<template>
  <div class="container-fluid p-0 mt-n2">

    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="fund"><span class="fundRoute"
                             @click.prevent="$router.push('/contact')">Contacts
      </span>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L6 6L1 11" stroke="#092C4C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>

        <span class="overview-text">{{ activeName }}</span>
      </h1>
      <div>


      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <el-form v-loading="loading">
          <el-tabs v-model="activeName">
            <el-tab-pane name="Overview">
              <span slot="label">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2798 1.5H4.71946C2.4333 1.5 1 3.1187 1 5.40941V11.5906C1 13.8813 2.42573 15.5 4.71946 15.5H11.279C13.5728 15.5 15 13.8813 15 11.5906V5.40941C15 3.1187 13.5728 1.5 11.2798 1.5Z" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.60972 9.40606C4.1095 9.40606 3.70312 8.99968 3.70312 8.50022C3.70312 8.00076 4.1095 7.59363 4.60972 7.59363C5.10994 7.59363 5.51631 8.00076 5.51631 8.50022C5.51631 8.99968 5.10994 9.40606 4.60972 9.40606Z" fill="#4F31FF"/>
                <path d="M7.99961 9.40606C7.4994 9.40606 7.09302 8.99968 7.09302 8.50022C7.09302 8.00076 7.4994 7.59363 7.99961 7.59363C8.49983 7.59363 8.90621 8.00076 8.90621 8.50022C8.90621 8.99968 8.49983 9.40606 7.99961 9.40606Z" fill="#4F31FF"/>
                <path d="M11.3893 9.40606C10.889 9.40606 10.4827 8.99968 10.4827 8.50022C10.4827 8.00076 10.889 7.59363 11.3893 7.59363C11.8895 7.59363 12.2959 8.00076 12.2959 8.50022C12.2959 8.99968 11.8895 9.40606 11.3893 9.40606Z" fill="#4F31FF"/>
                </svg>
                Overview
              </span>
              <div class="row mt-2">
                <div class="col-md-6 mt-2">
                  <div class="fund-details-card">
                    <div class="card-body">
                      <div class="clearfix">
                        <h3 class="fund-details-text float-start">Section - 1</h3>
                        <button
                            v-if="checkPermission('contact_all','contact_edit')"
                                @click.prevent="editContact(contact.id)"
                                class="icon-button float-end">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                          </svg>
                        </button>
                      </div>

                      <h6 class="fund-name">Contact Name</h6>
                      <h4 class="fund-des">
                        {{ contact.first_name+' '+contact.last_name }}
                      </h4>

                      <h6 class="fund-name">Email</h6>
                      <h4 class="fund-des">{{ contact.email }}</h4>

                      <h6 class="fund-name">Phone</h6>
                      <h4 class="fund-des">{{ contact.phone_number }}</h4>

                      <h6 class="fund-name">Address</h6>
                      <h4 class="fund-des">{{ contact.address }}</h4>

                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="fund-details-card">
                    <div class="card-body">
                      <div class="clearfix">
                        <h3 class="fund-details-text float-start">Section - 2</h3>
                        <button
                            v-if="checkPermission('contact_all','contact_edit')"
                            @click.prevent="editContact(contact.id)" class="icon-button float-end">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                          </svg>
                        </button>
                      </div>
                      <h6 class="fund-name">Associated Investors</h6>
                      <div class="fund-des">
                        <button v-for="(value,key) in contact.accounts"
                                :key="key"
                                class="tag-button">
                          {{ value }}
                        </button>
                      </div>


                      <h6 class="fund-name">Fund Associated</h6>
                      <div class="fund-des">
                        <button v-for="(value,key) in contact.funds"
                                :key="key"
                                class="tag-button">
                          {{ value }}
                        </button>
                      </div>


                      <h6 class="fund-name">Company</h6>
                      <h4 class="fund-des">
                        {{ contact.company }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6 mt-2">
                  <div class="fund-details-card">
                    <div class="card-body">
                      <div class="clearfix">
                        <h3 class="fund-details-text float-start">Section - 3</h3>
                        <button
                            v-if="checkPermission('contact_all','contact_edit')"
                                @click.prevent="editContact(contact.id)"
                                class="icon-button float-end">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                          </svg>
                        </button>
                      </div>
                      <h6 class="fund-name">Status</h6>
                      <div class="fund-des">
                        <button class="tag-button text-capitalize">
                          {{ contact.status }}
                        </button>
                      </div>

                      <h6 class="fund-name">Access Role</h6>
                      <button class="tag-button text-capitalize">
                        {{ contact.role }}
                      </button>

                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="fund-details-card">
                    <div class="card-body">
                      <div class="clearfix">
                        <h3 class="fund-details-text float-start">Section - 4</h3>
                        <button  v-if="checkPermission('contact_all','contact_edit')"
                                 @click.prevent="editContact(contact.id)" type="button" class="icon-button float-end">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                          </svg>
                        </button>
                      </div>

                      <h6 class="fund-name">Notes</h6>
                      <h4 class="fund-des">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit pulvinar gravida pellentesque purus diam rutrum. Sit amet ornare amet arcu, amet viverra id eget. Gravida nec.</h4>

                      <h6 class="fund-name">Preferences</h6>
                      <h4 class="fund-des">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4>

                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
        <br><br>

      </div>
    </div>

    <edit-contact-modal
        v-if="openEditContactModal"
        :open="openEditContactModal"
        @cancel="openEditContactModal = false"
        @close="openEditContactModal = false"
        @submitted="editedContactData"
        ref="editModal"
    />


  </div>
</template>

<script>
import EditContactModal from "@/components/contact/EditModal";
import Auth from "@/apis/Auth";
import Contact from "@/apis/Contact";
import Account from "@/apis/Account";
import {Notification} from "element-ui";

export default {
  name: "ViewContact",
  data(){
    return{
      openEditContactModal: false,
      activeName: 'Overview',
      contact: {},
      loading: false,
    }
  },
  components: {EditContactModal},
  methods: {
    async getContact() {
      this.loading = true;

      await Contact.view(this.$route.params.id).then((res) => {
        this.contact = res.data;
        this.loading = false;
      }).catch(() => {
        Notification.error("Something went wrong!");
        this.loading = false;
      }).finally(()=>{
        this.loading = false;
      })

    },

    async editContact(id){

      this.openEditContactModal = true;
      // this.$refs.editModal.loading = true;

      Contact.show(id)
          .then(response => {

            let data = response.data;

            this.$refs.editModal.id = data.id;
            this.$refs.editModal.selectedRole.type = data.role;

            this.$refs.editModal.formData.first_name = data.first_name;
            this.$refs.editModal.formData.last_name = data.last_name;
            this.$refs.editModal.formData.email = data.email;
            this.$refs.editModal.formData.phone_number = data.phone_number;
            this.$refs.editModal.formData.role = data.role;
            this.$refs.editModal.formData.company = data.company;
            this.$refs.editModal.formData.tags = data.tags.map(tag => tag.name);
            this.$refs.editModal.formData.committed = data.committed;



            data.accounts.forEach((account,index)=>{
              Account.fundList(account.id).then((res)=>{
                this.$refs.editModal.formData.accounts = data.accounts.map(account=>account);
                this.$refs.editModal.formData.accounts[index].allFunds = res.data;
              }).catch((error)=>{
                console.log(error)
              })
            })

            this.$refs.editModal.loading = false;

          })
          .catch(error => {
            if (error.response) {
              this.errorMessage(error.response.data.message);
            } else {
              this.errorMessage(error);
            }
            this.$refs.editModal.loading = false;
          });
    },
    editedContactData() {
      this.openEditContactModal = false;
      this.getContact()
    },

    async getPermissions(){
      await Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch((error)=>{
        console.log(error)
      })
    },

    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;

    }
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getContact();
  },
  watch: {
    $route(to,from){
      this.getContact()
    }
  }
}
</script>

<style scoped>
.fund{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505968;
}

.fundRoute{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505968;
  cursor: pointer;
}

.fund svg{
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

.overview-text{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.fund-details-card{
  width: 100%;
  height: 373px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  box-shadow: 0px 8px 20px 2px rgba(9, 21, 42, 0.03);
  overflow-y: auto;
  padding: 25px;
}
.fund-details-text{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000
}

.fund-name{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #505968;
  margin-top: 15px;
}
.fund-des{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #092C4C;
}

.tag-button{
  width: auto;
  height: 31px;
  background: #F3F3F3;
  border-radius: 15.5px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #092C4C;
  padding: 0 15px;
  border: 0;
  margin: 5px;
}
</style>