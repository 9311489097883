<template>
  <div class="container-fluid p-0 mt-n1">

    <div
        class="title-section mb-3">
      <h1 class="list-title">Settings</h1>

    </div>

    <div class="row">
      <div class="col-12">
        <el-tabs v-model="activeName" >
          <el-tab-pane name="info"
                       v-if="checkPermission('profile_all','profile_update')">
              <span slot="label">
                <svg style="margin-right: 5px; margin-top: -5px !important;"
                     width="16" height="17" viewBox="0 0 16 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99166 11.3727V8.02856"
                      :stroke="activeName === 'info' ? '#2F80ED' : '#505968'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.99178 5.6274H7.99935" :stroke="activeName === 'info' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2798 1.5H4.71946C2.4333 1.5 1 3.1187 1 5.40941V11.5906C1 13.8813 2.42649 15.5 4.71946 15.5H11.279C13.5728 15.5 15 13.8813 15 11.5906V5.40941C15 3.1187 13.5728 1.5 11.2798 1.5Z"
                      :stroke="activeName === 'info' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                General Information
              </span>

            <general-info/>

          </el-tab-pane>

          <el-tab-pane name="categories"
                       v-if="checkPermission('category_all','category_index')">
              <span slot="label">
                <svg style="margin-right: 5px; margin-top: -5px !important;"
                     width="14" height="17" viewBox="0 0 14 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99458 1.50903H3.92278C2.35314 1.50903 1 2.78137 1 4.35177V12.5186C1 14.1775 2.26471 15.5001 3.92278 15.5001H10.0131C11.5835 15.5001 12.8558 14.0898 12.8558 12.5186V5.5311L8.99458 1.50903Z"
                      :stroke="activeName === 'categories' ? '#2F80ED' : '#505968'" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.79443 1.5V3.71762C8.79443 4.80014 9.67035 5.67834 10.7521 5.68063C11.7561 5.68292 12.783 5.68368 12.8523 5.6791"
                      :stroke="activeName === 'categories' ? '#2F80ED' : '#505968'" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.64946 11.2637H4.53516" :stroke="activeName === 'categories' ? '#2F80ED' : '#505968'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.09305 7.48834H4.53467" :stroke="activeName === 'categories' ? '#2F80ED' : '#505968'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Document Categories
              </span>

            <category/>

          </el-tab-pane>

          <el-tab-pane name="role"
                       v-if="checkPermission('role_all','role_index')">
              <span slot="label">
                <svg style="margin-right: 5px; margin-top: -5px !important;"
                     width="16" height="15" viewBox="0 0 16 15" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8979 6.65479C12.2761 6.65479 13.3931 5.53778 13.3931 4.15967C13.3931 2.78232 12.2761 1.66455 10.8979 1.66455"
                      :stroke="activeName === 'role' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.0454 9.36646C12.4571 9.39446 12.8658 9.45349 13.2691 9.54279C13.8284 9.65403 14.5019 9.88334 14.7411 10.3851C14.894 10.7067 14.894 11.0813 14.7411 11.403C14.5027 11.9047 13.8284 12.134 13.2691 12.2491"
                      :stroke="activeName === 'role' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03313 9.90381C8.82566 9.90381 11.2111 10.3269 11.2111 12.0168C11.2111 13.7074 8.8408 14.1448 6.03313 14.1448C3.24059 14.1448 0.855957 13.7225 0.855957 12.0319C0.855957 10.3412 3.22545 9.90381 6.03313 9.90381Z"
                      :stroke="activeName === 'role' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03308 7.49148C4.19106 7.49148 2.71533 6.01499 2.71533 4.17297C2.71533 2.33171 4.19106 0.855225 6.03308 0.855225C7.87509 0.855225 9.35158 2.33171 9.35158 4.17297C9.35158 6.01499 7.87509 7.49148 6.03308 7.49148Z"
                      :stroke="activeName === 'role' ? '#2F80ED' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Role & Permissions
              </span>

<!--            <role-permission/>-->
            <role-permission-update/>

          </el-tab-pane>
        </el-tabs>
        <br><br>

      </div>
    </div>

  </div>
</template>

<script>
import GeneralInfo from "@/components/setting/GeneralInfo.vue";
import Category from "@/components/setting/Category.vue";
import RolePermission from "@/components/setting/RolePermission.vue";
import RolePermissionUpdate
  from "@/components/setting/RolePermissionUpdate.vue";
import Auth from "@/apis/Auth";


export default {
  name: "Setting",
  components: {GeneralInfo,Category,RolePermission,RolePermissionUpdate},
  data() {
    return {
      activeName: 'info',
      permissions: [],
    };
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
  },
  methods: {

    async getPermissions(){
      await Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch((error)=>{
        console.log(error)
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    }
  },


}
</script>

<style scoped></style>