<template>
  <div class="container-fluid p-0 mt-n1 fund-box-container">

    <div class="mb-3 title-section">
      <h1 class="list-title">All Notifications</h1>
      <button @click.prevent="markAsReadAll" class="common-button">Mark As Read All</button>
    </div>

    <div class="row mt-n3">
      <div class="col-12">
        <el-form v-loading="loading">
          <div v-if="notifications.length > 0">
            <div class="notification-card"
                 v-for="notification in notifications"
                 :key="notification.id">
              <div class="row">
                <div class="col-1 icon-section">
                  <div class="notification-icon"
                       v-if="notification.type === 'fund'">
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="22.5" cy="22.5" r="22.5"
                              :fill="notification.read_at === null ? '#27AE60' : '#6c757d'"/>
                      <path d="M26.9088 20.5876L23.5817 23.2664C22.9521 23.76 22.0695 23.76 21.4399 23.2664L18.0842 20.5876"
                            :stroke="notification.read_at === null ? '#27AE60' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8126 16.25H26.1664C27.2267 16.2619 28.2359 16.7101 28.959 17.4904C29.682 18.2706 30.0558 19.3147 29.9932 20.3794V25.4712C30.0558 26.536 29.682 27.5801 28.959 28.3603C28.2359 29.1405 27.2267 29.5888 26.1664 29.6007H18.8126C16.535 29.6007 15 27.7478 15 25.4712V20.3794C15 18.1029 16.535 16.25 18.8126 16.25Z"
                            :stroke="notification.read_at === null ? '#27AE60' : '#6c757d'"
                            stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="notification-icon"
                       v-else-if="notification.type === 'contact'">
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="22.5" cy="22.5" r="22.5"
                              :fill="notification.read_at === null ? '#2F80ED' : '#6c757d'"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0288 25.1001C18.9119 25.1001 16.25 25.5712 16.25 27.4588C16.25 29.3456 18.8949 29.8345 22.0288 29.8345C25.1456 29.8345 27.8075 29.3626 27.8075 27.4758C27.8075 25.589 25.1626 25.1001 22.0288 25.1001Z"
                            :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0288 22.4077C24.085 22.4077 25.7326 20.7593 25.7326 18.7039C25.7326 16.6476 24.085 15 22.0288 15C19.9733 15 18.3249 16.6476 18.3249 18.7039C18.3249 20.7593 19.9733 22.4077 22.0288 22.4077Z"
                            :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M29.5911 19.7997V23.0511" :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M31.2499 21.4253H27.9337" :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="notification-icon"
                       v-else-if="notification.type === 'investor'">
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="22.5" cy="22.5" r="22.5"
                              :fill="notification.read_at === null ? '#2F80ED' : '#6c757d'"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0288 25.1001C18.9119 25.1001 16.25 25.5712 16.25 27.4588C16.25 29.3456 18.8949 29.8345 22.0288 29.8345C25.1456 29.8345 27.8075 29.3626 27.8075 27.4758C27.8075 25.589 25.1626 25.1001 22.0288 25.1001Z"
                            :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0288 22.4077C24.085 22.4077 25.7326 20.7593 25.7326 18.7039C25.7326 16.6476 24.085 15 22.0288 15C19.9733 15 18.3249 16.6476 18.3249 18.7039C18.3249 20.7593 19.9733 22.4077 22.0288 22.4077Z"
                            :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M29.5911 19.7997V23.0511" :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M31.2499 21.4253H27.9337" :stroke="notification.read_at === null ? '#2F80ED' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="notification-icon"
                       v-else-if="notification.type === 'document'">
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="22.5" cy="22.5" r="22.5"
                              :fill="notification.read_at === null ? '#EB5757' : '#6c757d'"/>
                      <path d="M22.4913 25.5777V21.9948"
                            :stroke="notification.read_at === null ? '#EB5757' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M22.4913 19.4223H22.4994"
                            :stroke="notification.read_at === null ? '#EB5757' : '#6c757d'" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0141 15H18.9851C16.5357 15 15 16.7343 15 19.1886V25.8114C15 28.2657 16.5284 30 18.9851 30H26.0132C28.4708 30 30 28.2657 30 25.8114V19.1886C30 16.7343 28.4708 15 26.0141 15Z"
                            :stroke="notification.read_at === null ? '#EB5757' : '#6c757d'" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="notification-icon"
                       v-else>
                    <svg style="width: 40px;"
                         width="40" height="45"
                         viewBox="0 0 18 21"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00079 12.2258V12.0173C1.03138 11.4002 1.22914 10.8024 1.57367 10.2853C2.14714 9.66425 2.53972 8.90315 2.7102 8.08188C2.7102 7.44714 2.7102 6.80334 2.76564 6.1686C3.05209 3.11278 6.0736 1 9.05815 1H9.13207C12.1166 1 15.1381 3.11278 15.4338 6.1686C15.4893 6.80334 15.4338 7.44714 15.48 8.08188C15.6528 8.90505 16.045 9.66852 16.6165 10.2944C16.9637 10.8069 17.1617 11.4025 17.1894 12.0173V12.2168C17.2101 13.0458 16.9245 13.8541 16.3855 14.4928C15.6733 15.2395 14.7068 15.7041 13.669 15.7985C10.6257 16.125 7.55527 16.125 4.51202 15.7985C3.47537 15.7 2.51025 15.2361 1.79543 14.4928C1.26476 13.8536 0.983086 13.0501 1.00079 12.2258Z"
                            :stroke="notification.read_at === null ? '#4F31FF' : '#6c757d '"
                            stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.7666 18.9541C7.2421 19.5509 7.94037 19.9372 8.70688 20.0274C9.47339 20.1176 10.2449 19.9043 10.8507 19.4347C11.037 19.2958 11.2047 19.1343 11.3497 18.9541"
                            :stroke="notification.read_at === null ? '#4F31FF' : '#6c757d '" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <div class="col-9">
                  <div class="notification-title"
                  :class="notification.read_at === null ? 'read-text' :
                  'unread-text'">
                    {{ notification.title }}
                  </div>
                  <div class="notification-text"
                       :class="notification.read_at === null ? '#505968' : 'text-muted'">
                    {{ notification.message }}
                    <span
                        @click.prevent="goToDetailsPage(notification.data_id,notification.type)"
                        class="notification-link">
                      {{ notification.name }}
                    </span>
                  </div>

                </div>
                <div class="col-2">
                  <div class="notification-action">
                    <div>
                      <a type="button"
                         data-bs-toggle="dropdown"
                         aria-haspopup="true"
                         aria-expanded="false">
                        <i class="text-dark fa fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu">
                        <a @click.prevent="markAsReadSingle(notification.id)"
                           :class="notification.read_at === null ?
                              'dropdown-item d-block' :
                              'dropdown-item d-none'">
                          Mark as Read
                        </a>
                        <div :class="notification.read_at === null ?
                          'dropdown-divider d-block' : 'dropdown-divider d-none'">
                        </div>
                        <a @click.prevent="destroy(notification.id)" class="dropdown-item">Delete</a>
                      </div>
                    </div>
                  </div>
                  <div class="notification-time">
                    {{ notification.date }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="notifications.length" v-observe-visibility="visibilityChanged"></div>
          </div>
          <h3 v-else class="text-center text-muted mt-5">No Notification Found!</h3>
        </el-form>

      </div>
    </div>

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteNotification"
    />


  </div>
</template>

<script>

import Notifications from "@/apis/Notifications";
import {Notification} from "element-ui";
import DeleteModal from "@/components/common/DeleteModal.vue";

export default {
  name: "Index",

  data(){
    return{
      notifications: [],
      notificationId: '',
      openDeleteModal: false,
      btnDisabled: false,
      page: 1,
      lastPage: 1,
      loading: true,
    }
  },
  components: {DeleteModal},
  mounted() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      // this.loading = true;
      Notifications.allNotification(this.page).then((res)=>{
        this.lastPage = res.data.meta.last_page;
        res.data.data.forEach((item)=>{
          this.notifications.push(item)
        })

        this.loading = false;
      }).catch(()=>{
        Notification.error('Something went wrong!');
        this.loading = false;
      })
    },
    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getNotifications();
    },
    markAsReadAll(){
      Notifications.markAsReadAll().then(()=>{
        Notification.success('Notification marked as read successfully.');
        this.page = 1
        this.notifications = [];
        this.getNotifications();
      }).catch(()=>{
        Notification.error('Something went wrong!');
      })
    },
    markAsReadSingle(id){
      Notifications.markAsReadSingle(id).then(()=>{
        Notification.success('Notification marked as read successfully.');
        this.page = 1
        this.notifications = [];
        this.getNotifications();
      }).catch(()=>{
        Notification.error('Something went wrong!');
      })
    },
    destroy(id){
      this.openDeleteModal = true;
      this.notificationId = id;
    },
    deleteNotification(){
      Notifications.destroy(this.notificationId).then(()=>{
        Notification.success('Notification deleted successfully.');
        this.openDeleteModal = false;
        this.page = 1
        this.notifications = [];
        this.getNotifications();
      }).catch(()=>{
        Notification.error('Something went wrong!');
      })
    },
    goToDetailsPage(id,type){
      if (type === 'investor'){
        this.$router.push(`/investor/${id}`);
      }else if (type === 'contact'){
        this.$router.push(`/contact/${id}`);
      }else if (type === 'fund'){
        this.$router.push(`/funds/${id}`);
      }else if (type === 'document'){
        this.$router.push(`/document`);
      }
    }
  }

}
</script>

<style scoped>

.notification-card{
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.icon-section{
  width: 70px !important;
}
.notification-icon{
  width: 70px;
}

.notification-action{
  position: absolute;
  top: 20px;
  right: 20px;
}
.notification-time{
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #505968;
}

.notification-title{
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}

.notification-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
}

.notification-link{
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
  color: #092C4C !important;
  cursor: pointer;
}

.notification-link:hover{
  color: #0a58ca !important;
  text-decoration: underline;
}

.read-text{
  color: #092C4C;
}
.unread-text{
  color: #6c757d;
}

@media (max-width: 576px){
  .icon-section{
    display: none;
  }
}



</style>