<template>

  <div class="container-fluid p-0 mt-n2 communication-section">

    <div class="d-flex justify-content-between mb-3">
      <h1 class="list-title">Email Details</h1>
      <div>

        <el-button
            @click.prevent="backToEmail"
            class="back-button"
        ><i class="fa fa-arrow-alt-circle-left"></i> Back
        </el-button>
      </div>
    </div>
    <p style="border-bottom: 1px solid #E6E8EB;"></p>

    <div class="row">
      <div class="col-12">
        <div class="card rounded-3 shadow-none">
          <div class="card-body">
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                header-row-class-name="table-header-row"
                row-class-name="table-row"
                v-loading="loading"
            >
              <el-table-column
                  type="selection"
                  width="31">
              </el-table-column>

              <el-table-column
                  label="Recipient's Name"
                  min-width="300"
              >
                <template slot-scope="scope">

                  <svg v-if="scope.row.seen_status === 'Seen'"
                       slot="reference"
                       style="margin-top: -3px;"
                       width="17" height="16"
                       viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 5L10.3447 8.51429C9.27442 9.1619 7.77413 9.1619 6.70388 8.51429L1 5" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 5L10.3447 1.48571C9.27442 0.838096 7.77413 0.838096 6.70388 1.48571L1 5" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.9932 5V10.5218C16.0558 11.6765 15.682 12.8088 14.959 13.6549C14.2359 14.501 13.2267 14.9871 12.1664 15H4.81257C2.53502 15 1 12.9907 1 10.5218V5" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <svg v-else slot="reference" style="margin-top: -3px;"
                       width="17" height="16"
                       viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9086 5.66238L9.58151 8.34113C8.95187 8.83477 8.06925 8.83477 7.43961 8.34113L4.08398 5.66238" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.81257 1.32467H12.1664C13.2267 1.33656 14.2359 1.78482 14.959 2.56504C15.682 3.34526 16.0558 4.38933 15.9932 5.45411V10.5459C16.0558 11.6107 15.682 12.6547 14.959 13.435C14.2359 14.2152 13.2267 14.6634 12.1664 14.6753H4.81257C2.53502 14.6753 1 12.8225 1 10.5459V5.45411C1 3.17753 2.53502 1.32467 4.81257 1.32467Z" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <span>
                    {{ scope.row.recipient_name}}
                  </span>
                </template>

              </el-table-column>
              <el-table-column
                  property="recipient"
                  label="Recipient's Email"
                  min-width="300"
              >
              </el-table-column>

              <el-table-column
                  label="Seen Status"
                  min-width="250"
              >
                <template slot-scope="scope">
                  <span class="investor-type-button">
                    {{scope.row.seen_status }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                  property="seen_time"
                  label="Seen At"
                  min-width="300"
              >
              </el-table-column>

            </el-table>


          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Emails from "@/apis/Emails";
import moment from "moment";
import {Notification} from "element-ui";

export default {
  name: "ViewCommunication",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {

      tableData: [],

      editor: ClassicEditor,
      editorConfig: {},
      id: null,

      loading: true
    }
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    backToEmail(){
      this.$router.push({name: 'communication'})
    }



  },
  mounted() {
    Emails.show(this.id).then((res)=>{
      this.loading = false;
      let recipients = res.data.recipients;

      recipients.forEach((receiver)=>{
        this.tableData.push({
          'recipient_name'     : receiver.user.first_name+' '+receiver.user.last_name,
          'subject'            : receiver.email.subject,
          'recipient'          : receiver.user.email,
          'seen_status'        : receiver.is_read === 1 ? "Seen": "Unseen",
          'seen_time'          : receiver.read_at != null?
              moment(new Date(receiver.read_at)).format('MM/DD/YYYY, h:mm a'): ''

        })

      })

    }).catch(()=>{
      Notification.error("Something went wrong!");
      this.loading = false;
    })
  },
  created() {
    this.id = this.$route.params.id
  }


}


</script>

<style scoped>

</style>