<template>
<h1>Register Page</h1>
</template>

<script>
export default {
    name: "Register"
}
</script>

<style scoped>

</style>