<template>

  <div class="container-fluid p-0 mt-n1">
    <div
        class="title-section mb-3">
      <h1 class="list-title">Profile</h1>

    </div>

    <el-tabs >
      <el-tab-pane>
        <span slot="label">
          <i class="el-icon-user"></i>
          Update Profile
        </span>

        <update-profile/>

      </el-tab-pane>

      <el-tab-pane>
        <span slot="label">
          <i class="el-icon-key"></i>
          Change Password
        </span>

        <change-password/>

      </el-tab-pane>

      <el-tab-pane>
        <span slot="label">
          <i class="el-icon-message"></i>
          Change Email
        </span>

        <change-email/>

      </el-tab-pane>

    </el-tabs>

  </div>



</template>

<script>
import UpdateProfile from "@/components/profile/UpdateProfile";
import ChangePassword from "@/components/profile/ChangePassword";
import ChangeEmail from "@/components/profile/ChangeEmail.vue";

export default {
  name: "Index",
  components: {UpdateProfile,ChangePassword,ChangeEmail},
}
</script>

<style scoped>

</style>