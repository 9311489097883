<template>
    <div class="container-fluid p-0 mt-n1">

        <div class="title-section">
            <h1 class="list-title">Documents Management</h1>
            <div>
              <span style="margin-right: 10px;">
                <span class="dropdown">
                  <button class="dropdown-button dropdown-toggle" type="button"
                          id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    Columns
                  </button>
                  <ul class="dropdown-menu dropdown-menu-box mt-5"
                      aria-labelledby="dropdownMenuClickableInside">
                    <div class="dropdown-menu-header dropdown-menu-header-custom">
                      <div class="d-flex justify-content-between">
                        All
                        <el-switch
                            @change="handleAllColumnChange"
                            v-model="tableColumn.all"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </div>

                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Document Batch Title
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.document_batch_title"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Category Name
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.category_name"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Upload Id
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.upload_id"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Fund Name
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.fund_name"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>

                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Upload Date
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.upload_date"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Uploaded By
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.uploaded_by"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>

                    </li>
                    <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Status
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.status"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                     <li class="dropdown-item dropdown-item-custom">
                      <div class="d-flex justify-content-between">
                        Action
                        <el-switch
                            @change="handleSingleColumnChange"
                            v-model="tableColumn.action"
                            active-color="#4F31FF"
                            inactive-color="#E5E5E5">
                        </el-switch>
                      </div>
                    </li>
                  </ul>
                </span>
              </span>

              <span class="dropdown">
                <button class="common-button dropdown-toggle" type="button"
                        id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                ><i class="fa fa-cloud-upload"></i> Upload Document
                </button>

                <ul class="dropdown-menu mt-5" aria-labelledby="dropdownMenuClickableInside">
                   <li class="dropdown-item">
                     <a v-if="checkPermission('document_all','document_create')"
                        @click="openBulkDocumentModal = true">
                       Upload Bulk Document
                     </a>
                    </li>
                    <div class="dropdown-divider"></div>
                    <li class="dropdown-item">
                     <a v-if="checkPermission('document_all','document_create')"
                        @click="openSingleDocumentModal = true">
                       Upload Single Document
                     </a>
                    </li>
                </ul>
              </span>
            </div>
        </div>

      <div class="row">
        <div class="col-12">
          <div class="card rounded-3 shadow-none">
            <div class="card-body">
              <span v-if="tableColumn.document_batch_title === false &&
              tableColumn.category_name === false &&
              tableColumn.upload_id === false &&
              tableColumn.fund_name === false &&
              tableColumn.upload_date === false &&
              tableColumn.uploaded_by === false &&
              tableColumn.status === false &&
              tableColumn.action === false">
            </span>

              <el-table v-else
                  ref="multipleTable"
                  :data="tableData"
                  style="width: 100%"
                  header-row-class-name="table-header-row"
                  row-class-name="table-row"
                  @expand-change="expandSelect"
                  :row-key="getRowKeys"
                  :expand-row-keys="expands"
                  v-loading="loading"
              >
                <el-table-column
                    label="Document Batch Title"
                    property="title"
                    min-width="180"
                    v-if="tableColumn.document_batch_title"
                >
                </el-table-column>
                <el-table-column min-width="140" label="Category Name"
                                 v-if="tableColumn.category_name">
                  <template slot-scope="scope">
                    {{ scope.row.category.name }}
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="Upload ID"
                                 property="batch_id"  v-if="tableColumn.upload_id">
                </el-table-column>
                <el-table-column min-width="150" label="Fund Name"
                                 v-if="tableColumn.fund_name">
                  <template slot-scope="scope">
                    <span
                        class="fund-badge"
                        v-for="(fund, fundKey) in scope.row.funds"
                        :key="fund.id"
                    >
                      {{ fund.name }}
                      <span v-if="fundKey + 1 < scope.row.funds.length">, </span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                    label="Upload Date"
                    property="upload_date"
                    min-width="120"
                    v-if="tableColumn.upload_date"
                >
                </el-table-column>
                <el-table-column min-width="120" label="Upload By"
                                 v-if="tableColumn.uploaded_by">
                  <template slot-scope="scope">
                    {{ scope.row.uploaded_by }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="Status"
                    min-width="130"
                    v-if="tableColumn.status"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.status === 'ACTIVE'" class="active-status">
                      <span></span>
                      {{ scope.row.status }}
                    </div>

                    <div v-else class="pending-status">
                      <span></span>
                      {{scope.row.status}}
                    </div>

                  </template>
                </el-table-column>

                <el-table-column  v-if="tableColumn.action" label="Action">
                  <template slot-scope="scope">
                    <button
                        v-if="checkPermission('document_all','document_edit')"
                        class="mini-button"
                        @click.prevent="edit(scope.row)"
                    >
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.91875 10.339L9.21498 3.48995C9.50282 3.12061 9.60517 2.6936 9.50922 2.25881C9.42607 1.86355 9.183 1.48773 8.81841 1.20262L7.9293 0.496331C7.15534 -0.11924 6.19587 -0.0544431 5.64578 0.651844L5.05091 1.42358C4.97416 1.52012 4.99335 1.66268 5.08929 1.74043C5.08929 1.74043 6.59245 2.94566 6.62443 2.97158C6.72678 3.06877 6.80353 3.19836 6.82272 3.35388C6.8547 3.65842 6.64362 3.94353 6.3302 3.98241C6.18308 4.00185 6.04236 3.95649 5.94002 3.87225L4.3601 2.61519C4.28334 2.55752 4.16821 2.56983 4.10424 2.64759L0.349543 7.50736C0.106479 7.81191 0.0233251 8.20717 0.106479 8.58947L0.58621 10.6695C0.611796 10.7796 0.707742 10.8574 0.822878 10.8574L2.9337 10.8314C3.31748 10.825 3.67568 10.65 3.91875 10.339ZM6.87434 9.69136H10.3163C10.6521 9.69136 10.9252 9.96804 10.9252 10.3082C10.9252 10.6491 10.6521 10.9251 10.3163 10.9251H6.87434C6.53853 10.9251 6.26541 10.6491 6.26541 10.3082C6.26541 9.96804 6.53853 9.69136 6.87434 9.69136Z"
                              fill="#230B34"/>
                      </svg>
                    </button>
                  </template>
                </el-table-column>

                <el-table-column min-width="20" type="expand">
                  <template>
                    <el-table
                        header-row-class-name="table-header-row sub-tbl-header"
                        row-class-name="table-row"
                        v-loading="childLoading"
                        :data="subTableData"
                        style="margin-left: 4%; width: 96%; background-color: #fafafa;"
                    >
                      <el-table-column
                          prop="account_title"
                          label="Investor Account"
                      >
                      </el-table-column>
                      <el-table-column prop="account_uid" label="Account ID">
                      </el-table-column>

                      <el-table-column label="Fund Name">
                        <template slot-scope="props">
                        <span
                            class="fund-badge"
                            v-for="(fund, fundKey) in props.row.funds"
                            :key="fund.id"
                        >
                        {{ fund.name }}
                        <span v-if="fundKey + 1 < props.row.funds.length">, </span>
                        </span>
                        </template>
                      </el-table-column>

                      <el-table-column label="Actions">
                        <template slot-scope="scope">

                         <div v-if="scope.row.security === 'no'">
                           <button
                               v-if="checkPermission('document_all','document_download')"
                               class="mini-button"
                               title="Download File"
                               @click.prevent="downloadDocument(scope.row.id,scope.row.title)"
                           >
                             <i
                                 class="fa-solid fa-download"
                                 style="color: #230B34"
                             ></i>
                           </button>
                         </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
              </el-table>

              <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

            </div>
          </div>
        </div>
      </div>

        <!-- Single Upload Modal -->
        <single-upload-modal
            v-if="openSingleDocumentModal"
            :open="openSingleDocumentModal"
            @cancel="closeAddModal(); refresh()"
            @close="closeAddModal(); refresh()"
            />

        <!-- Bulk Upload Modal -->
        <bulk-upload-modal
            v-if="openBulkDocumentModal"
            :open="openBulkDocumentModal"
            @cancel="openBulkDocumentModal = false; refresh()"
            @close="openBulkDocumentModal = false; refresh()"
            />

      <!-- Edit Single Upload Modal -->
      <edit-single-upload-modal
          v-if="openEditSingleDocumentModal"
          :open="openEditSingleDocumentModal"
          :document_id="document_id"
          @cancel="closeEditModal(); refresh()"
          @close="closeEditModal(); refresh()"
      />

      <!-- Edit Bulk Upload Modal -->
      <edit-bulk-upload-modal
          v-if="openEditBulkDocumentModal"
          :open="openEditBulkDocumentModal"
          :document_id="document_id"
          @cancel="openEditBulkDocumentModal = false; refresh()"
          @close="openEditBulkDocumentModal = false; refresh()"
      />

    </div>
</template>

<script>

import DocumentFilter from "../../components/document/Filter";
import DocumentTable from "../../components/document/Table";
import EditSingleUploadModal from
      "@/components/document/EditSingleUploadModal.vue";
import SingleUploadModal from "@/components/document/SingleUploadModal";
import BulkUploadModal from "@/components/document/BulkUploadModal";
import EditBulkUploadModal from "@/components/document/EditBulkUploadModal.vue";
import Auth from "@/apis/Auth";
import Document from "@/apis/Document";
import Table from "@/util/Table";
import {Notification} from "element-ui";


export default {
    name: "Document",
    components: {DocumentFilter, DocumentTable, SingleUploadModal,
      BulkUploadModal,EditSingleUploadModal,EditBulkUploadModal},
    data() {
        return {
          openDeleteModal: false,
          btnDisabled: false,
          openSingleDocumentModal: false,
          openBulkDocumentModal: false,
          openEditBulkDocumentModal: false,
          openEditSingleDocumentModal: false,
          document_id: null,

          tableColumn: {
            all: true,
            document_batch_title: true,
            category_name: true,
            upload_id: true,
            fund_name: true,
            read_by_no_of_investor: true,
            upload_date: true,
            uploaded_by: true,
            status: true,
            action: true,
          },

          table: new Table({
            columns: [
              "id",
              "title",
              "batch_id",
              "status",
              "category",
              "funds",
              "upload_date",
              "uploaded_by",
            ],
          }),
          multipleSelection: [],
          permissions: [],
          tableData: [],
          page: 1,
          lastPage: 1,
          deleteContactId: 0,
          documentData: {
            data: [],
          },
          expands: [],
          getRowKeys(row) {
            return row.id;
          },
          childLoading: false,
          subTableData: [],
          document: {},
          loading : true,
        }
    },

    methods: {
      handleAllColumnChange(){
        if (this.tableColumn.all === true){
          this.tableColumn.document_batch_title = true
          this.tableColumn.category_name = true
          this.tableColumn.upload_id = true
          this.tableColumn.fund_name = true
          this.tableColumn.upload_date = true
          this.tableColumn.uploaded_by = true
          this.tableColumn.status = true
          this.tableColumn.action = true

        }else {
          this.tableColumn.document_batch_title = false
          this.tableColumn.category_name = false
          this.tableColumn.upload_id = false
          this.tableColumn.fund_name = false
          this.tableColumn.upload_date = false
          this.tableColumn.uploaded_by = false
          this.tableColumn.status = false
          this.tableColumn.action = false
        }
      },
      handleSingleColumnChange(){
        this.tableColumn.all =
            this.tableColumn.document_batch_title === true &&
            this.tableColumn.category_name === true &&
            this.tableColumn.upload_id === true &&
            this.tableColumn.fund_name === true &&
            this.tableColumn.upload_date === true &&
            this.tableColumn.uploaded_by === true &&
            this.tableColumn.status === true &&
            this.tableColumn.action === true;
      },

      closeAddModal(){
        this.openSingleDocumentModal = false;
      },

      closeEditModal(){
        this.openEditSingleDocumentModal = false;
      },

      openUpdateStatusModal(document) {
        console.log(document)
        this.$confirm('Do you want ot activate this Document?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          Document.updateStatus(document.id,{status: 'ACTIVE'}).then((res)=>{
            Notification.success('Document Activated Successfully.')
            this.tableData = [];
            this.page = 1;
            this.getDocuments();
          }).catch((error)=>{
            Notification.error("Something went wrong!");
          })

        }).catch(() => {

        });
      },

      async getDocuments(){
        // this.loading = true;
        await Document.index(this.page).then((res)=>{

          let documents = res.data.data;

          this.lastPage = res.data.meta.last_page;

          documents.forEach((document)=>{
            this.tableData.push({
              "id" : document.id,
              "title" : document.title,
              "batch_id" : document.batch_id,
              "status" : document.status,
              "category" : document.category,
              "funds" : document.funds,
              "upload_date" : document.upload_date,
              "uploaded_by" : document.uploaded_by,
              "security" : document.security,
              "document_profile" : document.document_profile,
            })
          })
          this.loading = false;

        }).catch(()=>{
          Notification.error("Something went wrong!");
          this.loading = false;
        });
      },
      visibilityChanged(isVisible){
        if (!isVisible){return }

        if (this.page >= this.lastPage){return }

        this.page++
        this.getDocuments();
      },
      expandSelect(row, expandedRows) {
        if (this.expands.indexOf(row.id) >= 0) {
          //Collapse current line
          this.expands.shift();
          return;
        }

        this.document = row;
        var that = this;
        Document.getFiles(row.id).then((res) => {
          this.subTableData = res.data.data;

          if (expandedRows.length) {
            that.expands = [];
            if (row) {
              that.expands.push(row.id);
            }
          } else {
            that.expands = [];
          }
        });
      },

      downloadDocument(docFileId,title) {
        Document.downloadFile(this.document.id, docFileId).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title + ".pdf");
          document.body.appendChild(link);
          link.click();
        });
      },

      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },

      roleMap(role) {
        switch (role) {
          case "superadmin":
            return "Super Admin";
          case "admin":
            return "General";
          case "user":
            return "Investor";
          default:
            return "N/A";
        }
      },

      statusBtnType(status) {
        if (status === "active") {
          return "success";
        } else if (status === "inactive") {
          return "warning";
        } else if (status === "removed") {
          return "danger";
        }
      },
      statusBtnClass(status) {
        if (status === "active") {
          return "status-btn-success";
        } else if (status === "inactive") {
          return "status-btn-warning";
        } else if (status === "removed") {
          return "status-btn-danger";
        }
      },

      edit(document){
        if (document.document_profile === 'bulk'){
          this.document_id = document.id
          this.openEditBulkDocumentModal = true;
        }else {
          this.document_id = document.id
          this.openEditSingleDocumentModal = true;
        }
      },

      refresh() {
        // this.table.refresh();
        this.tableData = [];
        this.page = 1;
        this.getDocuments();
        this.document_id = null;
      },
      getPermissions(){
        Auth.userPermissions().then((res)=>{
          this.permissions = res.data.permissions;
        }).catch(()=>{
          Notification.error("Something went wrong!");
        })
      },
      checkPermission(allPermission,specificPermission){
        let userPermissions = this.permissions
        for (let key in userPermissions) {
          if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
            return true;
          }
        }
        return false;
      }

    },
    created() {
      this.getPermissions();
      this.getDocuments();
    },
    mounted() {
      this.checkPermission();
    }

    
}
</script>

<style scoped>
.active-status {
  padding-left: 15px;
}
.active-status span{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background-color: #27ae60;
}
.pending-status{
  padding-left: 15px;
}

.pending-status:hover{
  cursor: pointer;
  background-color: #ecf5ff;
  border-radius: 30px;
}

.pending-status span {
  background-color: red;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}


@media (min-width: 767.1px) and (max-width: 1222px) {
  .button-margin-bottom{
    margin-top: 10px;
  }
}

</style>
<style>
.sub-tbl-header th {
  background-color: #f5f5f5 !important;
}
</style>