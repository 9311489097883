<template>
  <div class="row">
    <div class="col-6">
      <h3 class="category-title">Document Categories</h3>
      <div class="category-container">
        <span v-for="category in categories" :key="category.id"
              class="category-item">

          <el-tooltip
              class="item"
              effect="dark"
              content="Edit"
              placement="top">

            <span v-if="checkPermission('category_all','category_edit')"
                  @click.prevent="editCategory(category.id)"
                  class="category-name">{{ category.name }}
            </span>
            <span v-else class="category-name">{{ category.name }}</span>

          </el-tooltip>
          <el-tooltip
              class="item"
              effect="dark"
              content="Delete"
              placement="top">
            <span
                v-if="checkPermission('category_all','category_delete')"
                @click.prevent="destroy(category.id)"
                class="icon-box">
            <i class="fa fa-times"></i>
          </span>
            <span v-else class="icon-box">
            <i class="fa fa-times"></i>
          </span>
          </el-tooltip>

         </span>
      </div>
      <button class="add-new-button"  @click.prevent="openAddCategoryModal = true"
              v-if="checkPermission('category_all','category_create')">Add new</button>

<!--      <button class="common-button" style="margin-top: 28px;">-->
<!--        Update Settings-->
<!--      </button>-->
    </div>

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteCategory"
    />


    <edit-category-modal
        :open="openEditCategoryModal"
        :disabled="btnDisabled"
        @close="openEditCategoryModal = false"
        @cancel="openEditCategoryModal = false"
        ref="editModal"
        @submitted="editedCategoryData"
    />

    <add-category-modal
        :open="openAddCategoryModal"
        :disabled="btnDisabled"
        @close="openAddCategoryModal = false"
        @cancel="openAddCategoryModal = false"
        @submitted="addedNewCategory"
    />

  </div>
</template>

<script>
import DeleteModal from "@/components/common/DeleteModal.vue";
import AddCategoryModal from "@/components/category/AddNewModal.vue";
import EditCategoryModal from "@/components/category/EditModal.vue";
import Category from "@/apis/Category";
import Auth from "@/apis/Auth";
import {Notification} from "element-ui";

export default {
  name: "Category",
  components: {DeleteModal,AddCategoryModal,EditCategoryModal},
  data(){
    return{
      openDeleteModal: false,
      btnDisabled: false,
      openAddCategoryModal: false,
      openEditCategoryModal: false,
      categoryId: null,
      categories: [],
      permissions: [],
    }
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getCategories()
  },
  methods: {
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    },

    async getCategories(){
      await Category.index().then((res)=>{
        this.categories = res.data.data;
      }).catch(()=>{
       Notification.error('Something went wrong!');
      });
    },

    addedNewCategory() {
      this.openAddCategoryModal = false;
      this.getCategories();
    },

    async editCategory(id){

      this.openEditCategoryModal = true;
      this.$refs.editModal.loading = true;

      await Category.show(id).then((res)=>{
        let data = res.data.data;
        let category = {};

        category.name           = data.name;
        category.description = data.description;

        this.$refs.editModal.id = data.id;
        this.$refs.editModal.formData = category;

        this.$refs.editModal.loading = false;

      }).catch(()=>{
        Notification.error('Something went wrong!');
      })

    },

    editedCategoryData() {
      this.openEditCategoryModal = false;
      this.getCategories()
    },

    destroy(id) {
      this.openDeleteModal = true;
      this.categoryId = id;
    },

    async deleteCategory(){
      await Category.destroy(this.categoryId).then(()=> {
        this.openDeleteModal = false;
        Notification.success('Category Deleted Successfully.');
        this.getCategories();

      }).catch(error => {
        if (error.response) {
          this.errorMessage(error.response.data.message);
        } else {
          this.errorMessage(error);
        }
      });
    },


  },

}
</script>

<style scoped>
.category-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  margin-top: 10px;
  margin-bottom: 10px;
}


.category-container{
  width: 100%;
  height: auto;
  background: #FAFAFA;
  border-radius: 10px;
  padding: 13px 25px;
  word-break: keep-all;
}
.category-item{
  width: auto;
  height: 22px;
  padding: 3px 10px;
  background: #FFFFFF;
  border-radius: 22px;
  margin-right: 3px;
  margin-top: 5px;
  display: inline-block;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505968;
}

.category-name:hover{
  text-decoration: underline;
  cursor: pointer;
}

.category-item .icon-box{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-left: 6px;
  cursor: pointer;
}
.category-item .icon-box:hover{
  background-color: #ddd;
}

.add-new-button{
  width: auto;
  height: 25px;
  padding: 3px 10px;
  background: #2F80ED;
  border: none;
  border-radius: 22px;
  margin-top: 10px;
  display: block;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.add-new-button:hover{
  border: 1px solid #2F80ED;
  background: #ebeef5;
  color: #092C4C;
}


</style>