<template>
<div class="row generalInfoContainer">
  <div class="col-6">
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
        style="margin-top: 10px;"
        v-loading="loading"
    >


      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Email" prop="email">
            <el-input type="email" prefix-icon="el-icon-message"
                      v-model="formData.email"
                      placeholder="example@gmail.com"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="First Name" prop="first_name">
            <el-input v-model="formData.first_name"
                      placeholder="Enter First Name"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Last Name" prop="last_name">
            <el-input v-model="formData.last_name"
                      placeholder="Enter Last Name"/>
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input type="number" prefix-icon="el-icon-phone"
                      v-model="formData.phone_number"
                      placeholder="+1-202-555-0112"/>
          </el-form-item>

          <div class="two-step-auth">
            Two-Step Authentication <el-switch class="ms-3"
              v-model="formData.two_step_verify">
          </el-switch>
          </div>
        </div>
        <div class="col-12 mt-4">
          <el-button
              class="common-button"
              @click.prevent="handleSubmit('form')"
              :loading="loading"
          >Update Settings
          </el-button>
        </div>
      </div>



    </el-form>
  </div>
</div>
</template>

<script>
import Profile from "@/apis/Profile";
import {Notification} from "element-ui";
import Auth from "@/apis/Auth";

export default {
  name: "GeneralInfo",
  data(){
    return{
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        two_step_verify: false,
      },
      rules: {
        first_name: [
          {required: true, message: 'First Name is required', trigger: 'blur'},
        ],
        last_name: [
          {required: true, message: 'Last Name is required', trigger: 'blur'},
        ],
        phone_number: [
          {required: true, message: 'Phone Number is required', trigger: 'blur'},
          {min: 4,max: 16, trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'Please insert email address', trigger: 'blur'},
          {type: 'email', message: 'Please insert correct email address', trigger: ['blur', 'change']}
        ],

      },
      loading: false,
    }
  },
  mounted() {
    this.getAuthUser();
  },
  methods: {
    getAuthUser() {
      this.loading = true;
      Auth.me().then((response) => {
        if (response.data.first_name != null){
          this.formData.first_name  = response.data.first_name
        }
        if (response.data.last_name != null){
          this.formData.last_name = response.data.last_name
        }
        if (response.data.phone_number != null){
          this.formData.phone_number = response.data.phone_number
        }
        if (response.data.email != null){
          this.formData.email = response.data.email
        }
        if (response.data.two_step_verify != null){
          this.formData.two_step_verify = response.data.two_step_verify !== 0
        }

        this.loading = false;
      }).catch(() => {
        Notification.error('Something went wrong!')
        this.loading = false
      })
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.callToUpdateProfileApi();
        } else {
          return false;
        }
      });
    },
    callToUpdateProfileApi(){

      Profile.updateProfileWithEmail(this.formData).then((res)=>{
        this.$store.dispatch('auth/setUser', res.data.data);
        Notification.success('Profile updated successfully.');
        this.loading = false;
      }).catch((error)=>{

        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;

      })
    },
  }
}
</script>

<style scoped>

.two-step-auth{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #092C4C;
}

</style>