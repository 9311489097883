<template>
  <div class="container-fluid p-0 mt-1 position-relative communication-section">

    <div class="showFundCard create-section">
      <div  style="margin-bottom: -17px !important;" v-if="activeName === 'Emails'" >
        <button
            v-if="checkPermission('email_all','email_create')"
            @click.prevent="addNewEmail"
                 class="create-button button-margin-bottom">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.26074 12.1284H12.9997" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63364 1.02435C8.18133 0.326559 9.06639 0.362972 9.76493 0.910656L10.7979 1.72066C11.4964 2.26835 11.7439 3.11774 11.1962 3.81702L5.03642 11.6756C4.83057 11.9386 4.51623 12.094 4.18182 12.0977L1.80605 12.1281L1.26803 9.8133C1.19223 9.48856 1.26803 9.14672 1.47387 8.88291L7.63364 1.02435Z" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.48047 2.49603L10.043 5.2887" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Compose New Mail
        </button>

        <button
            v-if="checkPermission('email_all','email_create')"
            @click.prevent="addNewEmail"
            class="create-button-small button-margin-bottom">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.26074 12.1284H12.9997" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63364 1.02435C8.18133 0.326559 9.06639 0.362972 9.76493 0.910656L10.7979 1.72066C11.4964 2.26835 11.7439 3.11774 11.1962 3.81702L5.03642 11.6756C4.83057 11.9386 4.51623 12.094 4.18182 12.0977L1.80605 12.1281L1.26803 9.8133C1.19223 9.48856 1.26803 9.14672 1.47387 8.88291L7.63364 1.02435Z" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.48047 2.49603L10.043 5.2887" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          New
        </button>

      </div>

      <div style="margin-bottom: -17px !important;" v-if="activeName === 'Templates'">
        <button
            v-if="checkPermission('template_all','template_create')"
            @click.prevent="$router.push('/templates')"
                class="create-button button-margin-bottom">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.26074 12.1284H12.9997" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63364 1.02435C8.18133 0.326559 9.06639 0.362972 9.76493 0.910656L10.7979 1.72066C11.4964 2.26835 11.7439 3.11774 11.1962 3.81702L5.03642 11.6756C4.83057 11.9386 4.51623 12.094 4.18182 12.0977L1.80605 12.1281L1.26803 9.8133C1.19223 9.48856 1.26803 9.14672 1.47387 8.88291L7.63364 1.02435Z" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.48047 2.49603L10.043 5.2887" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Add New Template
        </button>

        <button
            v-if="checkPermission('template_all','template_create')"
            @click.prevent="$router.push('/templates')"
            class="create-button-small button-margin-bottom">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.26074 12.1284H12.9997" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63364 1.02435C8.18133 0.326559 9.06639 0.362972 9.76493 0.910656L10.7979 1.72066C11.4964 2.26835 11.7439 3.11774 11.1962 3.81702L5.03642 11.6756C4.83057 11.9386 4.51623 12.094 4.18182 12.0977L1.80605 12.1281L1.26803 9.8133C1.19223 9.48856 1.26803 9.14672 1.47387 8.88291L7.63364 1.02435Z" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.48047 2.49603L10.043 5.2887" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Add
        </button>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <el-tabs v-model="activeName">
          <el-tab-pane name="Emails">
              <span slot="label">
                <svg
                    v-if="activeName === 'Emails'"
                    style="margin-right: 5px; margin-top: -3px !important;"
                    width="16" height="15" viewBox="0 0 16 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1152 5.31824L9.00993 7.8184C8.42227 8.27914 7.59848 8.27914 7.01083 7.8184L3.87891 5.31824" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5584 1.26968H11.422C12.4116 1.28078 13.3535 1.69915 14.0284 2.42736C14.7032 3.15556 15.0521 4.13004 14.9937 5.12383V9.87616C15.0521 10.8699 14.7032 11.8444 14.0284 12.5726C13.3535 13.3008 12.4116 13.7192 11.422 13.7303H4.5584C2.43269 13.7303 1 12.001 1 9.87616V5.12383C1 2.99902 2.43269 1.26968 4.5584 1.26968Z" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <svg
                    v-else
                    style="margin-right: 5px; margin-top: -3px !important;"
                    width="16" height="15" viewBox="0 0 16 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1152 5.31824L9.00993 7.8184C8.42227 8.27914 7.59848 8.27914 7.01083 7.8184L3.87891 5.31824" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5584 1.26968H11.422C12.4116 1.28078 13.3535 1.69915 14.0284 2.42736C14.7032 3.15556 15.0521 4.13004 14.9937 5.12383V9.87616C15.0521 10.8699 14.7032 11.8444 14.0284 12.5726C13.3535 13.3008 12.4116 13.7192 11.422 13.7303H4.5584C2.43269 13.7303 1 12.001 1 9.87616V5.12383C1 2.99902 2.43269 1.26968 4.5584 1.26968Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Emails
              </span>
            <div class="row" style="margin-top: -13px;">
              <div class="col-12">
                <div class="card rounded-3 shadow-none">
                  <div class="card-body commitment-card">
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                        header-row-class-name="table-header-row"
                        row-class-name="table-row"
                    >
                      <el-table-column
                          type="selection"
                          width="31">
                      </el-table-column>

                      <el-table-column
                          label="Recipients"
                          min-width="350"
                      >
                        <template slot-scope="scope">
                          <div class="fund-name">
                            <svg slot="reference" style="margin-top: -3px;"
                                 width="17" height="16"
                                 viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.9086 5.66238L9.58151 8.34113C8.95187 8.83477 8.06925 8.83477 7.43961 8.34113L4.08398 5.66238" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.81257 1.32467H12.1664C13.2267 1.33656 14.2359 1.78482 14.959 2.56504C15.682 3.34526 16.0558 4.38933 15.9932 5.45411V10.5459C16.0558 11.6107 15.682 12.6547 14.959 13.435C14.2359 14.2152 13.2267 14.6634 12.1664 14.6753H4.81257C2.53502 14.6753 1 12.8225 1 10.5459V5.45411C1 3.17753 2.53502 1.32467 4.81257 1.32467Z" stroke="#092C4C" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span @click.prevent="viewEmail(scope.row.id)">
                                 <span v-if="scope.row.number_of_recipients > 1">
                                {{ scope.row.recipient }}
                                {{' and ' + (scope.row.number_of_recipients - 1) + ' other Users'}}
                              </span>
                                <span v-else>
                                {{ scope.row.recipient }}
                              </span>
                              </span>


                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column
                          label="Subject"
                          min-width="450"
                      >
                        <template slot-scope="scope">
                          <span class="fund-des">{{ scope.row.subject}}</span>
                        </template>
                      </el-table-column>

                      <el-table-column
                          label="Date"
                          min-width="150"
                      >
                        <template slot-scope="scope">
                          <span class="fund-des">{{ scope.row.date}}</span>
                        </template>
                      </el-table-column>

                      <el-table-column
                          label="Actions"
                          width="120"
                      >
                        <template slot-scope="scope">

                          <!--                            <button-->
                          <!--                                class="mini-button"-->
                          <!--                            >-->
                          <!--                              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                          <!--                                <path d="M4.13309 4.01314C3.93304 4.2398 3.95977 4.57315 4.17312 4.75978L5.95981 6.34641L7.74649 4.75978C7.97316 4.55973 7.98646 4.22652 7.78653 4.01314C7.58648 3.78647 7.25326 3.77317 7.03988 3.9731L6.49318 4.45316L6.49307 0.533267C6.49307 0.239958 6.2531 0 5.95981 0C5.66651 0 5.42654 0.239969 5.42654 0.533267V4.45316L4.87984 3.9731C4.66649 3.77306 4.33315 3.78636 4.13311 4.01303L4.13309 4.01314Z" fill="#505968"/>-->
                          <!--                                <path d="M11.8797 6.13314L10.093 3.05308C9.85301 2.62638 9.31961 2.33307 8.82638 2.33307H7.11965V3.39975H8.8397C8.94638 3.39975 9.13301 3.50642 9.17304 3.5998L10.7597 6.33316H7.39976C7.39976 7.06649 6.79974 7.65311 6.06641 7.65311C5.33308 7.65311 4.73306 7.06649 4.73306 6.33316H1.23998L2.82661 3.5998C2.87994 3.50642 3.06657 3.39975 3.15995 3.39975H4.77332V2.33307H3.17338C2.68004 2.33307 2.16006 2.6397 1.90678 3.05308L0.119984 6.13314C9.39744e-08 6.3465 0 6.54644 0 6.7598V9.41309C0 9.7064 0.239968 9.94636 0.533266 9.94636H11.4666C11.7599 9.94636 11.9999 9.70639 11.9999 9.41309L12 6.77308C12 6.54642 12 6.34649 11.88 6.13314H11.8797Z" fill="#505968"/>-->
                          <!--                              </svg>-->
                          <!--                            </button>-->

                          <!--                            <button-->
                          <!--                                class="mini-button"-->
                          <!--                            >-->
                          <!--                              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                          <!--                                <path d="M13 4.2L8.47573 7.01143C7.61953 7.52952 6.4193 7.52952 5.56311 7.01143L1 4.2" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>-->
                          <!--                                <path d="M13 4.2L8.47573 1.38857C7.61953 0.870478 6.4193 0.870478 5.56311 1.38857L1 4.2" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>-->
                          <!--                                <path d="M12.9946 8.61745V4.2L7 8L1 4.2V8.61745C1 10.5925 2.22802 12.2 4.05005 12.2H9.93312C10.7814 12.1897 11.5887 11.8008 12.1672 11.1239C12.7456 10.447 13.0446 9.54121 12.9946 8.61745Z" fill="#505968" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>-->
                          <!--                              </svg>-->
                          <!--                            </button>-->

                          <el-tooltip class="item" effect="dark" content="View"
                                      placement="top">
                            <button
                                v-if="checkPermission('email_all','email_view')"
                                class="mini-button"
                                type="button"
                                @click.prevent="goToViewEmailPage(scope.row.id)"
                            >
                              <i class="fa-solid fa-eye" style="color: #230B34"></i>
                            </button>

                          </el-tooltip>


                          <el-tooltip class="item" effect="dark"
                                      content="Delete"
                                      placement="top">

                            <button
                                v-if="checkPermission('email_all','email_delete')"
                                class="mini-button"
                                @click.prevent="destroy(scope.row.id)"
                            >
                              <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                                      fill="#230B34"/>
                              </svg>
                            </button>

                          </el-tooltip>

                        </template>
                      </el-table-column>

                    </el-table>

                    <div v-if="loading" class="text-center">
                      <i class="el-icon-loading" style="font-size: 30px;"></i>
                    </div>
                    <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

                  </div>
                </div>
              </div>

            </div>
          </el-tab-pane>

          <el-tab-pane name="Templates">
              <span slot="label">
                <svg
                    v-if="activeName === 'Templates'"
                    style="margin-right: 5px; margin-top: -3px !important;"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0989 11.7461H4.6084" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.0989 8.56243H4.6084" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.70443 5.38616H4.60938" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2456 1.5C10.2456 1.5 4.4076 1.50304 4.39848 1.50304C2.29962 1.51597 1 2.89696 1 5.00342V11.9966C1 14.1137 2.30951 15.5 4.42662 15.5C4.42662 15.5 10.2639 15.4977 10.2738 15.4977C12.3726 15.4848 13.673 14.103 13.673 11.9966V5.00342C13.673 2.88631 12.3627 1.5 10.2456 1.5Z" stroke="#4F31FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <svg
                    v-else
                    style="margin-right: 5px; margin-top: -3px !important;"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0989 11.7461H4.6084" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.0989 8.56243H4.6084" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.70443 5.38616H4.60938" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2456 1.5C10.2456 1.5 4.4076 1.50304 4.39848 1.50304C2.29962 1.51597 1 2.89696 1 5.00342V11.9966C1 14.1137 2.30951 15.5 4.42662 15.5C4.42662 15.5 10.2639 15.4977 10.2738 15.4977C12.3726 15.4848 13.673 14.103 13.673 11.9966V5.00342C13.673 2.88631 12.3627 1.5 10.2456 1.5Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Templates
              </span>
            <div v-if="templates.length > 0" class="row" style="margin-top: 5px;">
              <div
                  v-for="template in templates" :key="template.id"
                  class="col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xxl-4"
              >
                <div class="template-card">
                  <h3 class="temp-name">{{ template.subject }}</h3>
                  <div>
                    <div class="description"
                         v-html="template.body.toString().substr(0,440)"></div>
                    <button
                        @click.prevent="useTemplate(template)"
                        class="common-button template-button"
                    >
                      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.26074 12.1284H12.9997" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63364 1.02435C8.18133 0.326559 9.06639 0.362972 9.76493 0.910656L10.7979 1.72066C11.4964 2.26835 11.7439 3.11774 11.1962 3.81702L5.03642 11.6756C4.83057 11.9386 4.51623 12.094 4.18182 12.0977L1.80605 12.1281L1.26803 9.8133C1.19223 9.48856 1.26803 9.14672 1.47387 8.88291L7.63364 1.02435Z" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.48047 2.49603L10.043 5.2887" stroke="#4F31FF" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Use this template
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <h3 class="text-danger text-center">No Templates Found!</h3>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <br><br>

      </div>
    </div>

    <create-email
        :open="openAddNewModal"
        :disabled="btnDisabled"
        @close="openAddNewModal = false"
        @cancel="openAddNewModal = false"
        @submitted="addedNewEmail"
        ref="createModal"
    />

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteEmail"
    />


  </div>
</template>

<script>
import CreateEmail from "@/components/communication/CreateEmail";
import DeleteModal from "../../components/common/DeleteModal";
import Auth from "@/apis/Auth";
import Template from "@/apis/Template";
import {Notification} from "element-ui";
import Emails from "@/apis/Emails";
import Communication from "@/apis/Communication";



export default {
  name: "ShowFund",
  components: {CreateEmail,DeleteModal},
  data() {
    return {
      activeName: 'Emails',
      openAddNewModal: false,
      openDeleteModal: false,
      btnDisabled: false,
      tableData: [],
      templates: [],
      multipleSelection: [],
      permissions: [],
      emailId: null,
      search: '',
      page: 1,
      lastPage: 1,
      loading: false,
    };
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getEmails();
    this.getTemplates();
  },
  methods: {
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    getTemplates(){
      Template.index(this.search).then((res)=>{
        this.templates = res.data.data;
        this.loading = false;
      }).catch((error)=>{
        Notification.error(error.response.data.message);
        this.loading = false;
      });
    },

    useTemplate(template){
      this.openAddNewModal = true;
      this.$refs.createModal.loading = true;
      this.$refs.createModal.formData.subject = template.subject
      this.$refs.createModal.formData.body = template.body
      this.$refs.createModal.loading = false;
    },

    getEmails(){
      this.loading = true;
      Communication.index(this.page).then((res)=>{
        let emails = res.data.data;
        this.lastPage = res.data.meta.last_page;
        emails.forEach((email)=>{
          this.tableData.push({
            'id'                   : email.id,
            'name'                 : email.name,
            'subject'              : email.subject,
            'body'                 : email.body,
            'date'                 : email.date,
            'number_of_recipients' : email.number_of_recipients,
            'recipient'            : email.recipient,
          })

        })

        this.loading = false;
      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },

    addNewEmail(){
      this.openAddNewModal = true;
    },

    addedNewEmail() {
      this.openAddNewModal = false;
      this.tableData = [];
      this.page = 1;
      this.getEmails();
    },

    goToViewEmailPage(id){
      this.$router.push(`/email-preview?id=${id}`)
    },

    destroy(id) {
      this.openDeleteModal = true;
      this.emailId = id;

    },

    deleteEmail(){
      Communication.destroy(this.emailId).then(response => {

        this.openDeleteModal = false;
        this.$notify({
          title: 'Success',
          message: 'Email Deleted Successfully.',
          type: 'success'
        });

        this.tableData = [];
        this.page = 1;
        this.getEmails();

      }).catch(() => {
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },

    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getEmails();
    },

    viewEmail(id){
      this.$router.push(`/communication/${id}`)
    },

  },
}
</script>

<style scoped>
.create-section{
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 99 !important;
}

.create-button{
  width: auto;
  height: auto;
  border: 1px solid #4F31FF;
  padding: 10px 25px;
  border-radius: 10px;
  background: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4F31FF;
}
.create-button-small{
  width: auto;
  height: auto;
  border: 1px solid #4F31FF;
  padding: 10px 25px;
  border-radius: 10px;
  background: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4F31FF;
  display: none;
}

.create-button:hover{
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}


.temp-name{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.fund-name{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.fund-name:hover{
  color: #4F31FF;
  cursor: pointer;
  text-decoration: underline;
}

.fund-des{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
  opacity: 0.8;
}

.description{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #092C4C;
  opacity: 0.8;
}

.template-card{
  box-sizing: border-box;
  width: 100%;
  height: 348px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 25px;
  position: relative;
}

.template-button{
  position: absolute;
  bottom: 20px;
}



@media (max-width: 576px) {
  .button-margin-bottom {
    margin-bottom: 15px;
  }

  .template-card{
    height: auto;
  }

  .template-button{
    position: static;
  }

  .fund-name{
    font-size: 14px;
    line-height: 20px;
  }

  .create-button-small{
    display: block;
    padding: 6px 15px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 17px;
    margin-top: 10px;
  }

  .create-button{
    display: none;
  }

}

@media (max-width: 1200px) {
  .template-card{
    height: auto;
    min-height: 400px;
  }
  .template-button{
    position: static;
    margin-top: 10px;
  }
}




</style>