export const errorMessage = (error, notifyError) => {
    if (process.env.NODE_ENV === "development") {
        console.error(error);
    }

    if (notifyError && typeof error === "string") {
        notifyError({
            title: 'Error',
            message: error
        })
    }
}

export const formErrors = (data, notifyError) => {
    errorMessage(data.message, notifyError);
    let errors = data.errors
    for (let field in errors) {
        for (let e of errors[field]) {
            errorMessage(e, notifyError);
        }
    }
}