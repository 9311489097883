<template>
  <div class="row commitment-container">
    <div class="col-12">
      <div class="card rounded-3 shadow-none">
        <div class="card-body commitment-card">
          <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%;margin-top: -22px;"
              header-row-class-name="table-header-row"
              row-class-name="table-row"
          >
            <el-table-column
                label="Investors"
                min-width="300"
            >
              <template slot-scope="scope">
                <span
                    @click.prevent="$router.push(`/investor/${scope.row.investor_id}`)"
                     class="fund-name fund-overview">{{
                    scope.row.title
                  }}</span>
                <div class="fund-description">{{ scope.row.address }}</div>
              </template>
            </el-table-column>

            <el-table-column
                label="Committed Capital"
                min-width="200"
            >
              <template slot-scope="scope">
                <span class="capital">${{ scope.row.committed_amount | number}}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="Status"
                min-width="200"
            >
              <template slot-scope="scope">
                <div class="dropdown-box">
                    <span :class="scope.row.status === 1 ?
                    'dropdown-status-open' : 'dropdown-status-close' "></span>
                  <el-select @change="updateStatus(scope.row.id,scope.row.status)"
                      v-model="scope.row.status"
                      placeholder="Select">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>

              </template>
            </el-table-column>

            <el-table-column
                label="Actions"
                width="150"
            >
              <template slot-scope="scope">

                <button
                    v-if="checkPermission('fund_all','fund_edit')"
                    class="mini-button"
                    @click.prevent="editInvestor(scope.row.id)"
                >
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.91875 10.339L9.21498 3.48995C9.50282 3.12061 9.60517 2.6936 9.50922 2.25881C9.42607 1.86355 9.183 1.48773 8.81841 1.20262L7.9293 0.496331C7.15534 -0.11924 6.19587 -0.0544431 5.64578 0.651844L5.05091 1.42358C4.97416 1.52012 4.99335 1.66268 5.08929 1.74043C5.08929 1.74043 6.59245 2.94566 6.62443 2.97158C6.72678 3.06877 6.80353 3.19836 6.82272 3.35388C6.8547 3.65842 6.64362 3.94353 6.3302 3.98241C6.18308 4.00185 6.04236 3.95649 5.94002 3.87225L4.3601 2.61519C4.28334 2.55752 4.16821 2.56983 4.10424 2.64759L0.349543 7.50736C0.106479 7.81191 0.0233251 8.20717 0.106479 8.58947L0.58621 10.6695C0.611796 10.7796 0.707742 10.8574 0.822878 10.8574L2.9337 10.8314C3.31748 10.825 3.67568 10.65 3.91875 10.339ZM6.87434 9.69136H10.3163C10.6521 9.69136 10.9252 9.96804 10.9252 10.3082C10.9252 10.6491 10.6521 10.9251 10.3163 10.9251H6.87434C6.53853 10.9251 6.26541 10.6491 6.26541 10.3082C6.26541 9.96804 6.53853 9.69136 6.87434 9.69136Z"
                          fill="#230B34"/>
                  </svg>
                </button>

                <button
                    v-if="checkPermission('fund_all','fund_edit')"

                    class="mini-button"
                    @click.prevent="deleteInvestor(scope.row.id)"
                >
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                          fill="#230B34"/>
                  </svg>
                </button>
              </template>
            </el-table-column>

          </el-table>

          <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

<!--          <div class="d-flex justify-content-end mt-3">-->
<!--            <el-pagination-->
<!--                background-->
<!--                layout="prev, pager, next"-->
<!--                @current-change="handleCurrentChange"-->
<!--                :total="pagination.total_page"-->
<!--                :page-size="pagination.per_page">-->
<!--            </el-pagination>-->
<!--          </div>-->

        </div>
      </div>
    </div>

    <create-commitment-modal
        :open="openCreateCommitmentModal"
        :disabled="btnDisabled"
        :accounts="accounts"
        @close="handleClose"
        @cancel="openCreateCommitmentModal = false"
        @openInvestorModal="openInvestorModal"
        @submitted="CreatedNewCommittedInvestor"
    />

    <edit-commitment-modal
        :open="openEditCommitmentModal"
        :disabled="btnDisabled"
        @close="openEditCommitmentModal = false"
        @cancel="openEditCommitmentModal = false"
        ref="editModal"
        @submitted="editedInvestor"
    />

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deletedInvestor"
    />



  </div>
</template>

<script>
import Auth from "@/apis/Auth";
import FundCommitment from "@/apis/FundCommitment";
import CreateCommitmentModal
  from "@/components/commitment/CreateCommitmentModal";
import EditCommitmentModal from "@/components/commitment/EditCommitmentModal";
import DeleteModal from "@/components/common/DeleteModal";
import {Notification} from 'element-ui';
import router from "@/router";


export default {
  name: "List",
  components: {CreateCommitmentModal,EditCommitmentModal,DeleteModal},
  props: {
    openCreateCommitmentModal: {
      type: Boolean,
      default: () => false
    },
    accounts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      btnDisabled: false,
      openEditFundModal: false,
      openEditCommitmentModal: false,
      openDeleteModal: false,
      tableData: [],
      permissions: [],
      fundId: '',
      pagination: {
        total_page: 1,
        per_page: 10,
      },
      page: 1,
      lastPage: 1,
      options: [
        {
          value: 1,
          label: 'Active'
        }, {
          value: 0,
          label: 'Pending'
        }],
      loading: false,
    };
  },
  methods: {
    router() {
      return router
    },
    handleClose(){
      this.$emit('close')
    },
    async getInvestors() {
      this.loading = true;

      await FundCommitment.index(this.page,this.$route.params.id).then((res)=>{
        this.loading = false;
        let investors = res.data.data;

        this.lastPage  = res.data.meta.last_page;

        investors.forEach((investor)=>{
          this.tableData.push({
            'id'               : investor.id,
            'title'            : investor.investor_name,
            'investor_id'      : investor.investor_id,
            'investor_type'    : investor.investor_type,
            'address'          : investor.investor_address,
            'committed_amount' : investor.committed_amount,
            'status'           : investor.status,
          })
        })

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });

    },

    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getInvestors();
    },

    async editInvestor(id){

      this.openEditCommitmentModal = true;
      this.$refs.editModal.loading = true;

      await FundCommitment.show(id).then((res)=>{
        let data = res.data.data;
        let fund = {};

        let tempNumber = data.committed_amount.replace(/,/gi, "");
        let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

        fund.fund_id           = this.$route.params.id;
        fund.investor_id       = data.investor_id;
        fund.committed_amount  = commaSeparatedNumber;
        fund.status            = data.status;

        this.$refs.editModal.id = data.id;
        this.$refs.editModal.formData = fund;
        this.$refs.editModal.loading = false;

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.$refs.editModal.loading = false;
      })

    },

    editedInvestor() {
      this.openEditCommitmentModal = false;
      this.tableData = [];
      this.page = 1;
      this.getInvestors();
    },

    deleteInvestor(id){
      this.openDeleteModal = true;
      this.fundId = id;
    },

    deletedInvestor(){
      FundCommitment.destroy(this.fundId).then(() => {

        this.openDeleteModal = false;
        this.$notify({
          title: 'Success',
          message: 'Investor Deleted Successfully.',
          type: 'success'
        });

        this.tableData = [];
        this.page = 1;
        this.getInvestors();

      }).catch(() => {
        Notification.error("Something went wrong!");
      });
    },

    handleCurrentChange(page) {
      this.getInvestors(page);
    },

    CreatedNewCommittedInvestor() {
      this.$emit('close',false);
      this.tableData = [];
      this.page = 1;
      this.getInvestors();
    },

    async updateStatus(id,status){
      await FundCommitment.updateStatus(id,status).then(()=>{
        this.$notify({
          title: 'Success',
          message: 'Commitment Status Updated Successfully.',
          type: 'success'
        });

      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    openInvestorModal(){
      this.$emit('openInvestorModal')
    },

    async getPermissions(){
      await Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;

    }

  },

  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getInvestors()
  }
}
</script>

<style scoped>
.fund-name{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.fund-overview:hover{
  cursor: pointer;
  color: #0a53be;
  text-decoration: underline;
}

.fund-description{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505968;
  opacity: 0.8;
  margin-top: -5px;
}

.capital{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  opacity: 0.8;
}
</style>