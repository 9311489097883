<template>
  <div class="container-fluid p-0 mt-n1 investor-container">

    <div class="title-section">
      <h1 class="list-title">Investor List</h1>
      <div>
        <button @click.prevent="manageFilterOption" class="filter-button">
          Filter

          <svg v-if="showFilterBox === false" width="7" height="11" viewBox="0 0 7 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path d="M1.20914 10.3215L6.18731 6.05564C6.26751 5.98688 6.33189 5.90159 6.37603 5.80561C6.42016 5.70963 6.44301 5.60524 6.44301 5.4996C6.44301 5.39396 6.42016 5.28957 6.37603 5.19359C6.33189 5.09761 6.26751 5.01231 6.18731 4.94355L1.20914 0.677679C0.733969 0.270563 -4.32389e-07 0.608097 -4.05042e-07 1.23373L-3.2054e-08 9.76669C-4.70686e-09 10.3923 0.733969 10.7299 1.20914 10.3215Z" fill="#505968"/>
          </svg>

          <svg v-if="showFilterBox === true" width="7" height="11"
               viewBox="0 0 7 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path d="M5.23385 10.3215L0.255678 6.05564C0.175479 5.98688 0.111103 5.90159 0.0669672 5.80561C0.0228311 5.70963 -2.10798e-05 5.60524 -2.10752e-05 5.4996C-2.10706e-05 5.39396 0.0228311 5.28957 0.0669672 5.19359C0.111103 5.09761 0.175479 5.01231 0.255678 4.94355L5.23385 0.677679C5.70902 0.270563 6.44299 0.608097 6.44299 1.23373L6.44299 9.76669C6.44299 10.3923 5.70902 10.7299 5.23385 10.3215V10.3215Z" fill="#505968"/>
          </svg>
        </button>

        <span v-if="showFilterBox === true">
          <el-select @change="searchInvestors" v-model="formData.investor_type"
                     style="width: 82px; margin-right: 10px;"
                     placeholder="Type">
            <el-option
                v-for="item in investor_type"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

<!--          <el-select v-model="value" style="width: 82px; margin: 10px;"-->
<!--                     placeholder="Tags">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->

<!--          <el-select v-model="value" style="width: 108px;" placeholder="Location">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->

<!--          <el-select v-model="value" style="width: 150px; margin: 10px;"-->
<!--                     placeholder="Active Position">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->

<!--          <el-select v-model="value" style="width: 210px; margin-right: 10px;"-->
<!--                     placeholder="Tax Document Delivery">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->

        </span>

        <span style="margin-right: 10px;">
          <span class="dropdown">
            <button class="dropdown-button dropdown-toggle" type="button"
                    id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              Columns
            </button>
            <ul class="dropdown-menu dropdown-menu-box mt-5"
                aria-labelledby="dropdownMenuClickableInside">
              <div class="dropdown-menu-header dropdown-menu-header-custom">
                <div class="d-flex justify-content-between">
                  All
                  <el-switch
                      @change="handleAllColumnChange"
                      v-model="tableColumn.all"
                      active-color="#4F31FF"
                      inactive-color="#E5E5E5">
                  </el-switch>
                </div>
              </div>

              <li class="dropdown-item dropdown-item-custom">
                <div class="d-flex justify-content-between">
                  Investor Name
                  <el-switch
                      @change="handleSingleColumnChange"
                      v-model="tableColumn.investor_name"
                      active-color="#4F31FF"
                      inactive-color="#E5E5E5">
                  </el-switch>
                </div>
              </li>
              <li class="dropdown-item dropdown-item-custom">
                <div class="d-flex justify-content-between">
                  Funds
                  <el-switch
                      @change="handleSingleColumnChange"
                      v-model="tableColumn.funds"
                      active-color="#4F31FF"
                      inactive-color="#E5E5E5">
                  </el-switch>
                </div>
              </li>
              <li class="dropdown-item dropdown-item-custom">
                <div class="d-flex justify-content-between">
                  Investor Type
                  <el-switch
                      @change="handleSingleColumnChange"
                      v-model="tableColumn.investor_type"
                      active-color="#4F31FF"
                      inactive-color="#E5E5E5">
                  </el-switch>
                </div>
              </li>

              <li class="dropdown-item dropdown-item-custom">
                <div class="d-flex justify-content-between">
                  Action
                  <el-switch
                      @change="handleSingleColumnChange"
                      v-model="tableColumn.action"
                      active-color="#4F31FF"
                      inactive-color="#E5E5E5">
                  </el-switch>
                </div>
              </li>
            </ul>
          </span>

        </span>

        <button v-if="checkPermission('fund_all','fund_create')"
                 @click.prevent="openAddInvestorModal = true" class="common-button">
          <i class="fa fa-plus"></i> Add New Investor
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card rounded-3 shadow-none card-margin-top">
          <div class="card-body investor-card">

            <span v-if="tableColumn.investor_name === false &&
              tableColumn.funds  === false  &&
              tableColumn.investor_type === false &&
              tableColumn.action === false">
            </span>

            <el-table v-else
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                header-row-class-name="table-header-row"
                row-class-name="table-row"
                v-loading="loading"
            >
              <el-table-column
                  label="Investor Name"
                  min-width="180"
                  v-if="tableColumn.investor_name"
              >
                <template slot="header" slot-scope="scope">
                  <span v-if="showFilterBox === false"
                       class="el-form-item__label">Investor Name</span>
                  <el-form  :model="formData" v-else @submit.native.prevent="searchInvestors">
                    <el-form-item label="Investor Name">
                      <el-input @input="searchInvestors" placeholder="Search"
                                v-model="formData.title">
                        <svg class="input-search-icon" slot="prefix" width="12"
                             height="12"
                              viewBox="0 0 12 12"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.28571 9.40108C7.65264 9.40108 9.57142 7.52043 9.57142 5.20054C9.57142 2.88064 7.65264 1 5.28571 1C2.91878 1 1 2.88064 1 5.20054C1 7.52043 2.91878 9.40108 5.28571 9.40108Z" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.9999 10.8012L8.85706 8.70093" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </template>

                <template slot-scope="scope">
                  <div v-if="checkPermission('investor_all','investor_edit')"
                       @click.prevent="viewInvestor(scope.row.id)"
                       class="fund-name fund-overview">{{ scope.row.title }}</div>

                  <div v-else class="fund-name">{{ scope.row.title }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  label="Funds"
                  min-width="350"
                  v-if="tableColumn.funds"
              >
                <template slot="header" slot-scope="scope">
                  <span v-if="showFilterBox === false" class="el-form-item__label">Funds</span>
                  <el-form :model="formData" v-else
                           @submit.native.prevent="searchInvestors">
                    <el-form-item label="Funds">
                      <el-input @input="searchInvestors" placeholder="Search"
                                 v-model="formData.fund_name">
                        <svg class="input-search-icon" slot="prefix" width="12"
                             height="12"
                             viewBox="0 0 12 12"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.28571 9.40108C7.65264 9.40108 9.57142 7.52043 9.57142 5.20054C9.57142 2.88064 7.65264 1 5.28571 1C2.91878 1 1 2.88064 1 5.20054C1 7.52043 2.91878 9.40108 5.28571 9.40108Z" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.9999 10.8012L8.85706 8.70093" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </template>

                <template slot-scope="scope">
                  <div v-if="scope.row.funds.length > 0">
                    <span v-for="(fund,index) in scope.row.funds"
                          :key="fund.id" class="investor-des">
                        {{ fund.name}}
                      <span v-if="index + 1 < scope.row.funds.length">,</span>
                    </span>
                  </div>
                </template>

              </el-table-column>
              <el-table-column
                  label="Investor Type"
                  min-width="90"
                  v-if="tableColumn.investor_type"
              >
                <template slot="header" slot-scope="scope">
                  <span v-if="showFilterBox === false"
                     class="el-form-item__label">Investor Type</span>
                  <el-form :model="formData" v-else
                           @submit.native.prevent="searchInvestors">
                    <el-form-item label="Investor Type">
                      <el-input @input="searchInvestors" placeholder="Search"
                                 v-model="formData.investor_type">
                        <svg class="input-search-icon" slot="prefix" width="12"
                             height="12"
                             viewBox="0 0 12 12"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.28571 9.40108C7.65264 9.40108 9.57142 7.52043 9.57142 5.20054C9.57142 2.88064 7.65264 1 5.28571 1C2.91878 1 1 2.88064 1 5.20054C1 7.52043 2.91878 9.40108 5.28571 9.40108Z" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.9999 10.8012L8.85706 8.70093" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </template>

                <template slot-scope="scope">
                  <span class="investor-type-button">{{
                      scope.row.investor_type }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="Actions"
                  width="150"
                  v-if="tableColumn.action"
              >

                <template slot="header" slot-scope="scope">
                  <span v-if="showFilterBox === false"
                     class="el-form-item__label">Actions</span>
                  <el-form v-else  @submit.native.prevent="">
                    <el-form-item label="Actions">
                      <el-input style="visibility: hidden;" placeholder="Search"
                                v-model="search">
                        <svg class="input-search-icon" slot="prefix" width="12"
                             height="12"
                             viewBox="0 0 12 12"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.28571 9.40108C7.65264 9.40108 9.57142 7.52043 9.57142 5.20054C9.57142 2.88064 7.65264 1 5.28571 1C2.91878 1 1 2.88064 1 5.20054C1 7.52043 2.91878 9.40108 5.28571 9.40108Z" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.9999 10.8012L8.85706 8.70093" stroke="#818295" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </template>

                <template slot-scope="scope">

                  <el-tooltip content="Edit" placement="top">
                    <button
                        v-if="checkPermission('investor_all','investor_edit')"
                        class="mini-button"
                        @click.prevent="edit(scope.row)"
                    >
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.91875 10.339L9.21498 3.48995C9.50282 3.12061 9.60517 2.6936 9.50922 2.25881C9.42607 1.86355 9.183 1.48773 8.81841 1.20262L7.9293 0.496331C7.15534 -0.11924 6.19587 -0.0544431 5.64578 0.651844L5.05091 1.42358C4.97416 1.52012 4.99335 1.66268 5.08929 1.74043C5.08929 1.74043 6.59245 2.94566 6.62443 2.97158C6.72678 3.06877 6.80353 3.19836 6.82272 3.35388C6.8547 3.65842 6.64362 3.94353 6.3302 3.98241C6.18308 4.00185 6.04236 3.95649 5.94002 3.87225L4.3601 2.61519C4.28334 2.55752 4.16821 2.56983 4.10424 2.64759L0.349543 7.50736C0.106479 7.81191 0.0233251 8.20717 0.106479 8.58947L0.58621 10.6695C0.611796 10.7796 0.707742 10.8574 0.822878 10.8574L2.9337 10.8314C3.31748 10.825 3.67568 10.65 3.91875 10.339ZM6.87434 9.69136H10.3163C10.6521 9.69136 10.9252 9.96804 10.9252 10.3082C10.9252 10.6491 10.6521 10.9251 10.3163 10.9251H6.87434C6.53853 10.9251 6.26541 10.6491 6.26541 10.3082C6.26541 9.96804 6.53853 9.69136 6.87434 9.69136Z"
                              fill="#230B34"/>
                      </svg>
                    </button>
                  </el-tooltip>

                  <el-tooltip content="Delete" placement="top">
                    <button
                        v-if="checkPermission('investor_all','investor_delete')"
                        class="mini-button"
                        @click.prevent="destroy(scope.row.id)"
                    >
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                              fill="#230B34"/>
                      </svg>
                    </button>
                  </el-tooltip>

                </template>
              </el-table-column>

            </el-table>

            <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

          </div>
        </div>
      </div>
    </div>

    <account-create-modal
        v-if="openAddInvestorModal"
        :open="openAddInvestorModal"
        @cancel="openAddInvestorModal = false"
        @close="openAddInvestorModal = false"
        @submitted="addedNewInvestor"
    />

    <account-edit-modal
        v-if="openEditInvestorModal"
        :open="openEditInvestorModal"
        :selectedAccount="selectedAccount"
        @cancel="openEditInvestorModal = false"
        @close="openEditInvestorModal = false"
        @submitted="editedInvestorData"
    />

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteInvestor"
    />


  </div>
</template>

<script>
import Auth from "@/apis/Auth";
import Account from "@/apis/Account";
import AccountCreateModal from "@/components/portal/AccountCreateModal";
import AccountEditModal from "@/components/portal/AccountEditModal";
import DeleteModal from "../../components/common/DeleteModal";
import _ from 'lodash'
import {Notification} from "element-ui";


export default {
  name: "InvestorList",
  data(){
    return{
      formData: {
        title: '',
        fund_name: '',
        investor_type: '',
      },

      tableColumn: {
        all: true,
        investor_name: true,
        funds: true,
        investor_type: true,
        action: true,
      },

      openDeleteModal: false,
      btnDisabled: false,

      openAddInvestorModal: false,
      openEditInvestorModal: false,
      search: '',
      showFilterBox: false,

      pagination: {
        total_page: 1,
        per_page: 10,
      },

      page: 1,
      lastPage: 1,

      investor_type: [
        {
          value: "Fund Manager",
          label: "Fund Manager",
        },
        {
          value: "Individual",
          label: "Individual",
        },
        {
          value: "Endowment",
          label: "Endowment",
        },
      ],

      options: [
        {
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }, {
          value: 'Option5',
          label: 'Option5'
        }
      ],
      value: '',
      showSingleFund: true,

      tableData: [],
      investorId: null,
      selectedAccount: {},
      permissions: [],

      loading: true,
    }
  },
  components: {AccountCreateModal,AccountEditModal,DeleteModal},
  methods: {
    // async getInvestors(page=1){
    //   this.loading = true
    //   await
    //       Account.allAccounts(page,this.formData.title,this.formData.fund_name,this.formData.investor_type).then((res)=>{
    //     this.loading = false;
    //
    //     let investors = res.data.data;
    //
    //     this.pagination.total_page = res.data.meta.total;
    //     this.pagination.per_page  = res.data.meta.per_page;
    //
    //     this.tableData = [];
    //
    //     investors.forEach((investor)=>{
    //       this.tableData.push({
    //         'id' : investor.id,
    //         'uid' : investor.uid,
    //         'title' : investor.title,
    //         'investor_type' : investor.investor_type,
    //         'bank_name' : investor.bank_name,
    //         'account_name' : investor.account_name,
    //         'account_number' : investor.account_number,
    //         'address' : investor.address,
    //         'tax_id_type' : investor.tax_id_type,
    //         'tax_id_value' : investor.tax_id_value,
    //         'funds' : investor.funds,
    //         'users' : investor.users,
    //       })
    //     })
    //
    //   }).catch((error)=>{
    //
    //     if (error.response) {
    //       this.formErrors(error.response.data, this.$notify.error);
    //     } else {
    //       this.errorMessage(error);
    //     }
    //     this.loading = false;
    //   });
    // },

    handleAllColumnChange(){
      if (this.tableColumn.all === true){
        this.tableColumn.investor_name = true
        this.tableColumn.funds = true
        this.tableColumn.investor_type = true
        this.tableColumn.action = true

      }else {
        this.tableColumn.investor_name = false
        this.tableColumn.funds = false
        this.tableColumn.investor_type = false
        this.tableColumn.action = false
      }
    },
    handleSingleColumnChange(){
      this.tableColumn.all =
          this.tableColumn.investor_name === true &&
          this.tableColumn.funds === true &&
          this.tableColumn.investor_type === true &&
          this.tableColumn.action === true;
    },

    async getInvestors(){
      // this.loading = true
      await
          Account.allAccounts(this.page,this.formData.title,this.formData.fund_name,this.formData.investor_type).then((res)=>{

            let investors = res.data.data;

            this.lastPage = res.data.meta.last_page;
            this.tableData = [];

            investors.forEach((investor)=>{
              this.tableData.push({
                'id' : investor.id,
                'uid' : investor.uid,
                'title' : investor.title,
                'investor_type' : investor.investor_type,
                'bank_name' : investor.bank_name,
                'account_name' : investor.account_name,
                'account_number' : investor.account_number,
                'address' : investor.address,
                'tax_id_type' : investor.tax_id_type,
                'tax_id_value' : investor.tax_id_value,
                'funds' : investor.funds,
                'users' : investor.users,
              })
            })

            this.loading = false;

          }).catch(()=>{
            Notification.error("Something went wrong!");
            this.loading = false;
          });
    },

    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getInvestors();
    },

    searchInvestors:_.debounce(function (){
          Account.allAccounts(1,this.formData.title,this.formData.fund_name,this.formData.investor_type).then((res)=>{
            let investors = res.data.data;

            this.lastPage = res.data.meta.last_page;
            this.tableData = [];

            investors.forEach((investor)=>{
              this.tableData.push({
                'id' : investor.id,
                'uid' : investor.uid,
                'title' : investor.title,
                'investor_type' : investor.investor_type,
                'bank_name' : investor.bank_name,
                'account_name' : investor.account_name,
                'account_number' : investor.account_number,
                'address' : investor.address,
                'tax_id_type' : investor.tax_id_type,
                'tax_id_value' : investor.tax_id_value,
                'funds' : investor.funds,
                'users' : investor.users,
              })
            })

            this.loading = false;
          }).catch(()=>{
            Notification.error("Something went wrong!");
            this.loading = false;
          });

    },500),


    handleCurrentChange(page) {
      this.getInvestors(page);
    },

    manageFilterOption(){
      this.showFilterBox=!this.showFilterBox
    },

    addedNewInvestor() {
      this.openAddInvestorModal = false;
      this.tableData = [];
      this.page = 1;
      this.getInvestors();
    },

    viewInvestor(id){
      this.$router.push(`/investor/${id}`)
    },

    async edit(investor){
      this.selectedAccount = investor;
      this.openEditInvestorModal = true;
    },

    editedInvestorData() {
      this.openEditInvestorModal = false;
      this.tableData = [];
      this.page = 1;
      this.getInvestors()
    },

    destroy(id) {
      this.openDeleteModal = true;
      this.investorId = id;
    },

    async deleteInvestor(){
      await Account.destroy(this.investorId).then(response => {

        this.openDeleteModal = false;
        this.$notify({
          title: 'Success',
          message: "Investor Account Deleted Successfully.",
          type: 'success'
        });

        this.tableData = [];
        this.page = 1;
        this.getInvestors();

      }).catch(() => {
        Notification.error("Something went wrong!");
      });
    },

    async getPermissions(){
      await Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;

    }
  },
  created() {
    this.getPermissions();
  },

  mounted() {
    this.checkPermission();
    this.getInvestors()
  }
}
</script>

<style scoped>
.fund-name{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}


.fund-overview:hover{
  cursor: pointer;
  color: #0a53be;
  text-decoration: underline;
}

.investor-des{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  opacity: 0.8;
}

.expand-button{
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #0a0a0a;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  margin-bottom: -5px;
}

.expand-button .expand-button-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

.filter-button{
  width: 64.44px;
  height: 39px;
  background: #F3F3F3;
  border: 1px solid #505968;
  border-radius: 5px;
  margin-right: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;

}

.input-search-icon{
  margin-top: -3px;
  margin-left: 6px;
}


@media (max-width: 991px) {
  .card-margin-top{
    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .filter-button{
    width: 62px;
    height: 34px;
  }
}


</style>