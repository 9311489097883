<template>
  <el-dialog
      title="Create New Fund"
      custom-class="modal-custom-class"
      :visible.sync="open"
      width="642px"
      :before-close="handleClose">
    <el-form
        ref="form"
        class="acceson-form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
    >

      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Fund Name" prop="name">
            <el-input
                v-model="formData.name"
                placeholder="Enter Fund Name"
            />
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item label="Fund Description" prop="description">
            <el-input type="textarea"
                      v-model="formData.description"
                      placeholder="Enter Fund Description"
            />
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item label="Vintage" prop="vintage">
            <el-date-picker
                style="width: 100%"
                v-model="formData.vintage"
                type="year"
                placeholder="Pick a year">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item label="Vehicle Type" prop="vehicle_type">
            <el-select v-model="formData.vehicle_type"  style="width: 100%" placeholder="Select">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

      </div>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
          class="cancel-button common-button mx-3"
          @click="$emit('cancel', true)"
          :disabled="disabled"
      >Cancel
      </button>
      <el-button
          class="common-button"
          @click="handleSubmit('form')"
          :disabled="disabled"
          :loading="loading"
      >Save
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import Fund from "@/apis/Fund";
import moment from "moment";

export default {
  name: "AddNewModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        name: '',
        description: '',
        vintage: '',
        vehicle_type: '',
      },
      rules: {
        name: {
          required: true,
          message : 'Fund Name is required'
        },
        description: {
          required: true,
          message : 'Fund Description is required'
        },
        vintage: {
          required: true,
          message : 'Vintage is required'
        },
        vehicle_type: {
          required: true,
          message : 'Vehicle Type is required'
        },
      },
      options: [
        {
          value: 'LP',
          label: 'LP'
        }, {
          value: 'SPV',
          label: 'SPV'
        }],
      loading: false
    }
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.callToBackendFundApi();
        } else {
          this.$notify.error('Please insert Required Field!')
          return false;
        }
      });

    },

    async callToBackendFundApi(){

      await Fund.store(this.formData).then((res)=>{
        this.$notify({
          title: "Success!",
          message: "Fund Created Successfully.",
          type: "success",
        });
        this.clearFormData();
        this.$emit('submitted', res.data)
        this.loading = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;
      })

    },
    clearFormData(){
      this.formData.name = '';
      this.formData.description = '';
      this.formData.vintage = '';
      this.formData.vehicle_type = '';
    }

  },



}


</script>

<style scoped>

</style>