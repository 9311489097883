<template>
  <div class="container-fluid login-container">
    <div class="row">
      <div class="col-md-6 left-section">
        <img class="img-fluid" src="../../assets/images/login_image.svg"
             alt="Login-Image">
      </div>
      <div class="col-md-6 right-section">
        <div class="form-section">
          <div class="mx-auto mb-4">
            <svg width="250" height="50" viewBox="0 0 120 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M23.4725 0H0V23.4725H23.4725V0Z" fill="#4F31FF"/>
              <path d="M16.2196 8.83524L16.2031 11.5056L9.93937 11.4891V17.7858H7.26904V5.70337H9.93937V8.81875L16.2196 8.83524Z" fill="white"/>
              <path d="M16.2196 15.1155H13.5493V17.7858H16.2196V15.1155Z" fill="white"/>
              <path d="M39.346 5.68677V17.8351H37.3515V12.4615H31.6317V17.8351H29.6372V5.68677H31.6317V10.9285H37.3515V5.68677H39.346Z" fill="#092C4C"/>
              <path d="M50.5877 8.19238V17.8188H48.5932V16.3188C48.28 16.8627 47.8515 17.2748 47.324 17.555C46.7965 17.8352 46.1866 17.9671 45.5108 17.9671C44.8185 17.9671 44.1756 17.8023 43.6152 17.4891C43.0548 17.1759 42.5932 16.7144 42.2635 16.1045C41.9339 15.4946 41.769 14.7528 41.769 13.8792V8.19238H43.7471V13.5825C43.7471 14.4396 43.9613 15.099 44.3899 15.5605C44.8185 16.0221 45.4119 16.2528 46.1537 16.2528C46.8954 16.2528 47.4888 16.0221 47.9339 15.5605C48.3789 15.099 48.5932 14.4396 48.5932 13.5825V8.19238H50.5877Z" fill="#092C4C"/>
              <path d="M56.258 8.52197C56.736 8.20878 57.3129 8.04395 57.9723 8.04395V10.1044H57.4613C56.6866 10.1044 56.0931 10.3022 55.6811 10.6978C55.2854 11.0934 55.0712 11.7857 55.0712 12.7582V17.8352H53.0767V8.1923H55.0712V9.87362C55.3844 9.29669 55.78 8.85164 56.258 8.52197Z" fill="#092C4C"/>
              <path d="M62.5713 10.1538C62.1262 10.401 61.7636 10.7636 61.4999 11.2581C61.2197 11.7362 61.0878 12.3131 61.0878 12.9889C61.0878 13.6483 61.2197 14.2417 61.4999 14.7362C61.7801 15.2307 62.1427 15.6263 62.5878 15.8735C63.0328 16.1373 63.5109 16.2691 64.0054 16.2691C64.5164 16.2691 64.9944 16.1373 65.4394 15.89C65.8845 15.6263 66.2471 15.2636 66.5109 14.7691C66.7911 14.2746 66.9229 13.6977 66.9229 13.0219C66.9229 12.3461 66.7911 11.7856 66.5109 11.2911C66.2306 10.7966 65.8845 10.434 65.4394 10.1702C64.9944 9.9065 64.5164 9.79111 64.0054 9.79111C63.4944 9.77463 63.0164 9.89001 62.5713 10.1538ZM65.5878 8.55485C66.1647 8.901 66.6098 9.34606 66.9229 9.9065V4.89551H68.9339V17.8351H66.9229V16.0384C66.5933 16.6318 66.1482 17.1098 65.5713 17.456C64.9944 17.8186 64.335 17.9834 63.5768 17.9834C62.7361 17.9834 61.9779 17.7691 61.2856 17.3406C60.5933 16.912 60.0493 16.3186 59.6372 15.5603C59.2416 14.8021 59.0273 13.9285 59.0273 12.9559C59.0273 11.9834 59.2251 11.1263 59.6372 10.3845C60.0328 9.64276 60.5933 9.06584 61.2856 8.63727C61.9779 8.22518 62.7526 8.01089 63.5768 8.01089C64.3515 8.04386 65.0109 8.2087 65.5878 8.55485Z" fill="#092C4C"/>
              <path d="M73.4506 4.89551V17.8351H71.4561V4.89551H73.4506Z" fill="#092C4C"/>
              <path d="M82.7142 12.3297C82.6977 11.5385 82.434 10.9121 81.923 10.4341C81.412 9.95604 80.7692 9.72527 80.0109 9.72527C79.3021 9.72527 78.6922 9.95604 78.1977 10.4341C77.7032 10.9121 77.423 11.5385 77.3571 12.3297H82.7142ZM84.7252 13.6978H77.3406C77.39 14.5055 77.6538 15.1484 78.1483 15.6099C78.6428 16.0879 79.2692 16.3187 80.0109 16.3187C81.0988 16.3187 81.8571 15.8736 82.3021 14.967H84.445C84.1483 15.8571 83.6208 16.5824 82.8461 17.1429C82.0714 17.7033 81.1318 17.9835 79.9944 17.9835C79.0714 17.9835 78.2637 17.7692 77.5384 17.3571C76.8296 16.9451 76.2692 16.3681 75.8736 15.6099C75.478 14.8516 75.2637 13.9945 75.2637 12.989C75.2637 12 75.4615 11.1264 75.8736 10.3681C76.2692 9.60989 76.8461 9.03296 77.5714 8.62088C78.2966 8.20879 79.1373 8.01099 80.0769 8.01099C80.9835 8.01099 81.7911 8.20879 82.4999 8.60439C83.2087 9 83.7692 9.56044 84.1648 10.2692C84.5604 10.978 84.7582 11.8022 84.7582 12.7253C84.8076 13.0879 84.7747 13.4011 84.7252 13.6978Z" fill="#092C4C"/>
              <path d="M96.3626 5.68677V17.8351H94.3681V12.4615H88.6483V17.8351H86.6538V5.68677H88.6483V10.9285H94.3681V5.68677H96.3626Z" fill="#092C4C"/>
              <path d="M107.604 8.19238V17.8188H105.61V16.3188C105.297 16.8627 104.868 17.2748 104.341 17.555C103.813 17.8352 103.203 17.9671 102.527 17.9671C101.835 17.9671 101.192 17.8023 100.632 17.4891C100.071 17.1759 99.6098 16.7144 99.2802 16.1045C98.9505 15.4946 98.7856 14.7528 98.7856 13.8792V8.19238H100.764V13.5825C100.764 14.4396 100.978 15.099 101.407 15.5605C101.835 16.0221 102.429 16.2528 103.17 16.2528C103.912 16.2528 104.505 16.0221 104.95 15.5605C105.396 15.099 105.61 14.4396 105.61 13.5825V8.19238H107.604Z" fill="#092C4C"/>
              <path d="M113.604 10.1538C113.159 10.4175 112.797 10.7801 112.517 11.2746C112.236 11.7691 112.104 12.3461 112.104 13.0054C112.104 13.6647 112.236 14.2417 112.517 14.7527C112.797 15.2472 113.159 15.6263 113.604 15.8735C114.05 16.1373 114.528 16.2527 115.022 16.2527C115.533 16.2527 116.011 16.1208 116.473 15.857C116.918 15.5933 117.28 15.2142 117.561 14.7197C117.841 14.2252 117.973 13.6318 117.973 12.9724C117.973 12.3131 117.841 11.7362 117.561 11.2417C117.28 10.7636 116.918 10.3845 116.473 10.1373C116.028 9.89001 115.55 9.75815 115.022 9.75815C114.528 9.77463 114.05 9.9065 113.604 10.1538ZM117.775 8.67023C118.467 9.08232 119.011 9.65925 119.407 10.4175C119.802 11.1592 120 12.0164 120 12.9889C120 13.9614 119.802 14.8186 119.407 15.5933C119.011 16.3516 118.467 16.945 117.775 17.3735C117.083 17.8021 116.308 18.0164 115.484 18.0164C114.725 18.0164 114.066 17.8351 113.489 17.4889C112.912 17.1263 112.467 16.6647 112.121 16.0713V17.868H110.126V4.89551H112.121V9.9065C112.451 9.34606 112.896 8.901 113.473 8.55485C114.05 8.2087 114.709 8.04386 115.467 8.04386C116.308 8.04386 117.083 8.24166 117.775 8.67023Z" fill="#092C4C"/>
            </svg>
          </div>

          <el-form v-if="!showVerify"
                   ref="form"
                   class="acceson-form profile-form"
                   :model="formData"
                   :rules="rules"
                   label-position="top"
                   label-width="120px">

            <el-form-item label="Email" prop="email">
              <el-input v-model="formData.email" placeholder="example@gmail.com"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input type="password" v-model="formData.password" show-password
                        placeholder="********"></el-input>
            </el-form-item>

            <div class="d-grid">
              <el-button
                  class="btn acceson-btn mt-2"
                  @click.prevent="handleSubmit('form')"
                  :disabled="disabled"
                  :loading="loading"
              >Login
              </el-button>
            </div>
          </el-form>

          <el-form v-else
                   ref="verifyForm"
                   class="acceson-form profile-form"
                   :model="verifyFormData"
                   :rules="verifyRules"
                   label-position="top"
                   label-width="120px">

            <el-form-item label="OTP" prop="otp">
              <el-input type="number" v-model="verifyFormData.otp"
                        placeholder="Enter your OTP"></el-input>
            </el-form-item>

            <div class="d-grid">
              <el-button
                  class="btn btn-primary acceson-btn mt-2"
                  @click.prevent="handleSubmit('verifyForm')"
                  :disabled="disabled"
                  :loading="loading"
              >Verify OTP
              </el-button>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <span @click.prevent="login" class="cursor-pointer">
                  <i class="el-icon-refresh"></i>
                  Resend
                </span>
            </div>
          </el-form>

          <a class="forgot-password"
             @click.p.prevent="$router.push('/forgot-password')">Forgot
            Password?</a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Notification} from 'element-ui';
import Auth from "@/apis/Auth";

export default {
  name: "Login",
  components: {Notification},
  data() {
    return {
      formData: {
        email: 'superadmin@example.com',
        password: 'password',
      },
      rules: {
        email: [
          {required: true, message: 'This field is required', trigger: 'blur'},
          {type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: 'This field is required', trigger: 'blur'},
          {min: 8, message: 'Password must be at least 8 characters', trigger: 'blur'}
        ]
      },
      showVerify: false,
      uid: '',
      verifyFormData: {
        otp: '',
      },
      verifyRules: {
        otp: [
          {required: true, message: 'The OTP is required', trigger: 'blur'},
          {min: 4, message: 'OPT must be at least 4 characters', trigger: 'blur'}
        ]
      },
      disabled: false,
      loading: false,

    }
  },
  methods: {
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (formName === 'verifyForm') {
            this.verifyOTP();
          } else {
            this.login();
          }

        } else {
          Notification.error({
            title: 'Error',
            message: 'Please check your input',
            duration: 3000
          });
          return false;
        }
      });
    },

    login() {
      this.disabled = true;
      Auth.login(this.formData)
          .then(res => {
            if (res.data.uid){
              this.showVerify = true;
              this.uid = res.data.uid;
              Notification.success('Verification code sent to your email. Please check your email.');
            }else {
              this.handleStoreCall(res);
            }

            this.disabled = false;
            this.loading = false;
          })
          .catch(error => {
            this.disabled = false;
            this.loading = false;
            if (error.response.status === 401){
              Notification.error({
                title: 'Error',
                message: 'Your Account is not Active!',
              });
            }else {
              Notification.error({
                title: 'Error',
                message: 'Incorrect email or password!',
              });
            }

          })
          .finally(() => {
            this.disabled = false;
            this.loading = false;
          });

    },

    verifyOTP(){
      this.disabled = true;
      Auth.checkOtp({
        email: this.formData.email,
        password: this.formData.password,
        uid: this.uid,
        otp: this.verifyFormData.otp,
      }).then(res => {
        this.handleStoreCall(res);
        this.disabled = false;
        this.loading = false;
      }).catch(error => {
        this.disabled = false;
        this.loading = false;
        Notification.error(error.response.data.message);
      }).finally(() => {
        this.disabled = false;
        this.loading = false;
      });
    },

    handleStoreCall(response) {
      let expires = new Date().getTime() + (response.data.expires_in * 1000);

      this.$store.dispatch('auth/setUser', response.data.user);
      this.$store.dispatch('auth/getPermissions',response.data.permissions)
      this.$store.dispatch('auth/setToken', response.data.token);
      this.$store.dispatch('auth/setTokenExpiration',  expires);
      window.location.href = '/';
    }
  }
}
</script>

<style scoped>
body{
  background: #F3F3F3;
}

.login-container{
  background: #FFFFFF;
  border-radius: 20px;
  width: 947px;
  height: 665px;
  margin: auto auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.left-section{
  border-right: 1px solid #F3F3F3;
  height: 665px;
  padding: 98px 0;
}

.right-section{
  height: 665px;
  padding: 150px 30px;
}

.form-section{
  display: flex;
  flex-direction: column;
}

.forgot-password{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  margin-top: 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
}

.forgot-password:hover{
  color: #4F31FF;
}

@media (max-width: 767px) {
  .login-container{
    width: 100%;
    height: auto;
  }

  .left-section{
    display: none;
  }
}



</style>