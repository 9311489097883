import Api from "./Api";

export default {   
    list() {
        return Api.get(`/v1/fund/list`);
    },

    listWithUid() {
        return Api.get(`/v1/fund/list?uid=yes`);
    },

    userFundAccessRights(user_id,account_id,fund_id){
        return Api.get(`/v1/fund/user-access/${user_id}/${account_id}/${fund_id}`);
    },

    index(page,name,status,vintage,vehicle_type) {
        return Api.get(`/v1/funds?page=${page}&name=${name}&status=${status}&vintage=${vintage}&vehicle_type=${vehicle_type}`);
    },

    store(formData) {
        return Api.post('/v1/funds', formData);
    },

    show(id) {
        return Api.get(`/v1/funds/${id}`);
    },

    update(id, formData) {
        return Api.patch(`/v1/funds/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/funds/${id}`);
    },

    updateStatus(id,status) {
        return Api.patch(`/v1/update-fund-status/${id}`,{'status' : status});
    },

    details(id) {
        return Api.get(`/v1/fund/details/${id}`);
    },
}