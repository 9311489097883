export const SET_USER = (state, user) => {
  state.user = user
}

export const SET_PERMISSIONS = (state, permissions) => {
  state.permissions = permissions
}

export const SET_TOKEN = (state, token) => {
  state.token = token
}

export const SET_TOKEN_EXPIRATION = (state, expiration) => {
  state.tokenExpiration = expiration
}

export const LOGOUT = (state) => {
  state.user = null
  state.token = null
  state.tokenExpiration = null
}