<template>
  <div class="container-fluid p-0 mt-n2 communication-section">

    <div class="d-flex justify-content-between mb-3">
      <h1 class="list-title">Email Preview</h1>
      <div>

        <el-button
            @click.prevent="backToEmail"
            class="back-button"
        ><i class="fa fa-arrow-alt-circle-left"></i> Back
        </el-button>
      </div>
    </div>
    <p style="border-bottom: 1px solid #E6E8EB;"></p>

    <div class="row">
      <div class="col-12">
        <div class="card rounded-3 shadow-none">
          <div class="card-body">
            <el-form v-loading="loading">
              <div v-html="email.body"></div>
            </el-form>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Emails from "@/apis/Emails";

export default {
  name: "PreviewEmail",
  data(){
    return {
      email: {},
      loading: false,
    }
  },
  mounted() {
    this.getEmail();
  },
  methods: {
    backToEmail(){
      this.$router.push({name: 'communication'})
    },
    getEmail(){
      this.loading = true;
      Emails.edit(this.$route.query.id).then((res)=>{
        this.email = res.data.email;
        this.loading = false;
      }).catch((error)=>{
        this.loading = false;
        this.$notify.error('Email data not Found!');
      })
    }
  },
}
</script>

<style scoped>

</style>