import Api from "./Api";

export default {

    index(page,fund_id,category_id,date,investor_id) {
        return Api.get(`/v1/fund-documents?page=${page}&fund_id=${fund_id}&category_id=${category_id}&date=${date}&investor_id=${investor_id}`,);
    },

    store(formData) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return Api.post('/v1/fund-documents', formData,config);
    },

    show(id) {
        return Api.get(`/v1/fund-documents/${id}`);
    },

    view(id) {
        return Api.get(`/v1/view-file/${id}`);
    },


}