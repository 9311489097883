<template>
    <nav class="navbar navbar-expand navbar-light navbar-bg">
        <a @click.prevent="openSmallDeviceSidebar"
          class="hamburger-menu">
            <i class="hamburger align-self-center"></i>
        </a>

        <div class="welcome-section">
            <div>
              <span class="user-name">
                Hello, {{ user.first_name + ' ' + user.last_name }}
              </span>
              <span class="ms-2">
<!--                <img src="./../../assets/images/clap.svg" style="margin-top:-->
<!--                -5px;" alt="">-->
              </span>
            </div>
            <p class="welcome-text">Welcome to {{title}}</p>
        </div>

        <div class="navbar-collapse collapse custom-popper-container">
            <ul class="navbar-nav navbar-align">
                <li class="nav-item">
                    <a class="nav-icon" href="#">
                        <div class="position-relative">
                          <el-popover
                              popper-class="search-popover"
                              placement="bottom"
                              width="327"
                              v-model="visible">

                            <div slot="reference" class="nav-search-box">
                              <el-input
                                  @click.native.prevent="searchData"
                                  @input="searchData"
                                  v-model="search"
                                  class="search-text"
                                  type="text"
                                  placeholder="Search"/>
                              <button type="button" @click.prevent="searchData"
                                      class="search-button">
                                <svg width="11" height="11"
                                     viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.14285 11.0813C8.98317 11.0813 11.2857 8.82452 11.2857 6.04065C11.2857 3.25677 8.98317 1 6.14285 1C3.30253 1 1 3.25677 1 6.04065C1 8.82452 3.30253 11.0813 6.14285 11.0813Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M13 12.7615L10.4286 10.2412" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </button>
                            </div>


                            <div v-if="loading" class="loading-box">
                              <i class="el-icon-loading"></i>
                            </div>

                            <div v-else>

                              <div class="search-dropdown-header">
                                <div class="search-header-title">
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3085 7.40002H14.6835" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.31653 7.40002L5.94153 8.02502L7.81653 6.15002" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.3085 13.2333H14.6835" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.31653 13.2333L5.94153 13.8583L7.81653 11.9833" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5001V7.50008C18.3332 3.33341 16.6665 1.66675 12.4998 1.66675H7.49984C3.33317 1.66675 1.6665 3.33341 1.6665 7.50008V12.5001C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                    <span>Search Results</span>
                                </div>
                              </div>

                              <div class="output-box"
                                   v-if="funds.length > 0
                              || contacts.length > 0
                              || investors.length > 0">
                                <div v-if="funds.length > 0">
                                  <div v-for="(fund,index) in funds"
                                       :key="fund.id">
                                    <div class="clearfix">
                                      <div @click.prevent="goToFundViewPage(fund.id)"
                                           class="search-link float-start">
                                        {{ fund.name }}
                                      </div>
                                      <svg @click.prevent="goToFundViewPage(fund.id)"
                                          class="float-end cursor-pointer mt-1"
                                           width="16"
                                           height="16"
                                           viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z" stroke="#505968" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.16003 10.3533L9.5067 7.99994L7.16003 5.64661" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>

                                    <p v-if="index+1 < funds.length"
                                       style="border-bottom: 1px solid #F3F3F3; margin-top: 15px;"></p>
                                  </div>

                                </div>
                                <div v-if="investors.length > 0">
                                  <div v-if="funds.length > 0"
                                       class="title-header">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.95964 14.0503V15.742C8.95964 17.1753 7.6263 18.3336 5.98463 18.3336C4.34296 18.3336 3.00128 17.1753 3.00128 15.742V14.0503C3.00128 15.4836 4.33463 16.5003 5.98463 16.5003C7.6263 16.5003 8.95964 15.4753 8.95964 14.0503Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M8.95834 11.7584C8.95834 12.175 8.84167 12.5583 8.64167 12.8917C8.15001 13.7 7.14166 14.2084 5.975 14.2084C4.80833 14.2084 3.79999 13.6917 3.30832 12.8917C3.10832 12.5583 2.9917 12.175 2.9917 11.7584C2.9917 11.0417 3.32501 10.4 3.85835 9.93336C4.40001 9.45836 5.14165 9.17505 5.96665 9.17505C6.79165 9.17505 7.53335 9.46669 8.07501 9.93336C8.62501 10.3917 8.95834 11.0417 8.95834 11.7584Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M8.95964 11.7583V14.0499C8.95964 15.4833 7.6263 16.4999 5.98463 16.4999C4.34296 16.4999 3.00128 15.4749 3.00128 14.0499V11.7583C3.00128 10.3249 4.33463 9.16663 5.98463 9.16663C6.80963 9.16663 7.55132 9.45827 8.09299 9.92494C8.62632 10.3916 8.95964 11.0416 8.95964 11.7583Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M18.3333 9.14159V10.8583C18.3333 11.3166 17.9667 11.6916 17.5 11.7083H15.8667C14.9667 11.7083 14.1417 11.0499 14.0667 10.1499C14.0167 9.62494 14.2167 9.13327 14.5667 8.79161C14.875 8.47494 15.3 8.29163 15.7667 8.29163H17.5C17.9667 8.30829 18.3333 8.68326 18.3333 9.14159Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M1.66669 8.74996V7.08329C1.66669 4.81663 3.03336 3.23329 5.15836 2.96662C5.37502 2.93329 5.60002 2.91663 5.83335 2.91663H13.3334C13.55 2.91663 13.7584 2.92495 13.9584 2.95828C16.1084 3.20828 17.5 4.79996 17.5 7.08329V8.29164H15.7667C15.3 8.29164 14.875 8.47495 14.5667 8.79162C14.2167 9.13328 14.0167 9.62495 14.0667 10.15C14.1417 11.05 14.9667 11.7083 15.8667 11.7083H17.5V12.9166C17.5 15.4166 15.8334 17.0833 13.3334 17.0833H11.25" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Investors ({{ investors.length }})</span>
                                  </div>
                                  <div v-for="(investor,index) in investors"
                                       :key="investor.id">
                                    <div class="clearfix">
                                      <div @click.prevent="goToInvestorViewPage(investor.id)"
                                           class="search-link float-start">
                                        {{ investor.title }}
                                      </div>
                                      <svg @click.prevent="goToInvestorViewPage(investor.id)"
                                           class="float-end cursor-pointer mt-1"
                                           width="16"
                                           height="16"
                                           viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z" stroke="#505968" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.16003 10.3533L9.5067 7.99994L7.16003 5.64661" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>

                                    <p v-if="index+1 < investors.length"
                                       style="border-bottom: 1px solid #F3F3F3; margin-top: 15px;"></p>
                                  </div>

                                </div>
                                <div v-if="contacts.length > 0">
                                  <div v-if="funds.length > 0 || investors.length > 0"
                                       class="title-header">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.1333 9.05817C10.05 9.04984 9.94999 9.04984 9.85832 9.05817C7.87499 8.9915 6.29999 7.3665 6.29999 5.3665C6.29999 3.32484 7.94999 1.6665 9.99999 1.6665C12.0417 1.6665 13.7 3.32484 13.7 5.3665C13.6917 7.3665 12.1167 8.9915 10.1333 9.05817Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M5.9666 12.1335C3.94993 13.4835 3.94993 15.6835 5.9666 17.0252C8.25827 18.5585 12.0166 18.5585 14.3083 17.0252C16.3249 15.6752 16.3249 13.4752 14.3083 12.1335C12.0249 10.6085 8.2666 10.6085 5.9666 12.1335Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Contacts ({{ contacts.length }})</span>
                                  </div>
                                  <div v-for="(contact,index) in contacts"
                                       :key="contact.id">
                                    <div class="clearfix">
                                      <div @click.prevent="goToContactViewPage(contact.id)"
                                           class="search-link float-start">
                                        {{ contact.name }}
                                      </div>
                                      <svg @click.prevent="goToContactViewPage(contact.id)"
                                           class="float-end cursor-pointer mt-1"
                                           width="16"
                                           height="16"
                                           viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z" stroke="#505968" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.16003 10.3533L9.5067 7.99994L7.16003 5.64661" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </div>

                                    <p v-if="index+1 < contacts.length"
                                       style="border-bottom: 1px solid #F3F3F3; margin-top: 15px;"></p>
                                  </div>

                                </div>

                              </div>

                              <div v-else>
                                <h5
                                    class="text-center text-muted text-uppercase mt-2">No
                                  Results Found!</h5>
                              </div>
                            </div>

                          </el-popover>

                        </div>
                    </a>
                </li>

                <li class="nav-item"
                    v-if="checkPermission('setting_all','setting_index')">
                  <router-link to="/setting" class="nav-icon">
                    <div class="position-relative">
                      <div class="circle-button">
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2376 6.56191L18.5899 5.4381C18.0421 4.48718 16.8279 4.15914 15.8757 4.70476V4.70476C15.4224 4.97178 14.8815 5.04753 14.3723 4.91532C13.8632 4.78311 13.4275 4.4538 13.1614 4C12.9902 3.71156 12.8982 3.38302 12.8947 3.04762V3.04762C12.9101 2.50988 12.7073 1.98879 12.3323 1.60305C11.9573 1.21731 11.4422 0.999778 10.9042 1H9.59947C9.07243 0.999994 8.56712 1.21001 8.19534 1.58357C7.82357 1.95713 7.61598 2.46345 7.61851 2.99048V2.99048C7.60289 4.07861 6.71629 4.95249 5.62804 4.95238C5.29264 4.9489 4.9641 4.85691 4.67566 4.68571V4.68571C3.72344 4.14009 2.50926 4.46813 1.96137 5.41905L1.26614 6.56191C0.718911 7.51163 1.04249 8.72505 1.98994 9.27619V9.27619C2.60581 9.63175 2.98519 10.2889 2.98519 11C2.98519 11.7111 2.60581 12.3682 1.98994 12.7238V12.7238C1.04369 13.2712 0.719761 14.4817 1.26614 15.4286V15.4286L1.92328 16.5619C2.17999 17.0251 2.6107 17.3669 3.1201 17.5117C3.62951 17.6565 4.17561 17.5923 4.63756 17.3333V17.3333C5.09169 17.0683 5.63285 16.9957 6.14076 17.1317C6.64867 17.2676 7.08124 17.6008 7.34232 18.0571C7.51352 18.3456 7.60551 18.6741 7.60899 19.0095V19.0095C7.60899 20.1088 8.50016 21 9.59947 21H10.9042C11.9998 21 12.8895 20.1146 12.8947 19.019V19.019C12.8922 18.4904 13.101 17.9826 13.4749 17.6088C13.8487 17.2349 14.3565 17.026 14.8852 17.0286C15.2198 17.0375 15.547 17.1291 15.8376 17.2952V17.2952C16.7873 17.8425 18.0007 17.5189 18.5518 16.5714V16.5714L19.2376 15.4286C19.503 14.973 19.5758 14.4304 19.44 13.9209C19.3041 13.4115 18.9708 12.9772 18.5138 12.7143V12.7143C18.0567 12.4514 17.7234 12.0171 17.5875 11.5076C17.4517 10.9982 17.5245 10.4556 17.7899 10C17.9625 9.69865 18.2124 9.44879 18.5138 9.27619V9.27619C19.4555 8.72535 19.7783 7.51902 19.2376 6.57143V6.57143V6.56191Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <circle cx="10.2568" cy="10.9999" r="2.74286" stroke="#505968" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </router-link>

                </li>

                <li class="nav-item">

                  <el-popover
                      placement="bottom"
                      width="325"
                      trigger="click"
                      popper-class="notification-popover"
                      v-model="show"
                  >

                    <a slot="reference" class="nav-icon">
                      <div class="position-relative circle-button">
                        <div class="circle-button">
                          <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00079 12.1782V11.9697C1.03138 11.3526 1.22914 10.7548 1.57367 10.2377C2.14714 9.61664 2.53972 8.85554 2.7102 8.03428C2.7102 7.39954 2.7102 6.75573 2.76564 6.12099C3.05209 3.06517 6.0736 0.952393 9.05815 0.952393H9.13207C12.1166 0.952393 15.1381 3.06517 15.4338 6.12099C15.4893 6.75573 15.4338 7.39954 15.48 8.03428C15.6528 8.85744 16.045 9.62091 16.6165 10.2468C16.9637 10.7593 17.1617 11.3549 17.1894 11.9697V12.1692C17.2101 12.9981 16.9245 13.8065 16.3855 14.4451C15.6733 15.1919 14.7068 15.6565 13.669 15.7509C10.6257 16.0773 7.55527 16.0773 4.51202 15.7509C3.47537 15.6524 2.51025 15.1885 1.79543 14.4451C1.26476 13.806 0.983086 13.0025 1.00079 12.1782Z"
                                  stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.7666 18.9065C7.2421 19.5033 7.94037 19.8896 8.70688 19.9798C9.47339 20.07 10.2449 19.8567 10.8507 19.3871C11.037 19.2482 11.2047 19.0867 11.3497 18.9065" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <circle cx="13.8571" cy="3.33333" r="2.58333"
                                    :fill="notifications.length > 0 ? 'red' : 'white'"
                                    :stroke="notifications.length > 0 ? 'red' : '#505968'"
                                    stroke-width="1.5"/>
                          </svg>
                        </div>
                      </div>
                    </a>

                    <div>

                      <div class="custom-dropdown-header">
                        <div class="notification-header-title">
                          <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00079 12.2258V12.0173C1.03138 11.4002 1.22914 10.8024 1.57367 10.2853C2.14714 9.66425 2.53972 8.90315 2.7102 8.08188C2.7102 7.44714 2.7102 6.80334 2.76564 6.1686C3.05209 3.11278 6.0736 1 9.05815 1H9.13207C12.1166 1 15.1381 3.11278 15.4338 6.1686C15.4893 6.80334 15.4338 7.44714 15.48 8.08188C15.6528 8.90505 16.045 9.66852 16.6165 10.2944C16.9637 10.8069 17.1617 11.4025 17.1894 12.0173V12.2168C17.2101 13.0458 16.9245 13.8541 16.3855 14.4928C15.6733 15.2395 14.7068 15.7041 13.669 15.7985C10.6257 16.125 7.55527 16.125 4.51202 15.7985C3.47537 15.7 2.51025 15.2361 1.79543 14.4928C1.26476 13.8536 0.983086 13.0501 1.00079 12.2258Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.7666 18.9541C7.2421 19.5509 7.94037 19.9372 8.70688 20.0274C9.47339 20.1176 10.2449 19.9043 10.8507 19.4347C11.037 19.2958 11.2047 19.1343 11.3497 18.9541" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <el-tooltip class="item" effect="dark"
                                      content="See All Notification"
                                      placement="top">
                            <span
                                @click.prevent="goToNotificationPage">Notifications
                          ({{ notifications.length }})</span>
                          </el-tooltip>

                        </div>
                      </div>

                      <div v-if="notifications.length > 0">
                        <div class="row"
                             v-for="(notification,index) in notifications"
                             :key="notification.id">
                          <div class="col-1">

                            <svg v-if="notification.type === 'fund'" style="margin-left: 5px;" width="36" height="36" viewBox="0 0 36 36"
                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle opacity="0.1" cx="18" cy="18" r="18" fill="#27AE60"/>
                              <path d="M21.5271 16.4702L18.8654 18.6132C18.3617 19.0081 17.6556 19.0081 17.1519 18.6132L14.4674 16.4702" stroke="#27AE60" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0501 13H20.9331C21.7814 13.0095 22.5887 13.3681 23.1672 13.9923C23.7456 14.6165 24.0446 15.4517 23.9946 16.3036V20.377C24.0446 21.2288 23.7456 22.0641 23.1672 22.6882C22.5887 23.3124 21.7814 23.671 20.9331 23.6805H15.0501C13.228 23.6805 12 22.1982 12 20.377V16.3036C12 14.4823 13.228 13 15.0501 13Z" stroke="#27AE60" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>


                            <svg v-if="notification.type === 'contact' ||
                            notification.type === 'investor'"
                                 style="margin-left: 5px;" width="36" height="36" viewBox="0 0 36 36"
                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle opacity="0.1" cx="18" cy="18" r="18" fill="#2F80ED"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.623 20.0801C15.1296 20.0801 13 20.457 13 21.967C13 23.4765 15.1159 23.8676 17.623 23.8676C20.1165 23.8676 22.246 23.4901 22.246 21.9807C22.246 20.4712 20.1301 20.0801 17.623 20.0801Z" stroke="#2F80ED" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.623 17.9262C19.268 17.9262 20.5861 16.6075 20.5861 14.9631C20.5861 13.3181 19.268 12 17.623 12C15.9786 12 14.6599 13.3181 14.6599 14.9631C14.6599 16.6075 15.9786 17.9262 17.623 17.9262Z" stroke="#2F80ED" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M23.6729 15.8396V18.4407" stroke="#2F80ED" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M25 17.1402H22.3469" stroke="#2F80ED" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <svg v-if="notification.type === 'document'"
                                 style="margin-left: 5px;" width="36" height="36" viewBox="0 0 36 36"
                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle opacity="0.1" cx="18" cy="18" r="18" fill="#EB5757"/>
                              <path d="M17.9931 20.4623V17.5959" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M17.9931 15.5377H17.9996" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8112 12H15.1881C13.2285 12 12 13.3875 12 15.3509V20.6491C12 22.6125 13.2227 24 15.1881 24H20.8106C22.7766 24 24 22.6125 24 20.6491V15.3509C24 13.3875 22.7766 12 20.8112 12Z" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                          </div>
                          <div class="col-11">
                            <div @click.prevent="goToNotificationPage"
                                 class="d-inline-block cursor-pointer"
                                 style="padding-left: 30px;">
                              <span class="notification-title">{{
                                  notification.title }}</span>
                              <br>
                              <span class="notification-time">{{
                                  notification.date }}</span>
                            </div>
                          </div>
                          <p v-if="index+1 < notifications.length"
                             style="border-bottom: 1px solid #F3F3F3; margin-top: 15px;">
                          </p>
                        </div>
                      </div>

                      <div v-else class="text-center mt-3 text-muted">
                        No Unread Notification Found!
                      </div>


                    </div>


                  </el-popover>

                </li>

                <li class="nav-item ">

                    <router-link to="/profile"
                       class="nav-link d-sm-inline-block"
                       >
                        <img :src="user.profile_picture != null
                        ?`${baseUrl}`+user.profile_picture:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"
                             class="avatar me-1 "
                             alt=""/>
                    </router-link>
                </li>

                <li class="nav-item">
                  <div class="position-relative">
                    <a class="circle-button" @click.prevent="logout">
                      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0041 5.09367V4.40755C10.0041 2.91103 8.79073 1.69763 7.29421 1.69763H3.70918C2.21339 1.69763 1 2.91103 1 4.40755V12.5924C1 14.089 2.21339 15.3024 3.70918 15.3024H7.30156C8.79367 15.3024 10.0041 14.0926 10.0041 12.6005V11.9071" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 8.49993H6.1452" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.8466 6.35617L14.9998 8.49983L12.8466 10.6442" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                </li>
            </ul>
        </div>


      <div class="small-device-sidebar-box">
        <small-device-sidebar
            :open="openSmallDeviceNavbar"
            @close="handleClose"
        />
      </div>

      <delete-modal
          :open="openDeleteModal"
          :disabled="btnDisabled"
          @close="openDeleteModal = false"
          @confirm="deleteNotification"
      />

    </nav>
</template>

<script>
import {mapGetters} from 'vuex';
import Auth from "@/apis/Auth";
import {Notification} from "element-ui";
import SmallDeviceSidebar from "@/components/layouts/SmallDeviceSidebar";
import _ from "lodash";
import Search from "@/apis/Search";
import Notifications from "@/apis/Notifications";
import DeleteModal from "@/components/common/DeleteModal.vue";

export default {
    name: "Navbar",
    data() {
        return {
          search: '',
          title: '',
          rules: {},
          baseUrl: `${process.env.VUE_APP_BASE_URL}`,
          permissions: [],
          openSmallDeviceNavbar: false,
          visible: false,
          show: false,
          funds: [],
          contacts: [],
          investors: [],
          notifications: [],
          notificationId: '',
          openDeleteModal: false,
          btnDisabled: false,
          loading: false,
        }
    },
    components: {SmallDeviceSidebar,DeleteModal},
    computed: {
        ...mapGetters('auth', ['user'])
    },

    created() {
      this.getPermissions();
    },

    mounted() {
      this.checkPermission();
      this.getNotifications();

      let routeName = this.$route.name;

      if (routeName === 'dashboard'){
        this.title = 'your Dashboard'
      }else if (routeName === 'profile'){
        this.title = 'Profile Page'
      }else if (routeName === 'document'){
        this.title = 'Document Page'
      }else if (routeName === 'funds'){
        this.title = 'Fund Page'
      }else if (routeName === 'investor'){
        this.title = 'Investor Page'
      }else if (routeName === 'contact'){
        this.title = 'Contact Page'
      }else if (routeName === 'communication'){
        this.title = 'Communication Page'
      }else if (routeName === 'report'){
        this.title = 'Reports Page'
      }else if (routeName === 'role'){
        this.title = 'Administration Page'
      }else if (routeName === 'setting'){
        this.title = 'Settings Page'
      }
      else if (routeName === 'notification'){
        this.title = 'Notification Page'
      }

    },

    methods: {
        async getPermissions(){
          await Auth.userPermissions().then((res)=>{
            this.permissions = res.data.permissions;
          }).catch(()=>{
            Notification.error("Something went wrong!");
          })
        },
        checkPermission(allPermission,specificPermission){
          let userPermissions = this.permissions
          for (let key in userPermissions) {
            if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
              return true;
            }
          }
          return false;

        },
        getNotifications() {
          Notifications.unreadNotification().then((res)=>{
            this.notifications = res.data.data;
          }).catch(()=>{
            Notification.error('Something went wrong!');
          })
        },
        markAsReadSingle(id){
          Notifications.markAsReadSingle(id).then(()=>{
            Notification.success('Notification marked as read successfully.');
            this.getNotifications();
          }).catch(()=>{
            Notification.error('Something went wrong!');
          })
        },
        destroy(id){
          this.openDeleteModal = true;
          this.notificationId = id;
        },
        deleteNotification(){
          Notifications.destroy(this.notificationId).then(()=>{
            Notification.success('Notification deleted successfully.');
            this.openDeleteModal = false;
            this.getNotifications();
          }).catch(()=>{
            Notification.error('Something went wrong!');
          })
        },
        logout() {
            Auth.logout()
                .then(() => {
                    Notification.success('Logout success');
                })
                .catch(() => {
                    Notification.error(`Logout failed!`);
                })
                .finally(() => {
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                });
        },
        openSmallDeviceSidebar(){
          this.openSmallDeviceNavbar = true
        },
        handleClose(){
            this.openSmallDeviceNavbar = false
        },
        searchData:_.debounce(function (){
          this.visible = true;
          this.loading = true;

          Search.index(this.search).then((res)=>{
            this.funds = res.data.funds;
            this.contacts = res.data.contacts;
            this.investors = res.data.investors;
            this.loading = false;

          }).catch((error)=>{

            if (error.response) {
              this.formErrors(error.response.data, this.$notify.error);
            } else {
              this.errorMessage(error);
            }
            this.loading = false;
            this.visible = false;
          });
        },500),
        goToFundViewPage(id){
          this.visible = false;
          this.search = '';
          this.$router.push(`/funds/${id}`)
        },
        goToContactViewPage(id){
          this.visible = false;
          this.search = '';
          this.$router.push(`/contact/${id}`)
        },
        goToInvestorViewPage(id){
          this.visible = false;
          this.search = '';
          this.$router.push(`/investor/${id}`)
        },
        goToNotificationPage(){
            this.show = false;
            if (this.$route.name !== 'notification'){
              this.$router.push('/notification')
            }
        }

    },
    watch: {
      $route() {
        let routeName = this.$route.name;

        if (routeName === 'dashboard'){
          this.title = 'your Dashboard'
        }else if (routeName === 'profile'){
          this.title = 'Profile Page'
        }else if (routeName === 'document'){
          this.title = 'Document Page'
        }else if (routeName === 'funds'){
          this.title = 'Fund Page'
        }else if (routeName === 'investor'){
          this.title = 'Investor Page'
        }else if (routeName === 'contact'){
          this.title = 'Contact Page'
        }else if (routeName === 'communication'){
          this.title = 'Communication Page'
        }else if (routeName === 'report'){
          this.title = 'Reports Page'
        }else if (routeName === 'role'){
          this.title = 'Administration Page'
        }else if (routeName === 'setting'){
          this.title = 'Settings Page'
        }
        else if (routeName === 'notification'){
          this.title = 'Notification Page'
        }

      }
    }

}
</script>

<style scoped>
/* Designing Dropdown box*/

.custom-dropdown-header{
  width: 323px;
  height: 50px;
  background: #FAFAFA;
  border-radius: 14px 14px 0 0;
  position: relative;
  margin-bottom: 5px;
  left: -12px;
  top: -12px;
}
.search-dropdown-header{
  width: 423px;
  height: 50px;
  background: #FAFAFA;
  border-radius: 13px 13px 0 0;
  position: relative;
  margin-bottom: 5px;
  left: -12px;
  top: -12px;
}

.title-header{
  width: 433px;
  height: 50px;
  background: #FAFAFA;
  margin: 10px 0;
  display: flex;
  align-items: center;
  position: relative;
  left: -12px;
  padding: 0 15px;
}

.title-header span{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #092C4C;
  margin-left: 10px;
}

.search-dropdown-header span{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #092C4C;
  margin-left: 10px;
}

.search-header-title{
  padding-top: 15px;
  padding-left: 20px;
}

.notification-header-title{
  padding-top: 15px;
  padding-left: 20px;
}

.notification-header-title span{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
  margin-left: 10px;
  cursor: pointer;
}

.notification-header-title span:hover{
  color: #0a58ca;
  text-decoration: underline;
}

.notification-title{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #092C4C;
  word-break: keep-all;
}

.notification-time{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737A86;
}

.search-link{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
  padding: 5px 10px;
  cursor: pointer;
}

.search-link:hover{
  text-decoration: underline;
}

.loading-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 115px;
}
.loading-box i{
  font-size: 25px;
}


.hamburger-menu{
  margin-left: 10px;
  display: none;
}


@media (max-width: 1230px) {
  .welcome-section{
    display: none;
  }
}

@media (max-width: 991.5px) {
  .hamburger-menu{
    display: block;
  }
}

@media (min-width: 767.1px) {
  .nav-search-box{
    display: block;
  }
}

@media (max-width: 767px) {
  .nav-search-box{
    display: none;
  }
}








</style>