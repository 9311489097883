import Api from "./Api";

export default {
    allNotification(page) {
        return Api.get(`/v1/all-notifications?page=${page}`);
    },

    unreadNotification() {
        return Api.get(`/v1/unread-notifications`);
    },

    markAsReadAll() {
        return Api.post(`/v1/mark-as-read`);
    },

    markAsReadSingle(id) {
        return Api.post(`/v1/mark-as-read/${id}`);
    },

    destroy(id) {
        return Api.delete(`/v1/delete-notification/${id}`);
    },


}