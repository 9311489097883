<template>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-3">
        <div class="card-body">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            header-row-class-name="table-header-row"
            row-class-name="table-row"
            @expand-change="expandSelect"
            :row-key="getRowKeys"
            :expand-row-keys="expands"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="Document Batch Title"
              property="title"
              min-width="180"
            >
            </el-table-column>
            <el-table-column min-width="140" label="Category Name" >
              <template slot-scope="scope">
                {{ scope.row.category.name }}
              </template>
            </el-table-column>
            <el-table-column min-width="100" label="Upload ID"
                             property="batch_id">
            </el-table-column>
            <el-table-column min-width="150" label="Fund Name">
              <template slot-scope="scope">
                <span
                  class="fund-badge"
                  v-for="(fund, fundKey) in scope.row.funds"
                  :key="fund.id"
                >
                  {{ fund.name }}
                  <span v-if="fundKey + 1 < scope.row.funds.length">, </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column min-width="190" label="Read by No. of Investor">
              <template slot-scope="scope"> 6/9 (68.77%) </template>
            </el-table-column>
            <el-table-column
              label="Upload Date"
              property="upload_date"
              min-width="120"
            >
            </el-table-column>
            <el-table-column min-width="120" label="Upload By">
              <template slot-scope="scope">
                {{ scope.row.uploaded_by }}
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              min-width="100"
            >
              <template slot-scope="scope">
                <span
                  class="doc-status"
                  :class="scope.row.status == 'ACTIVE' ? 'active' : 'pending'"
                >
                </span>
                {{ scope.row.status }}
              </template>
            </el-table-column>

            <el-table-column min-width="20" type="expand">
              <template>
                <el-table
                  header-row-class-name="table-header-row sub-tbl-header"
                  row-class-name="table-row"
                  v-loading="childLoading"
                  :data="subTableData"
                  style="margin-left: 4%; width: 96%; background-color: #fafafa;"
                >
                  <el-table-column
                    prop="account_title"
                    label="Investor Account"
                  >
                  </el-table-column>
                  <el-table-column prop="account_uid" label="Account ID">
                  </el-table-column>

                  <el-table-column label="Fund Name">
                    <template slot-scope="props">
                        <span
                        class="fund-badge"
                        v-for="(fund, fundKey) in props.row.funds"
                        :key="fund.id"
                        >
                        {{ fund.name }}
                        <span v-if="fundKey + 1 < props.row.funds.length">, </span>
                        </span>
                    </template>
                    </el-table-column>

                  <!-- <el-table-column prop="batch_id" label="Batch ID">
                  </el-table-column> -->
                  <el-table-column label="Actions">
                    <template slot-scope="props">
                      <button
                          v-if="checkPermission('document_all','document_download')"
                        class="mini-button"
                        title="Download File"
                        @click.prevent="downloadDocument(props.row.id,props.row.title)"
                      >
                        <i
                            class="fa-solid fa-download"
                            style="color: #230B34"
                        ></i>
                      </button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>

          <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

<!--          <div-->
<!--            v-if="table.data.length > 0"-->
<!--            class="d-flex justify-content-end mt-3"-->
<!--          >-->
<!--            <el-pagination-->
<!--              background-->
<!--              layout="prev, pager, next"-->
<!--              :total="table.meta.total"-->
<!--              :current-page="table.meta.current_page"-->
<!--              @current-change="(curr) => table.currentChange(curr)"-->
<!--              @prev-click="() => table.prevClick()"-->
<!--              @next-click="() => table.nextClick()"-->
<!--            >-->
<!--            </el-pagination>-->
<!--          </div>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../../util/Table";
import Contact from "../../apis/Contact";
import Document from "../../apis/Document";
import Auth from "@/apis/Auth";

export default {
  name: "DocumentTable",
  data() {
    return {
      table: new Table({
        columns: [
          "id",
          "title",
          "batch_id",
          "status",
          "category",
          "funds",
          "upload_date",
          "uploaded_by",
        ],
      }),
      multipleSelection: [],
      permissions: [],
      tableData: [],
      page: 1,
      lastPage: 1,
      deleteContactId: 0,
      documentData: {
        data: [],
      },
      expands: [],
      getRowKeys(row) {
        return row.id;
      },
      childLoading: false,
      subTableData: [{ investor: "Sanjib" }, { investor: "Jamal" }],
      document: {},
    };
  },
  methods: {
    async getDocuments(){
      this.loading = true;
      await Document.index(this.page).then((res)=>{

        let documents = res.data.data;

        this.lastPage = res.data.meta.last_page;


        documents.forEach((document)=>{
          this.tableData.push({
            "id" : document.id,
            "title" : document.title,
            "batch_id" : document.batch_id,
            "status" : document.status,
            "category" : document.category,
            "funds" : document.funds,
            "upload_date" : document.upload_date,
            "uploaded_by" : document.uploaded_by,
          })
        })
        this.loading = false;

      }).catch((error)=>{

        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;
      });
    },
    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getDocuments();
    },
    expandSelect(row, expandedRows) {
      if (this.expands.indexOf(row.id) >= 0) {
        //Collapse current line
        this.expands.shift();
        return;
      }

      this.document = row;
      var that = this;
      Document.getFiles(row.id).then((res) => {
        this.subTableData = res.data.data;

        if (expandedRows.length) {
          that.expands = [];
          if (row) {
            that.expands.push(row.id);
          }
        } else {
          that.expands = [];
        }
      });
    },

    downloadDocument(docFileId,title) {
      Document.downloadFile(this.document.id, docFileId).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    roleMap(role) {
      switch (role) {
        case "superadmin":
          return "Super Admin";
        case "admin":
          return "General";
        case "user":
          return "Investor";
        default:
          return "N/A";
      }
    },

    statusBtnType(status) {
      if (status === "active") {
        return "success";
      } else if (status === "inactive") {
        return "warning";
      } else if (status === "removed") {
        return "danger";
      }
    },
    statusBtnClass(status) {
      if (status === "active") {
        return "status-btn-success";
      } else if (status === "inactive") {
        return "status-btn-warning";
      } else if (status === "removed") {
        return "status-btn-danger";
      }
    },
    destroy(id) {
      this.deleteContactId = id;
      this.openDeleteModal = true;
    },
    deleteContact() {
      Contact.destroy(this.deleteContactId)
        .then((response) => {
          this.openDeleteModal = false;
          this.$notify({
            title: "Success",
            message: response.data.message,
            type: "success",
          });
          this.table.refresh();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.message);
          } else {
            this.errorMessage(error);
          }
        });
    },
    refresh() {
      this.table.refresh();
    },
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch((error)=>{
        console.log(error)
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    }
  },
  created() {
    this.getPermissions();
    this.getDocuments();
    // this.table.get(Document.index);
    // this.getTags();
  },
  mounted() {
    this.checkPermission()
  }
};
</script>
<style scoped>
.doc-status {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 4px;
}
.doc-status.active {
  background-color: #27ae60;
}
.doc-status.pending {
  background-color: red;
}
</style>
<style>
.sub-tbl-header th {
    background-color: #f5f5f5 !important;
}
</style>
