<template>
  <div class="container-fluid p-0 mt-n2">

    <div class="row">
      <div class="col-12">
        <div class="row card-wrapper">
          <div class="col">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start mt-1">
                    <el-button type="danger"
                               icon="el-icon-s-order" circle></el-button>

                  </div>
                  <div class="float-start ms-3 mt-2" style="line-height: 6px;">
                    <p class="count-title">Investment</p>
                    <p class="count-number" v-if="user.user_role === 'general'">
                      ${{ userInfo.investment != null ? userInfo.investment : 0}}
                    </p>
                    <p class="count-number" v-else>
                      ${{user.committed}}
                    </p>
                  </div>
                  <div class="float-end mt-3">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start mt-1">
                    <el-button type="success"
                               icon="el-icon-s-operation" circle></el-button>

                  </div>
                  <div class="float-start ms-3 mt-2" style="line-height: 6px;">
                    <p class="count-title"># of Funds</p>

                    <p class="count-number"  v-if="user.user_role === 'general'">
                      {{ userInfo.fund_count_general }}
                    </p>
                    <p class="count-number" v-else>
                      {{ userInfo.fund_count_investor }}
                    </p>
                  </div>
                  <div class="float-end mt-3">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start mt-1">
                    <el-button type="warning"
                               icon="el-icon-remove" circle></el-button>

                  </div>
                  <div class="float-start ms-3 mt-2" style="line-height: 6px;">
                    <p class="count-title"># of Documents</p>
                    <p class="count-number">{{ userInfo.document_count }}</p>
                  </div>
                  <div class="float-end mt-3">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start mt-1">
                    <el-button type="success"
                               icon="el-icon-s-operation" circle></el-button>

                  </div>
                  <div class="float-start ms-3 mt-2" style="line-height: 6px;">
                    <p class="count-title">Need Info</p>
                    <p class="count-number">0</p>
                  </div>
                  <div class="float-end mt-3">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start mt-1">
                    <el-button type="warning"
                               icon="el-icon-remove" circle></el-button>

                  </div>
                  <div class="float-start ms-3 mt-2" style="line-height: 6px;">
                    <p class="count-title">Need Info</p>
                    <p class="count-number">0</p>
                  </div>
                  <div class="float-end mt-3">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12">
            <div class="card card-padding">
              <div class="card-body">
                <div class="clearfix">

                  <div class="float-start">
                    <el-select @change="loadLimitWiseData"
                               v-model="formData.page_size"
                               placeholder="Select">
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          >
                      </el-option>
                    </el-select>
                  </div>

                  <div class="float-end">
                    <button @click.prevent="addNewDocument" class="common-button"
                        v-if="checkPermission('document_all','document_create')" type="button">
                      <i class="fa fa-cloud-upload"></i> Upload New Document
                    </button>
                  </div>

                </div>

                <div class="row">
                  <div class="col-12">
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                        v-loading="loading"
                    >
                      <el-table-column
                          type="selection"
                          width="55">
                      </el-table-column>

                      <el-table-column
                          label="Doc Name"
                          sortable
                          min-width="250"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.title.length > 30">
                            {{ scope.row.title | shortLength(30,'...') }}
                          </div>
                          <div v-else>
                            {{ scope.row.title }}
                          </div>

                        </template>

                      </el-table-column>

                      <el-table-column
                          property="category"
                          label="Category"
                          min-width="200"
                          sortable
                      >
                      </el-table-column>

                      <el-table-column
                          label="Status"
                          min-width="130"
                          sortable
                      >
                        <template slot-scope="scope">
                          <el-tag :type="scope.row.status === 'ACTIVE'?
                          'success' : 'primary' " class="rounded-pill text-capitalize">
                            {{ scope.row.status }}
                          </el-tag>
                        </template>

                      </el-table-column>

                      <el-table-column
                          label="View Status"
                          sortable
                          min-width="150"
                      >
                        <template slot-scope="scope">
                          <el-tag :class=" scope.row.view_status === 0 ?
                            'rounded-pill text-capitalize custom-tag-danger' :
                            'rounded-pill text-capitalize custom-tag-success'
                             ">
                            {{ scope.row.view_status  != 0 ? "Viewed" : "Not Viewed"}}
                          </el-tag>
                        </template>
                      </el-table-column>

                      <el-table-column
                          label="Date"
                          sortable
                          min-width="130"
                      >
                        <template slot-scope="scope">
                          <i class="fa fa-calendar-days text-primary"></i>
                          {{ scope.row.date }}
                        </template>
                      </el-table-column>

<!--                      <el-table-column-->
<!--                          label="Actions"-->
<!--                          width="100"-->
<!--                      >-->

<!--                        <el-button-->
<!--                            type="success"-->
<!--                            circle-->
<!--                            size="small"-->
<!--                            class="circle-success-btn"-->
<!--                        >-->
<!--                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none"-->
<!--                               xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                                  d="M3.91875 10.339L9.21498 3.48995C9.50282 3.12061 9.60517 2.6936 9.50922 2.25881C9.42607 1.86355 9.183 1.48773 8.81841 1.20262L7.9293 0.496331C7.15534 -0.11924 6.19587 -0.0544431 5.64578 0.651844L5.05091 1.42358C4.97416 1.52012 4.99335 1.66268 5.08929 1.74043C5.08929 1.74043 6.59245 2.94566 6.62443 2.97158C6.72678 3.06877 6.80353 3.19836 6.82272 3.35388C6.8547 3.65842 6.64362 3.94353 6.3302 3.98241C6.18308 4.00185 6.04236 3.95649 5.94002 3.87225L4.3601 2.61519C4.28334 2.55752 4.16821 2.56983 4.10424 2.64759L0.349543 7.50736C0.106479 7.81191 0.0233251 8.20717 0.106479 8.58947L0.58621 10.6695C0.611796 10.7796 0.707742 10.8574 0.822878 10.8574L2.9337 10.8314C3.31748 10.825 3.67568 10.65 3.91875 10.339ZM6.87434 9.69136H10.3163C10.6521 9.69136 10.9252 9.96804 10.9252 10.3082C10.9252 10.6491 10.6521 10.9251 10.3163 10.9251H6.87434C6.53853 10.9251 6.26541 10.6491 6.26541 10.3082C6.26541 9.96804 6.53853 9.69136 6.87434 9.69136Z"-->
<!--                                  fill="#00B754"/>-->
<!--                          </svg>-->
<!--                        </el-button>-->

<!--                        <el-button-->
<!--                            type="danger"-->
<!--                            circle-->
<!--                            size="small"-->
<!--                            class="circle-danger-btn"-->
<!--                        >-->
<!--                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none"-->
<!--                               xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                                  d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"-->
<!--                                  fill="#EB5757"/>-->
<!--                          </svg>-->
<!--                        </el-button>-->

<!--                      </el-table-column>-->
                    </el-table>
                    <div class="d-flex justify-content-end mt-3">
                      <el-pagination
                          background
                          layout="prev, pager, next"
                          @current-change="handleCurrentChange"
                          :total="pagination.total_page"
                          :page-size="pagination.per_page">
                      </el-pagination>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>


    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Auth from "@/apis/Auth";
import Document from "@/apis/Document";
import moment from "moment";
import Dashboard from "@/apis/Dashboard";
import {Notification} from "element-ui";



export default {
  name: "UserDashboard",
  data(){
    return{
      formData: {
        page_size: 'All',
      },
      rules: {},
      welcome: '',
      baseUrl: `${process.env.VUE_APP_BASE_URL}`,
      uploadUrl: `${process.env.VUE_APP_API_ENDPOINT}/v1/update-avatar`,
      multipleSelection: [],
      fileList: [],
      pagination: {
        total_page: 1,
        per_page: 10,
      },
      options: [
      {
        value: 'All',
        label: 'All'
      }, {
        value: '5',
        label: '5',
      }, {
        value: '10',
        label: '10'
      }, {
        value: '15',
        label: '15'
      }, {
        value: '20',
        label: '20'
      },
      {
        value: '50',
        label: '50'
      },
      {
        value: '100',
        label: '100'
      }
      ],

      tableData: [],
      permissions: [],
      userInfo: [],
      loading: true,

    }
  },

  computed: {
    ...mapGetters('auth', ['user','token']),
  },

  methods: {
    getDocuments(page=1){
      Document.userDocumentList(page,this.formData.page_size).then((res)=>{
        this.loading = false;

        let documents = res.data.data;

        this.pagination.total_page = res.data.meta.total;
        this.pagination.per_page  = res.data.meta.per_page;

        this.tableData = [];

        documents.forEach((document)=>{
          this.tableData.push({
            'id'                : document.id,
            'title'             : document.title,
            'category'          : document.category.name,
            'status'            : document.status,
            'view_status'       : document.download_count,
            'date'              : moment(document.date).format("D MMM, YYYY"),
          })
        })

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },
    loadLimitWiseData(){
      Document.userDocumentList(1,this.formData.page_size).then((res)=>{
        this.loading = false;

        let documents = res.data.data;

        this.pagination.total_page = res.data.meta.total;
        this.pagination.per_page  = res.data.meta.per_page;

        this.tableData = [];

        documents.forEach((document)=>{
          this.tableData.push({
            'id'                : document.id,
            'title'             : document.title,
            'category'          : document.category.name,
            'status'            : document.status,
            'view_status'       : document.download_count,
            'date'              : moment(document.date).format("D MMM, YYYY"),
          })
        })

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });

    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(page) {
      this.getDocuments(page);
    },
    handleUploadSuccess(response){
      this.$notify({
        title: "Success!",
        message: "Profile Picture Updated Successfully.",
        type: "success",
      });

      this.$store.dispatch('auth/setUser', response.data);
    },
    welcomeUser(){
      let date = new Date();
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let current_time = hours * 60 * 60 + minutes * 60

      if (current_time >= 1 && current_time < 21600){
        this.welcome = 'Good Night'
      }else if (current_time >= 21600 && current_time < 43200){
        this.welcome = 'Good Morning'
      }else if (current_time >= 43200 && current_time < 64800){
        this.welcome = 'Good Afternoon'
      }else if (current_time >= 64800 && current_time < 90000){
        this.welcome = 'Good Evening'
      }

    },
    addNewDocument(){
      this.$router.push({name: 'document'})
    },
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.welcomeUser();
    this.checkPermission();
    this.getDocuments();

    Dashboard.getUserDashboardInfo().then((res)=>{
      this.userInfo = res.data;
    })


  }

}
</script>

<style scoped>



@media (min-width: 350px) and (max-width: 499px) {
  .updateProfileButton {
    top: 92%;
    left: 62%;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .updateProfileButton {
    top: 88%;
    left: 56%;
  }
}

@media (min-width: 701px) and (max-width: 1439px) {
  .updateProfileButton {
    top: 88%;
    left: 52%;
  }

  .dashboard-card{
    width: 252px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1920px) {
  .updateProfileButton{
    top: 88%;
    left: 56%;
  }
}

@media (min-width: 1920px) {
  .updateProfileButton {
    top: 88%;
    left: 56%;
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .dashboard-card {
    width: 252px !important;
    margin-left: 15px !important;
  }

  .card-wrapper{
    display: flex !important;
    justify-content: center !important;
  }
}


@media (max-width: 767px) {
  .dashboard-card {
    width: 300px !important;
    margin-left: 15px !important;
  }

  .card-wrapper{
    display: flex !important;
    justify-content: center !important;
  }
}
/*.row > * {*/
/*  --bs-gutter-x: 5px !important;*/
/*  padding-left: 6px !important;*/
/*  padding-right: 6px !important;*/

/*}*/




</style>