<template>
  <div class="container">
    <div class="row centerBox">
      <div class="col-md-12">
        <div class="row">
          <div class="col-3 text-right">
            <h1 style="font-size: 60px; color: #ff0000;margin-right: 50px;">403
            </h1>
          </div>
          <div class="col-9">
            <div class="ms-3">
              <h1 style="color: orange">Access Forbidden!</h1>
              <p>You don't have permission to this page</p>
              <router-link to="/" class="btn btn-primary btn-sm">Back to
                Homepage</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "403."
}
</script>

<style scoped>
.container{
  position: relative !important;
  width: 100%;
  height: 100vh;
}
.centerBox{
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;

}
</style>