import Api from "./Api";

export default {
    index() {
        // let queryParams = params.category_id?'category_id='.params.category_id.'&':''
        // return Api.get(`/v1/documents?${params.category_id?'category_id='+params.category_id+'&':''}page=${(params && params.page)?params.page:1}`);
        return Api.get(`/v1/documents`);
    },
    show(id) {
        return Api.get(`/v1/documents/${id}`);
    },
    markActive(id) {
        return Api.get(`/v1/documents/${id}/mark-active`);
    },
    store(formData) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return Api.post('/v1/documents', formData, config);
    },
    update(id,formData) {
        return Api.put(`/v1/documents/${id}`, formData);
    },

    updateDoc(id,formData) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return Api.post(`/v1/documents/${id}`, formData, config);
    },

    destroy(id) {
        return Api.delete(`/v1/documents/${id}`);
    },
    download(id) {
        return Api.get(`/v1/documents/${id}/download`, {responseType: 'blob'});
    },
    getFiles(id) {
        return Api.get(`/v1/documents/${id}/files`);
    },
    downloadFile(id, docFileId) {
        return Api.get(`/v1/documents/${id}/files/${docFileId}/download`, {responseType: 'blob'});
    },

    documentList(page,category,status,download_count) {
        return Api.get(`/v1/document/lists?page=${page}&category=${category}&status=${status}&download_count=${download_count}`);
    },
    exportToExcel(category,status,download_count) {
        return Api.get(`/v1/all-documents/export?category=${category}&status=${status}&download_count=${download_count}`,{responseType: 'blob'});
    },

    userDocumentList(params,page_size){
        return Api.get(`/v1/user/document-list?page=${params}&page_size=${page_size}`);

    },

    updateStatus(id,data) {
        return Api.post(`/v1/update-document-status/${id}`, data);
    },
}