import Api from "./Api";

export default {   
    list() {
        return Api.get(`/v1/category/list`);
    },

    index() {
        return Api.get(`/v1/categories`);
    },

    store(formData) {
        return Api.post('/v1/categories', formData);
    },

    show(id) {
        return Api.get(`/v1/categories/${id}`);
    },

    update(id, formData) {
        return Api.patch(`/v1/categories/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/categories/${id}`);
    },
}