<template>
  <div class="container-fluid p-0 mt-n2">

   <el-form v-loading="loading">
     <div class="row">
       <div class="col-12">
         <div class="clearfix">
           <h3 class="list-title float-start">Summery of {{ document.title }}</h3>
           <button  @click.prevent="$router.go(-1)"
                    class="common-button float-end">
             <i class="fa fa-arrow-left"></i> Back
           </button>
         </div>
       </div>
     </div>

     <div class="row mt-2">
       <div class="col-md-6 mt-2">
         <div class="fund-details-card">
           <div class="card-body">
             <div class="clearfix">
               <h3 class="fund-details-text float-start">Document Details</h3>
               <a href="#" class="icon-button float-end">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                 </svg>
               </a>
             </div>

             <h6 class="fund-name">Document Name</h6>
             <h4 class="fund-des">{{document.title}}</h4>

             <h6 class="fund-name">Document Category</h6>
             <h4 class="fund-des">
               {{document.category ? document.category.name: ''}}
             </h4>

             <h6 class="fund-name">Document Updated At</h6>
             <h4 class="fund-des">{{document.updated_at | date}}</h4>

             <h6 class="fund-name">Document Status</h6>
             <h4 class="fund-des">{{document.status}}</h4>


           </div>
         </div>
       </div>
       <div class="col-md-6 mt-2">
         <div class="fund-details-card">
           <div class="card-body">
             <div class="clearfix">
               <h3 class="fund-details-text float-start">Associated
                 Investors</h3>
               <a href="#" class="icon-button float-end">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                 </svg>
               </a>
             </div>

             <div class="fund-des">
               <button v-for="accnt in accounts" :key="accnt.id"
                       class="tag-button">{{ accnt.account.title }}
               </button>
             </div>


           </div>
         </div>
       </div>
     </div>

     <div class="row mt-3">
       <div class="col-md-6 mt-2">
         <div class="fund-details-card">
           <div class="card-body">
             <div class="clearfix">
               <h3 class="fund-details-text float-start">Settings</h3>
               <a href="#" class="icon-button float-end">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                 </svg>
               </a>
             </div>
             <h6 class="fund-name">Portal</h6>
             <h4 class="fund-des">This entity is visible in the portal</h4>
           </div>
         </div>
       </div>
       <div class="col-md-6 mt-2">
         <div class="fund-details-card">
           <div class="card-body">
             <div class="clearfix">
               <h3 class="fund-details-text float-start">Tags & Tasks</h3>
               <a href="#" class="icon-button float-end">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25093 11.3519L10.1085 3.77696C10.4269 3.36847 10.5401 2.8962 10.4339 2.41533C10.342 1.97817 10.0731 1.56251 9.66991 1.24719L8.68657 0.466042C7.83057 -0.214774 6.76941 -0.143109 6.16102 0.638038L5.5031 1.49157C5.41821 1.59835 5.43943 1.75601 5.54554 1.84201C5.54554 1.84201 7.20802 3.17497 7.2434 3.20364C7.35659 3.31114 7.44148 3.45447 7.4627 3.62646C7.49807 3.96329 7.26462 4.27861 6.91797 4.32161C6.75526 4.34311 6.59963 4.29295 6.48644 4.19978L4.73906 2.80948C4.65417 2.7457 4.52683 2.75932 4.45609 2.84532L0.303426 8.22018C0.034599 8.55701 -0.057368 8.99416 0.034599 9.41698L0.565178 11.7174C0.593475 11.8393 0.699591 11.9253 0.82693 11.9253L3.16148 11.8966C3.58594 11.8894 3.98211 11.6959 4.25093 11.3519ZM7.51975 10.6356H11.3265C11.6979 10.6356 12 10.9416 12 11.3178C12 11.6948 11.6979 12.0001 11.3265 12.0001H7.51975C7.14835 12.0001 6.84627 11.6948 6.84627 11.3178C6.84627 10.9416 7.14835 10.6356 7.51975 10.6356Z" fill="#505968"/>
                 </svg>
               </a>
             </div>

           </div>
         </div>
       </div>
     </div>
   </el-form>

  </div>
</template>

<script>
import FundDocument from "@/apis/FundDocument";
import {Notification} from 'element-ui';

export default {
  name: "ViewDocument",
  props: {
    url: {
      type: String
    }
  },
  mounted() {
    this.getDocument();
  },
  data(){
    return{
      document: {},
      accounts: [],
      loading: false,
    }
  },
  methods: {
    getDocument(){
      this.loading = true;
      let id = this.$route.query.id;

      FundDocument.show(id).then((res)=>{
        this.loading = false;
        this.document = res.data.document;
        this.accounts = res.data.accounts;
      }).catch((error)=>{
        Notification.error(error.response.data.message);
      })
    },
  },
}
</script>

<style scoped>

.fund-details-card{
  width: 100%;
  height: 373px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  box-shadow: 0px 8px 20px 2px rgba(9, 21, 42, 0.03);
  overflow-y: auto;
  padding: 25px;
}
.fund-details-text{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000
}

.fund-name{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #505968;
  margin-top: 15px;
}
.fund-des{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #092C4C
}

.tag-button{
  width: auto;
  height: 31px;
  background: #F3F3F3;
  border-radius: 15.5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #092C4C;
  border: 0;
  margin: 5px;
  padding: 5px 10px;
}


</style>