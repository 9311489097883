import Api from "./Api";

export default {
    index(page) {
        return Api.get(`/v1/reports?page=${page}`);
    },


    store(formData) {
        return Api.post('/v1/reports', formData);
    },

    destroy(id) {
        return Api.delete(`/v1/reports/${id}`);
    },

    download(id) {
        return Api.get(`/v1/report-download/${id}`,{responseType: 'blob'});
    },

}