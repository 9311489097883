<template>
<div class="row" style="margin-top: -10px;">
  <div class="col-12">
    <div class="role-card">
        <div class="card-header d-flex justify-content-between">
          <div>
            <svg width="18" height="20"
                 viewBox="0 0 18 20"
                 fill="none"
                  xmlns="http://www.w3.org/2000/svg">
              <path d="M7.86748 1.8583L4.12499 3.41663C3.26249 3.77496 2.5575 4.90829 2.5575 5.93329V12.1249C2.5575 13.1083 3.1425 14.4 3.855 14.9916L7.07999 17.6666C8.13749 18.55 9.87748 18.55 10.935 17.6666L14.16 14.9916C14.8725 14.4 15.4575 13.1083 15.4575 12.1249V5.93329C15.4575 4.90829 14.7525 3.77496 13.89 3.41663L10.1475 1.8583C9.50999 1.59997 8.48998 1.59997 7.86748 1.8583Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.00002 9.10004C8.97002 9.10004 8.93251 9.10004 8.90251 9.10004C8.19751 9.07504 7.63501 8.42503 7.63501 7.63336C7.63501 6.82503 8.22752 6.16669 8.95502 6.16669C9.68252 6.16669 10.275 6.82503 10.275 7.63336C10.2675 8.43336 9.70502 9.07504 9.00002 9.10004Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.50753 11.4334C6.78753 11.9667 6.78753 12.8417 7.50753 13.375C8.32503 13.9834 9.66753 13.9834 10.485 13.375C11.205 12.8417 11.205 11.9667 10.485 11.4334C9.67503 10.825 8.33253 10.825 7.50753 11.4334Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span class="role-name">SUPER ADMIN</span>
          </div>
          <el-button class="setting-button"
                  @click.prevent="updatePermissions('superadmin')"
                  :loading="superAdminLoading">
            Update Setting
          </el-button>
        </div>
        <div class="role-card-body">
          <div class="permission-box">
            <div class="permission-title">Permissions</div>
            <div class="permission-wrapper">
              <el-select v-model="formData.superAdminPermissions"
                         multiple
                         filterable
                         placeholder="Select Permissions"
                         style="width: 100%;">

                <el-option-group
                    v-for="(group,name) in permissions"
                    :key="name"
                    :label="name + ' ' +'permissions'">
                  <el-option
                      v-for="item in group"
                      :key="item.id"
                      :label="item.display_name"
                      :value="item.id">
                  </el-option>
                </el-option-group>

              </el-select>
            </div>

          </div>
        </div>
      </div>
    <div class="role-card">
      <div class="card-header d-flex justify-content-between">
        <div>
          <svg width="20" height="20"
               viewBox="0 0 20 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5666 7.14993V12.8499C17.5666 13.7832 17.0666 14.6499 16.2583 15.1249L11.3083 17.9833C10.4999 18.4499 9.49993 18.4499 8.68326 17.9833L3.73326 15.1249C2.92493 14.6583 2.42493 13.7916 2.42493 12.8499V7.14993C2.42493 6.21659 2.92493 5.34989 3.73326 4.87489L8.68326 2.01657C9.49159 1.5499 10.4916 1.5499 11.3083 2.01657L16.2583 4.87489C17.0666 5.34989 17.5666 6.20826 17.5666 7.14993Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.0001 9.16676C11.0724 9.16676 11.9417 8.29743 11.9417 7.22508C11.9417 6.15272 11.0724 5.28345 10.0001 5.28345C8.92772 5.28345 8.05841 6.15272 8.05841 7.22508C8.05841 8.29743 8.92772 9.16676 10.0001 9.16676Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3334 13.8834C13.3334 12.3834 11.8417 11.1667 10.0001 11.1667C8.15841 11.1667 6.66675 12.3834 6.66675 13.8834" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="role-name">ADMIN</span>
        </div>
        <el-button class="setting-button"
                @click.prevent="updatePermissions('admin')" :loading="adminLoading">
          Update Setting
        </el-button>
      </div>
      <div class="role-card-body">
        <div class="permission-box">
          <div class="permission-title">Permissions</div>
          <div class="permission-wrapper">
            <el-select v-model="formData.adminPermissions"
                       multiple
                       filterable
                       placeholder="Select Permissions"
                       style="width: 100%;">

              <el-option-group
                  v-for="(group,name) in permissions"
                  :key="name"
                  :label="name + ' ' +'permissions'">
                <el-option
                    v-for="item in group"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id">
                </el-option>
              </el-option-group>

            </el-select>
          </div>

        </div>
      </div>
    </div>
    <div class="role-card">
      <div class="card-header d-flex justify-content-between">
        <div>
          <svg width="20" height="20"
               viewBox="0 0 20 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95964 14.0503V15.742C8.95964 17.1753 7.6263 18.3336 5.98463 18.3336C4.34296 18.3336 3.00128 17.1753 3.00128 15.742V14.0503C3.00128 15.4836 4.33463 16.5003 5.98463 16.5003C7.6263 16.5003 8.95964 15.4753 8.95964 14.0503Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.95834 11.7584C8.95834 12.175 8.84167 12.5583 8.64167 12.8917C8.15001 13.7 7.14166 14.2084 5.975 14.2084C4.80833 14.2084 3.79999 13.6917 3.30832 12.8917C3.10832 12.5583 2.9917 12.175 2.9917 11.7584C2.9917 11.0417 3.32501 10.4 3.85835 9.93336C4.40001 9.45836 5.14165 9.17505 5.96665 9.17505C6.79165 9.17505 7.53335 9.46669 8.07501 9.93336C8.62501 10.3917 8.95834 11.0417 8.95834 11.7584Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.95964 11.7583V14.0499C8.95964 15.4833 7.6263 16.4999 5.98463 16.4999C4.34296 16.4999 3.00128 15.4749 3.00128 14.0499V11.7583C3.00128 10.3249 4.33463 9.16663 5.98463 9.16663C6.80963 9.16663 7.55132 9.45827 8.09299 9.92494C8.62632 10.3916 8.95964 11.0416 8.95964 11.7583Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.3333 9.14159V10.8583C18.3333 11.3166 17.9667 11.6916 17.5 11.7083H15.8667C14.9667 11.7083 14.1417 11.0499 14.0667 10.1499C14.0167 9.62494 14.2167 9.13327 14.5667 8.79161C14.875 8.47494 15.3 8.29163 15.7667 8.29163H17.5C17.9667 8.30829 18.3333 8.68326 18.3333 9.14159Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.66669 8.74996V7.08329C1.66669 4.81663 3.03336 3.23329 5.15836 2.96662C5.37502 2.93329 5.60002 2.91663 5.83335 2.91663H13.3334C13.55 2.91663 13.7584 2.92495 13.9584 2.95828C16.1084 3.20828 17.5 4.79996 17.5 7.08329V8.29164H15.7667C15.3 8.29164 14.875 8.47495 14.5667 8.79162C14.2167 9.13328 14.0167 9.62495 14.0667 10.15C14.1417 11.05 14.9667 11.7083 15.8667 11.7083H17.5V12.9166C17.5 15.4166 15.8334 17.0833 13.3334 17.0833H11.25" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="role-name">INVESTOR</span>
        </div>
        <el-button class="setting-button"
                @click.prevent="updatePermissions('investor')"
                :loading="investorLoading">
          Update Setting
        </el-button>
      </div>
      <div class="role-card-body">
        <div class="permission-box">
          <div class="permission-title">Permissions</div>
          <div class="permission-wrapper">
            <el-select v-model="formData.investorPermissions"
                       multiple
                       filterable
                       placeholder="Select Permissions"
                       style="width: 100%;">

              <el-option-group
                  v-for="(group,name) in permissions"
                  :key="name"
                  :label="name + ' ' +'permissions'">
                <el-option
                    v-for="item in group"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id">
                </el-option>
              </el-option-group>

            </el-select>
          </div>

        </div>
      </div>
    </div>
    <div class="role-card">
      <div class="card-header d-flex justify-content-between">
        <div>
          <svg width="20" height="20"
               viewBox="0 0 20 20" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1333 9.05817C10.05 9.04984 9.94999 9.04984 9.85832 9.05817C7.87499 8.9915 6.29999 7.3665 6.29999 5.3665C6.29999 3.32484 7.94999 1.6665 9.99999 1.6665C12.0417 1.6665 13.7 3.32484 13.7 5.3665C13.6917 7.3665 12.1167 8.9915 10.1333 9.05817Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.9666 12.1335C3.94993 13.4835 3.94993 15.6835 5.9666 17.0252C8.25827 18.5585 12.0166 18.5585 14.3083 17.0252C16.3249 15.6752 16.3249 13.4752 14.3083 12.1335C12.0249 10.6085 8.2666 10.6085 5.9666 12.1335Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="role-name">GENERAL</span>
        </div>
        <el-button class="setting-button"
                @click.prevent="updatePermissions('general')"
                :loading="generalLoading">
          Update Setting
        </el-button>
      </div>
      <div class="role-card-body">
        <div class="permission-box">
          <div class="permission-title">Permissions</div>
          <div class="permission-wrapper">
            <el-select v-model="formData.generalPermissions"
                       multiple
                       filterable
                       placeholder="Select Permissions"
                       style="width: 100%;">

              <el-option-group
                  v-for="(group,name) in permissions"
                  :key="name"
                  :label="name + ' ' +'permissions'">
                <el-option
                    v-for="item in group"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id">
                </el-option>
              </el-option-group>

            </el-select>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Role from "@/apis/Role";
import {Notification} from "element-ui";

export default {
  name: "RolePermissionUpdate",
  data(){
    return{
      formData:{
        superAdminPermissions: [],
        adminPermissions: [],
        investorPermissions: [],
        generalPermissions: [],
      },
      rules: {},
      permissions: [],
      loading: false,
      superAdminLoading: false,
      adminLoading: false,
      investorLoading: false,
      generalLoading: false,

    }
  },
  mounted() {
    this.getAllPermissions();
    this.getRoles();
  },
  methods:{
    async getRoles() {
      await Role.getRolePermission().then((res)=>{

        res.data.data.forEach((role)=>{
          if (role.name === 'superadmin'){
            this.formData.superAdminPermissions = role.permissions
          }else if (role.name === 'admin'){
            this.formData.adminPermissions = role.permissions
          }else if (role.name === 'investor'){
            this.formData.investorPermissions = role.permissions
          }else if (role.name === 'general'){
            this.formData.generalPermissions = role.permissions
          }

        })


      })
    },

    async getAllPermissions() {
      await Role.allPermissions().then((res)=>{
        this.permissions = res.data.permissions
      })
    },

    updatePermissions(name){
      let formData = new FormData();
      let id = '';

      if (name === 'superadmin'){
        formData.append('permissions',this.formData.superAdminPermissions);
        id = 1;
        this.superAdminLoading = true;
      }else if (name === 'admin'){
        formData.append('permissions',this.formData.adminPermissions);
        id = 2;
        this.adminLoading = true;
      }else if (name === 'investor'){
        formData.append('permissions',this.formData.investorPermissions);
        id = 3;
        this.investorLoading = true;
      }else if (name === 'general'){
        formData.append('permissions',this.formData.generalPermissions);
        id = 4;
        this.generalLoading = true;
      }


      Role.update(id,formData).then((res) => {
        this.roles = res.data.data
        Notification.success('Permissions updated successfully.');
        this.superAdminLoading = false;
        this.adminLoading = false;
        this.investorLoading = false;
        this.generalLoading = false;
      }).catch(()=>{
        Notification.error('Something went wrong!');
        this.superAdminLoading = false;
        this.adminLoading = false;
        this.investorLoading = false;
        this.generalLoading = false;
      })
    },

  }
}
</script>

<style scoped>
.role-card{
  box-sizing: border-box;
  width: 100%;
  min-height: 194px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  margin-top: 26px;
}

.role-card .card-header{
  border-radius: 15px 15px 0 0;
  background: #FAFAFA;
  padding: 10px 15px !important;
  height: 50px !important;
}

.role-name{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #092C4C;
  padding-left: 10px;
  padding-top: 5px;
}

.setting-button{
  min-width: 118px;
  height: 30px;
  border: 1px solid #4F31FF;
  background: #FAFAFA !important;
  border-radius: 8px;
  padding: 7px 15px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4F31FF;
}

.role-card-body{
  padding: 20px 15px;
}

.permission-box{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 767px) {
  .permission-box{
    flex-direction: column;
  }

}

.permission-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.permission-wrapper{
  width: 100%;
  padding: 8px;
  border: 1px solid #EBEBEB;
  border-radius: 12px;
}

</style>