import Api from "@/apis/Api";

export default {

    userList() {
        return Api.get("/v1/get/userList");
    },

    deleteUserProfilePicture() {
        return Api.delete("/v1/remove-profile-picture");
    },



}