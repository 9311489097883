import Api from "./Api";

export default {
    index(page) {
        return Api.get(`/v1/communication?page=${page}`);
    },

    destroy(id) {
        return Api.delete(`/v1/communication/delete/${id}`);
    },

}