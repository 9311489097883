<template>
<h3>Edit document</h3>
</template>

<script>
export default {
name: "EditDocumentModal"
}
</script>

<style scoped>

</style>