<template>
  <el-form
    ref="form"
    class="acceson-form"
    :model="newDocument"
    :rules="rules"
    label-position="top"
    label-width="120px"
    v-loading="loading"
  >
    <div class="row">
      <div class="col-12 mb-3">
        <el-form-item prop="tempDocument">
          <el-upload
              class="upload-demo acceson-uploader doc-upload"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="fileChange"
              accept="application/pdf,.zip"
              :auto-upload="false"
              :file-list="fileList"
              :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="upload-file-title">Upload File</div>
            <div class="or">Or</div>
            <div class="upload-file-text">
              Drag & Drop here
            </div>
            <div class="upload-file-size-text" >
              application/pdf or zip files with a size less than 2MB
            </div>
          </el-upload>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item label="Document Title" prop="title">
          <el-input v-model="newDocument.title" placeholder="Input name" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Document Profile" prop="document_profile">
          <el-input v-model="newDocument.document_profile" placeholder="Document profile" disabled />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Security" prop="security">
          <el-select
            v-model="newDocument.security"
            placeholder="Select Security"
            class="w-100"
          >
            <el-option v-for="(security,index) in securityOptions"
                       :key="index"
                       :label="security.label"
                       :value="security.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-6" v-if="newDocument.document_profile === 'bulk'">
        <el-form-item label="Split Mode" prop="split_method">
          <el-select
            v-model="newDocument.split_method"
            placeholder="Select Split Mode"
            class="w-100"
          >
            <el-option label="Merged" value="merged"> </el-option>
            <el-option label="Separated" value="separated"> </el-option>
          </el-select>
        </el-form-item>
      </div>
     <div class="col-md-6" v-if="newDocument.split_method === 'merged'">
        <el-form-item label="Split Per Page" prop="split_method">
          <el-select
            v-model="newDocument.split_per_page"
            placeholder="Select Per Page"
            class="w-100"
          >
            <el-option label="1" :value="1"> </el-option>
            <el-option label="2" :value="2"> </el-option>
            <el-option label="3" :value="3"> </el-option>
            <el-option label="4" :value="4"> </el-option>
            <el-option label="5" :value="5"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Category" prop="category_id">
          <el-select
            v-model="newDocument.category_id"
            filterable
            placeholder="Select Category"
            class="w-100"
          >
            <el-option
              v-for="(value, id) in categoryList"
              :key="id"
              :label="value"
              :value="id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Document Date" prop="date">
          <el-date-picker
            v-model="newDocument.date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="MM/dd/yyyy"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Due Date" prop="due_date">
          <el-date-picker
            v-model="newDocument.due_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="MM/dd/yyyy"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </div>      
    </div>

    <div class="col-md-12 text-right">
      <button
          type="button"
          v-if="currentStep > 1"
          class="common-button"
          @click="currentStep -= 1"
      >
        Back
      </button>
      <button
          type="button"
          class="cancel-button common-button mx-2"
          @click="saveAndClose('form')"
      >
        Save & Close
      </button>
      <button
          type="button"
          class="common-button"
          @click="next('form')"
      >
        Next
      </button>
    </div>

  </el-form>
</template>
<script>
import Category from "../../../apis/Category";
import Document from "../../../apis/Document";
export default {
  name: "DocumentDetail",
  props: {
    document: Object,
  },
  data() {
    return {
      loading: false,
      fileList: [],
      categoryList: [],
      newDocument: { date:'' },
      tempDocument: null,
      currentStep: 1,
      securityOptions: [
        {
          value: 'yes',
          label: 'Does not Allow saving and printing of this document'
        }, {
          value: 'no',
          label: 'Allow saving and printing of this document'
        }],
      rules: {
        title: {
          required: true,
          type: "string",
        },
        category_id: {
          required: true,
          message: 'Category is required.'
        },
        security: {required: true,message: 'Security is required',trigger: 'blur'},
        split_method: {
          required: true,
          type: "string",
          message: 'Split Method is required.'
        },
        date: {
          required: true,
        },
        due_date: {
          required: false,
        },
      },
    };
  },

  created() {
    Category.list().then((res) => {
      this.categoryList = res.data.data;
    });

   setTimeout(()=>{
     this.newDocument = { ...this.document };
   },1000)

  },
  methods: {
    handleClose() {
      this.$emit("close", false);
    },

    saveAndClose() {
      this.handleSubmit('form');
    },
    next() {
      this.handleSubmit('form', 'next');
    },
    handleSubmit(formName, type) {
      type = type?type:'';

      this.$refs[formName].validate((valid) => {
        if (valid) {

          let formData = new FormData();

          formData.append('_method','PUT')
          formData.append("document", this.tempDocument);
          formData.append("title", this.newDocument.title);
          formData.append("security", this.newDocument.security);
          formData.append("date", this.newDocument.date);
          formData.append("status", this.newDocument.status);


          formData.append(
            "due_date",
            this.newDocument.due_date ? this.newDocument.due_date : ""
          );
          formData.append(
            "category_id",
            this.newDocument.category_id ? this.newDocument.category_id : ""
          );
          formData.append(
            "document_profile",
            this.newDocument.document_profile ? this.newDocument.document_profile : ""
          );

          if(this.newDocument.document_profile === 'bulk') {
            formData.append(
            "split_method",
            this.newDocument.split_method ? this.newDocument.split_method : ""
          );
          }
          
          if (this.newDocument.split_method === "merged") {
            formData.append(
              "split_per_page",
              this.newDocument.split_per_page ? this.newDocument.split_per_page : ""
            );
          }

          this.loading = true;

            Document.updateDoc(this.newDocument.id,formData).then((res) => {
              this.$emit("submitted", res.data.data);
              this.$notify({
                title: "Success!",
                message: "Document successfully updated.",
                type: "success",
              });
              this.loading = false;

              if(type === 'next') {
                this.$emit('next');
              }else {
                this.$emit('cancel', true);
              }

            })
                .catch((error) => {
                  if (error.response) {
                    this.formErrors(error.response.data, this.$notify.error);
                  } else {
                    this.errorMessage(error);
                  }
                  this.loading = false;
                });

        } else {
          this.errorMessage("Error while submitting!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    fileChange(file, fileList) {
      fileList = [];
      this.tempDocument = file.raw;
    },
    handlePreview(file) {
      console.log(file);
    },
  },

};
</script>
<style scoped>
.doc-upload .el-upload-dragger .el-icon-upload {
    margin: 10px 0 16px;
    font-size: 50px;    
    line-height: 40px;
}
.upload-file-title{
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #4F31FF;
}
.or{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
  opacity: 0.6;
}

.upload-file-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
}
.upload-file-size-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #505968;
  margin-top: 15px;
  padding: 10px;
}

.doc-upload .el-upload, .doc-upload .el-upload-dragger {
  height: auto !important;
  border: 2px dashed rgba(79, 49, 255, 0.5) !important;
  word-break: keep-all !important;
}


</style>
