<template>
  <InvestorList/>
</template>

<script>
import InvestorList from "@/components/investor/List";
export default {
  name: "InvestorPage",
  components: {InvestorList}
}
</script>

<style scoped>

</style>