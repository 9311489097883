<template>
    <el-dialog
        title="Confirmation"
        custom-class="modal-custom-class"
        :visible.sync="open"
        width="642px"
        :before-close="handleClose">
        <h1>Are you sure you want to delete this ?</h1>
        <div slot="footer" class="dialog-footer mt-5">

            <button
                class="cancel-button common-button"
                @click.prevent="$emit('close', false)"
                :disabled="disabled"
            >No</button>

            <button
                class="common-button mx-3"
                @click.prevent="$emit('confirm', true)"
                :disabled="disabled"
            >Yes
            </button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "DeleteModal",
    props: {
        open: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('close', false);
        }
    }
}
</script>

<style scoped>

</style>