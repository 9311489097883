import Api from "./Api";

export default {

    allPermissions() {
        return Api.get(`/v1/permission-list`);
    },

    roleList() {
        return Api.get(`/v1/role-list`);
    },

    index(page) {
        return Api.get(`/v1/roles?page=${page}`);
    },

    store(formData) {
        return Api.post('/v1/roles/store', formData);
    },
    edit(id) {
        return Api.get(`/v1/roles/edit/${id}`);
    },

    update(id, formData) {
        return Api.post(`/v1/roles/update/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/roles/delete/${id}`);
    },

    getRolePermission() {
        return Api.get(`/v1/role-permission`);
    },

    updatePermissions(roles) {
        return Api.post(`/v1/role-permission`,{roles: roles});
    },


}