<template>
  <div>

    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        class="mt-2 profile-form overflow-hidden"
        label-position="top"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <el-form-item label="Enter your current password" prop="current_password">
                <el-input v-model="formData.current_password"
                          type="password" show-password placeholder="********"/>
              </el-form-item>
            </div>
            <div class="col-md-12">
              <el-form-item label="Enter your new password" prop="new_password">
                <el-input v-model="formData.new_password" type="password" show-password
                          placeholder="********"/>
              </el-form-item>
            </div>
          </div>

          <div class="mt-3">
            <el-button v-if="checkPermission('profile_all','profile_changePassword')"
                       class="common-button mt-2"
                       type="success"
                       :loading="loading"
                       @click="handleSubmit('form')"
            >Change Password
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>

import Profile from "@/apis/Profile";
import Auth from "@/apis/Auth";
import {Notification} from "element-ui";

export default {
  name: "ChangePassword",
  data() {
    return {
      formData: {
        current_password : null,
        new_password: null,
      },
      permissions: [],
      rules: {
        current_password: [
          {required: true, message: 'Current Password is required', trigger: 'blur'},
          {
            min: 6,
            message: 'Password must be at least 8 characters',
            trigger: 'blur'
          }
        ],
        new_password: [
          {required: true, message: 'New Password is required', trigger: 'blur'},
          {
            min: 6,
            message: 'Password must be at least 8 characters',
            trigger: 'blur'
          }
        ],
      },
      loading: false,
    }
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    checkPermission(allPermission,specificPermission){


      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;


    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callToBackendChangePasswordApi();
        } else {
          return false;
        }
      });
    },

    callToBackendChangePasswordApi() {

      this.loading = true;

      let formData = new FormData();
      formData.append('current_password',this.formData.current_password);
      formData.append('new_password',this.formData.new_password);

      Profile.changePassword(formData).then((res)=>{

        if (res.data.status === true){
          this.formData.current_password = null;
          this.formData.new_password = null;

          this.$notify({
            title: "Success!",
            message: "Password Updated Successfully.",
            type: "success",
          });
        }else {
          this.$notify({
            title: "Failed!",
            message: "Your Password does not match.",
            type: "error",
          });
        }

        this.loading = false;

      }).catch((error)=>{

        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;

      })

    },

  },



}
</script>

<style scoped>

</style>