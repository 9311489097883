import Api from "./Api";

export default {
    index(page) {
        return Api.get(`/users?page=${page}`);
    },
    show(id) {
        return Api.get(`/users/${id}`);
    },
    store(data) {
        return Api.post('/users', data);
    },
    patch(id, data) {
        return Api.patch(`/users/${id}`, data);
    },
    destroy(id) {
        return Api.delete(`/users/${id}`);
    },
    invite(id) {
      return Api.get(`/users/${id}/invite`);
    },
    checkInviteUrl(email, token) {
        return Api.get(`check-invite-url/${email}/${token}`);
    },
    setPassword(email, token, data) {
        return Api.post(`set-password/${email}/${token}`, data);
    },
    contactsByRole(role) {
        return Api.get(`/v1/roles/users?role=${role}`);
    },
    accessRights(data) {
        return Api.post('/v1/contract-access-rights', data);
    },
    contactList(page,user_type,is_invited,investor) {
        return Api.get(`/v1/contact/lists?page=${page}&user_type=${user_type}&is_invited=${is_invited}&investor=${investor}`);
    },
    exportToExcel(user_type,is_invited,investor) {
        return Api.get(`/v1/all-users/export?user_type=${user_type}&is_invited=${is_invited}&investor=${investor}`,{responseType: 'blob'});
    },

    view(id) {
        return Api.get(`/user/details/${id}`);
    },

}