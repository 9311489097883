<template>
  <div class="d-flex justify-content-center align-items-center h-100 w-100 flex-column">
    <img src="../../assets/images/page_not_found.svg" alt="not-found" />
    <button class="btn btn-primary mt-5" @click="$router.push('/')">
      Go to Home
    </button>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
</style>