import Api from "./Api";

export default {
    index(page) {
        return Api.get(`/v1/emails?page=${page}`);
    },


    store(formData) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return Api.post('/v1/emails/store', formData, config);
    },

    show(id) {
        return Api.get(`/v1/emails/show/${id}`);
    },

    edit(id) {
        return Api.get(`/v1/emails/edit/${id}`);
    },


    update(id, formData) {
        return Api.post(`/v1/emails/update/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/emails/delete/${id}`);
    },

}