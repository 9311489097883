import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/custome.css';
import './assets/template/css/app.css';
import './assets/template/js/app.js';
// import './assets/js/dragging';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueApexCharts from 'vue-apexcharts';
import VueNumber from 'vue-number-animation';
import VueObserveVisibility from 'vue-observe-visibility';

require('./util/filters');
require('./util/prototypes');
require('./util/mixins');

locale.use(lang);
Vue.use(ElementUI);
Vue.use( CKEditor );
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(VueNumber);
Vue.use(VueObserveVisibility);




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
