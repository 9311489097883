import axios from 'axios';
import store from '@/store/index.js';
import router from "@/router";


const Api = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
});

Api.interceptors.request.use((config) => {
    // Do something before request is sent
    const token = localStorage.getItem('token');
    if(!config.headers.Authorization && token)
        config.headers.Authorization = 'Bearer ' + token;
    return config;
},  (error) => {
    // Do something with request error
    return Promise.reject(error);
});

Api.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 403){
        window.location.href = '/forbidden';
    }

    if (error.response.status === 401) {        
        store.dispatch('auth/logout');
        // window.location.href = '/login';
        if (!router.currentRoute.path === '/login'){
            router.push('/login');
        }
    }
    return Promise.reject(error);
});


export default Api;