<template>
  <div class="row">
    <table>
      <thead>
        <tr>
          <th>Document Title</th>
          <th>Funds</th>
          <th>Category</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ document.title }}</td>
          <td>
            <span class="fund-badge" v-for="(fund, fundKey) in document.funds" :key="fund.id">
              {{ fund.name }} <span v-if="fundKey+1 < document.funds.length">, </span>
            </span>
          </td>
          <td>{{ document.category ? document.category.name : ''}}</td>
          <td>
            <span class="doc-status" :class="document.status == 'ACTIVE'?'active':'pending'"> </span> {{ document.status }}            
          </td>
        </tr>
      </tbody>
    </table> 

    <div class="col-md-12 text-right mt-4">
      <button
          type="button"
          class="cancel-button common-button mx-3"
          @click="back()"
      >
        Back
      </button>
      <button
          type="button"
          class="common-button mx-2"
          @click="saveAndClose()"
      >
        Save & Close
      </button>
      <button v-if="document.status === 'ACTIVE'"
          type="button"
          class="common-button mx-2"
          @click="markAsActive()"
      >
        Set As Pending
      </button>
      <button v-if="document.status === 'PENDING'"
          type="button"
          class="common-button mx-2"
          @click="markAsActive()"
      >
        Approve
      </button>
    </div>
  </div>
</template>
<script>
import Document from "../../../apis/Document";
export default {
  name: "DocumentSummary",
  props: {
    document: Object,
  },
  methods: {
    markAsActive() {      
      Document.markActive(this.document.id)
        .then((res) => {
          this.$emit("submitted", res.data.data);
          this.loading = false;
          this.$emit("cancel", true);
          this.$notify({
            title: "Success!",
            message: "Document status successfully updated.",
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.formErrors(error.response.data, this.$notify.error);
          } else {
            this.errorMessage(error);
          }
          this.loading = false;
        });
    },
    back() {
      this.$emit("back");
    },
    saveAndClose() {        
      this.$emit("cancel", true);
    },
  }
};
</script>
<style scoped>
.summary-btn {
  background-color: #EAF2FD;
  color: #3584EE;
  border: none;
}
table th, table td {
  padding: 8px 10px;
  border: none;
}
.doc-status {
    width: 12px;
    height: 12px;
    display: inline-block;    
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 4px;
}
.doc-status.active {
    background-color: #27AE60;
}
.doc-status.pending {
    background-color: red;
}
</style>
