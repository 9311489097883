<template>
    <el-dialog
        title="Add New Contact"
        custom-class="modal-custom-class"
        :visible.sync="open"
        width="642px"
        :before-close="handleClose">
        <el-form
            ref="form"
            class="acceson-form"
            :model="formData"
            :rules="rules"
            label-position="top"
            label-width="120px"
            v-loading="loading"
        >
            <div class="row">
                <div class="col-md-6">
                    <el-form-item label="First Name" prop="first_name">
                        <el-input
                            v-model="formData.first_name"
                            placeholder="Input first name"
                        />
                    </el-form-item>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Last Name" prop="last_name">
                        <el-input
                            v-model="formData.last_name"
                            placeholder="Input last name"
                        />
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <el-form-item label="Email" prop="email">
                        <el-input
                            v-model="formData.email"
                            placeholder="Input email">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Phone Number" prop="phone_number">
                        <el-input
                            v-model="formData.phone_number"
                            placeholder="Input phone number">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Company" prop="company">
                    <el-input
                        v-model="formData.company"
                        placeholder="Input company"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Role" prop="role">
                        <el-select v-model="formData.role" @change="checkRoleType"
                                   style="width: 100%;"
                                   placeholder="Select Role">
                            <el-option
                                v-for="item in roles"
                                :key="item.id"
                                :label="item.display_name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="selectedRole.type === 'investor'">
                <div class="col-md-6">
                    <el-form-item label="Committed" prop="committed">
                        <el-input
                            v-model="formData.committed"
                            placeholder="Input committed amount"
                            type="number"
                            step="any"
                        />
                    </el-form-item>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Investments" prop="investments">
                        <el-input
                            v-model="formData.investments"
                            placeholder="Input investments"
                            type="number"
                            step="any"
                        />
                    </el-form-item>
                </div>
            </div>

            <div class="row" v-if="selectedRole.type === 'investor'">
                <div class="col-md-6">
                    <el-form-item label="Last Update" prop="last_update">
                        <el-date-picker
                            style="width: 100%"
                            v-model="formData.last_update"
                            type="date"
                            placeholder="Pick a day">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-md-6"></div>
            </div>


          <div class="row" v-for="(account,index) in formData.accounts"
               :key="index">
            <div class="col-md-6" v-if="selectedRole.type === 'general'">
              <el-form-item label="Accounts" prop="id">
                <el-select
                    v-model="account.id"
                    @change="checkFund(account,index)"
                    filterable
                    placeholder="Select Accounts"
                    class="w-100"
                >
                  <el-option
                      v-for="item in accountsList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="col-md-6" v-if="selectedRole.type === 'general'">
              <el-form-item label="Select Funds" prop="funds">
                <el-select
                    v-model="account.funds"
                    multiple
                    filterable
                    placeholder="Select Fund"
                    class="d-inline-block"
                    style="width: 80%!important;"
                >
                  <el-option
                      v-for="item in account.allFunds"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-button
                    type="danger"
                    circle
                    size="small"
                    class="circle-danger-btn d-inline-block ms-2"
                    title="Delete Row"
                    @click="deleteFundRow(index)"
                >
                  <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                        fill="#EB5757"
                    />
                  </svg>

                </el-button>
              </el-form-item>
            </div>

          </div>

            <div class="row" v-if="selectedRole.type === 'general'">
              <div class="col-12">
                <button
                    class="common-button"
                    type="button"
                    @click="addMoreAccount"
                >
                  + Add More Account
                </button>
              </div>
            </div>




        </el-form>


        <div slot="footer" class="dialog-footer">
            <button
                class="cancel-button common-button mx-3"
                @click="$emit('cancel', true)"
                :disabled="disabled"
            >Cancel
            </button>
            <button
                class="common-button"
                @click="handleSubmit('form')"
                :disabled="disabled"
            >Save
            </button>
        </div>
    </el-dialog>
</template>

<script>
import Contact from "../../apis/Contact";
import Account from "../../apis/Account";
import Role from "@/apis/Role";

export default {
    name: "AddNewModal",
    props: {
        open: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role: '',
                company: '',
                tags: [],
                committed: '',
                investments: '',
                last_update: '',
                accounts: [{id: null,funds: [] , allFunds: []}],
            },
            rules: {
                first_name: {
                    required: true,
                    type: 'string'
                },
                last_name: {
                    required: true,
                    type: 'string'
                },
                email: {
                    required: true,
                    type: 'email'
                },
                role: {
                    required: true,
                }
            },

            accountsList: [],
            selectedRole: {},
            tagOptions: [],
            roles: [],
            loading: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('close', false);
        },
        addMoreAccount(){
          this.formData.accounts.push({id: null,funds: [],allFunds: []});
        },
        deleteFundRow(index) {
          this.formData.accounts.splice(index, 1);
        },
        checkRoleType(){
            this.selectedRole = this.roles.find(({ name }) => name === this.formData.role);
            if(this.selectedRole.type === 'general') {
                Account.list('').then((res) => {
                    this.accountsList = res.data.data;
                });
            }else {
              this.showFund = false;
            }
        },

        checkFund(account,index){

          Account.fundList(account.id).then((res)=>{
            this.formData.accounts[index].allFunds = res.data
          }).catch((error)=>{
            console.log(error)
          })
        },

        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    Contact.store(this.formData)
                        .then(response => {
                          if (response.data.data.user_role === 'general'){
                            this.$router.push(`/contact-access-rights?uid=${response.data.data.id}`)
                          }
                            this.$emit('submitted', response.data)
                            this.loading = false;
                        })
                        .catch(error => {
                            if (error.response) {
                                this.formErrors(error.response.data, this.$notify.error)
                            } else {
                                this.errorMessage(error);
                            }
                            this.loading = false;
                        })
                } else {
                    this.errorMessage('Error while submitting!');
                    return false;
                }
            });
        },


    },

    mounted() {
      Role.roleList().then((res)=>{
        this.roles = res.data.roles;
      }).catch((error)=>{
        console.log(error)
      })      

    },

}
</script>

<style scoped>

</style>