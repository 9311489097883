<template>
  <el-dialog
      title="Edit Commitments"
      custom-class="modal-custom-class"
      :visible.sync="open"
      width="550px"
      :before-close="handleClose">
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
        v-loading="loading"
    >


      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Investor Name" prop="investor_id">
            <el-select v-model="formData.investor_id" style="width: 100%"
                       placeholder="Select">
              <el-option
                  v-for="item in accounts"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

      </div>

      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Committed Capital" prop="committed_amount">
            <el-input @input="formatNumber"
                v-model.number="formData.committed_amount"
                placeholder="Enter amount here"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Status" prop="status">
            <el-select v-model="formData.status" style="width: 100%"
                       placeholder="Select">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
          class="cancel-button common-button mx-3"
          @click.prevent="$emit('cancel', true)"
          :disabled="disabled"
      >Cancel
      </button>
      <el-button
          class="common-button"
          @click.prevent="handleSubmit('form')"
          :disabled="disabled"
          :loading="buttonLoading"
      >Update
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import FundCommitment from "@/apis/FundCommitment";
import Account from "@/apis/Account";

export default {
  name: "EditCommitmentModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formData: {
        fund_id: this.$route.params.id,
        investor_id: '',
        committed_amount: '',
        status: '',
      },
      rules: {
        investor_id: { required: true, message: 'Please insert Investor name', trigger: 'blur' },
        committed_amount: {required: true,message: 'Please insert Committed Capital',trigger: 'blur'},
        status: {required: true,message: 'Please select status',trigger: 'blur'},
      },
      options: [
        {
          value: 1,
          label: 'Active'
        }, {
          value: 0,
          label: 'Pending'
        }],
      accounts: [],
      loading: false,
      buttonLoading: false,
    }
  },

  mounted() {
    this.getAccountList();
  },

  methods: {
    getAccountList(){
      Account.list().then((res)=>{
        this.accounts = res.data.data;
      })
    },
    formatNumber(value){
      let tempNumber = value.replace(/,/gi, "");
      let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

      this.formData.committed_amount = commaSeparatedNumber
    },
    handleClose() {
      this.$emit('close', false);
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.callToBackendStoreTemplateApi();
        } else {
          return false;
        }
      });
    },

    callToBackendStoreTemplateApi(){
      let committed_amount =  this.formData.committed_amount.replace(/,/g, '');

      FundCommitment.update(this.id,{
        fund_id: this.formData.fund_id,
        investor_id: this.formData.investor_id,
        committed_amount: committed_amount,
        status: this.formData.status,
      }).then((res)=>{
        this.$notify({
          title: "Success!",
          message: "Investor Updated Successfully.",
          type: "success",
        });

        this.$emit('submitted', res.data)
        this.buttonLoading = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.buttonLoading = false;
      }).finally(()=>{
        this.buttonLoading = false;
      })

    },

  },
}
</script>

<style scoped>

</style>