<template>
  <div class="row mb-3">
    <div class="col">
      <el-select v-model="value" placeholder="Type">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Status">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Upload Date">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Fund Name">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Investor Name">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Category Name">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col">
      <el-select v-model="value" placeholder="Batch ID">
        <el-option
          v-for="item in options"
          style="width: 100%"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
export default {
  name: "DocumentFilter",
  data() {
    return {
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      value: "",
    };
  },
};
</script>
