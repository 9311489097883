<template>
  <div class="container-fluid p-0 mt-n1">

    <div class="title-section mb-3">
      <h1 class="list-title">Reports List</h1>
      <div>
        <span class="dropdown"
              v-if="checkPermission('report_all','report_create')">
            <button class="dropdown-button dropdown-toggle" type="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
             + Create Report
            </button>
            <ul class="dropdown-menu mt-5">
              <li><a @click.prevent="openCreateInvestorReportModal = true"
                     class="dropdown-item">Investor
              </a></li>
              <div class="dropdown-divider"></div>
              <li><a @click.prevent="openCreateContactReportModal = true" class="dropdown-item">Contact
              </a></li>
              <div class="dropdown-divider"></div>
              <li><a @click.prevent="openCreateDocumentReportModal = true" class="dropdown-item">Document
              </a></li>
            </ul>
          </span>
      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <el-tabs v-model="activeName">
          <el-tab-pane name="All Reports">
              <span slot="label">
                <svg style="margin-right: 5px; margin-top: -5px !important;" width="14" height="17"
                     viewBox="0 0 14 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.2812 1.50893H4.2094C2.63976 1.50893 1.28662 2.78126 1.28662 4.35166V12.5185C1.28662 14.1774 2.55133 15.5 4.2094 15.5H10.2997C11.8701 15.5 13.1424 14.0897 13.1424 12.5185V5.53099L9.2812 1.50893Z"
                      :stroke="activeName === 'All Reports' ? '#4F31FF' :
                      '#505968'"
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.08105 1.5V3.71762C9.08105 4.80014 9.95697 5.67834 11.0387 5.68063C12.0427 5.68292 13.0696 5.68368 13.1389 5.6791"
                      :stroke="activeName === 'All Reports' ? '#4F31FF' :
                      '#505968'"
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.93608 11.2637H4.82178"
                      :stroke="activeName === 'All Reports' ? '#4F31FF' :
                      '#505968'"
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.37968 7.48839H4.82129"
                      :stroke="activeName === 'All Reports' ? '#4F31FF' :
                      '#505968'"
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
               </svg>

                All Reports
              </span>

            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                header-row-class-name="table-header-row"
                row-class-name="table-row"
            >


              <el-table-column
                  label="Title"
                  min-width="200"
              >
                <template slot-scope="scope">
                    <span class="fund-name">
                      {{ scope.row.title  }}
                    </span>
                </template>
              </el-table-column>

              <el-table-column
                  label="Type"
                  min-width="250"
              >
                <template slot-scope="scope">
                    <span class="investor-type-button text-capitalize">
                      {{ scope.row.type  }}
                    </span>
                </template>
              </el-table-column>


              <el-table-column
                  label="Date"
                  min-width="220"
              >
                <template slot-scope="scope">
                    <span class="fund-name">
                      {{ scope.row.date  }}
                    </span>
                </template>
              </el-table-column>

              <el-table-column
                  label="Status"
                  min-width="150"
              >
                <template slot-scope="scope">
                    <span class="investor-type-button text-capitalize">
                      {{ scope.row.status === 1 ? "Running" : "Stopped"  }}
                    </span>
                </template>


              </el-table-column>

              <el-table-column
                  label="Actions"
                  align="right"
                  header-align="center"
                  min-width="130"
              >

                <template slot-scope="scope">

                  <button
                      v-if="checkPermission('report_all','report_download')"
                      class="mini-button"
                      title="Download"
                      @click.prevent="downloadReport(scope.row.id,scope.row.title)"
                  >
                    <i class="fa-solid fa-download" style="color: #230B34"></i>
                  </button>

                  <button
                          v-if="checkPermission('report_all','report_delete')"
                          class="mini-button"
                          title="Delete"
                          @click.prevent="destroy(scope.row.id)">
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                            fill="#230B34"/>
                    </svg>
                  </button>
                </template>
              </el-table-column>

            </el-table>

            <div v-if="loading" class="text-center">
              <i class="el-icon-loading" style="font-size: 30px;"></i>
            </div>
            <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

          </el-tab-pane>

          <!--            <el-tab-pane name="Running Reports">-->
          <!--              <span slot="label">-->
          <!--                <svg style="margin-right: 5px; margin-top: -5px !important;" width="16" height="17" viewBox="0 0 16 17" fill="none"-->
          <!--                      xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path d="M4.6158 7.24121V12.0433"-->
          <!--                      :stroke="activeName === 'Running Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                <path d="M7.88253 4.94336V12.0433" :stroke="activeName === 'Running Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                <path d="M11.0958 9.77881V12.0433" :stroke="activeName === 'Running Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1358 1.5H4.57583C2.28917 1.5 0.855835 3.11846 0.855835 5.40961V11.5904C0.855835 13.8815 2.2825 15.5 4.57583 15.5H11.1358C13.4292 15.5 14.8558 13.8815 14.8558 11.5904V5.40961C14.8558 3.11846 13.4292 1.5 11.1358 1.5Z"-->
          <!--                      :stroke="activeName === 'Running Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                </svg>-->

          <!--                Running Reports-->
          <!--              </span>-->

          <!--              All Running Reports goes here-->

          <!--            </el-tab-pane>-->

          <!--            <el-tab-pane name="Stopped Reports">-->
          <!--              <span slot="label">-->
          <!--                <svg style="margin-right: 5px; margin-top: -5px !important;" width="16" height="17" viewBox="0 0 16 17" fill="none"-->
          <!--                      xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path d="M7.84762 11.3727V8.02856" :stroke="activeName === 'Stopped Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                <path d="M7.84762 5.6274H7.85518" :stroke="activeName === 'Stopped Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1355 1.5H4.57517C2.28901 1.5 0.855713 3.1187 0.855713 5.40941V11.5906C0.855713 13.8813 2.2822 15.5 4.57517 15.5H11.1347C13.4285 15.5 14.8557 13.8813 14.8557 11.5906V5.40941C14.8557 3.1187 13.4285 1.5 11.1355 1.5Z"-->
          <!--                      :stroke="activeName === 'Stopped Reports' ? '#4F31FF' : '#505968'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                </svg>-->

          <!--                Stopped Reports-->
          <!--              </span>-->

          <!--             All Stop Reports goes here-->
          <!--            </el-tab-pane>-->
        </el-tabs>
        <br><br>

      </div>
    </div>


    <investor-report-modal
        :open="openCreateInvestorReportModal"
        :disabled="btnDisabled"
        @close="openCreateInvestorReportModal = false"
        @cancel="openCreateInvestorReportModal = false"
        @submitted="createdInvestorReport"
    />

    <contact-report-modal
        :open="openCreateContactReportModal"
        :disabled="btnDisabled"
        @close="openCreateContactReportModal = false"
        @cancel="openCreateContactReportModal = false"
        @submitted="createdContactReport"
    />

    <document-report-modal
        :open="openCreateDocumentReportModal"
        :disabled="btnDisabled"
        @close="openCreateDocumentReportModal = false"
        @cancel="openCreateDocumentReportModal = false"
        @submitted="createdDocumentReport"
    />

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteReport"
    />


  </div>
</template>

<script>
import Auth from "@/apis/Auth";
import InvestorReportModal from "@/components/reports/InvestorReportModal.vue";
import DocumentReportModal from "@/components/reports/DocumentReportModal.vue";
import ContactReportModal from "@/components/reports/ContactReportModal.vue";
import DeleteModal from "../../components/common/DeleteModal";
import Reports from "@/apis/Reports";
import {Notification} from "element-ui";


export default {
  name: "Reports",
  data() {
    return {
      activeName: 'All Reports',
      btnDisabled: false,
      openCreateInvestorReportModal: false,
      openCreateContactReportModal: false,
      openCreateDocumentReportModal: false,
      openDeleteModal: false,
      tableData: [],
      permissions: [],
      reportId: null,
      pagination: {
        total_page: 1,
        per_page: 10,
      },
      page: 1,
      lastPage: 1,
      loading: false,
    };
  },
  components: {InvestorReportModal, ContactReportModal, DocumentReportModal,DeleteModal},

  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getReports();
  },
  methods: {
    async getPermissions(){
      await Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch((error)=>{
        console.log(error)
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions
      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;

    },

    getReports(){
      this.loading = true;
      Reports.index(this.page).then((res)=>{

        let reports = res.data.data;
        this.lastPage = res.data.meta.last_page;

        reports.forEach((report)=>{
          this.tableData.push({
            'id'         : report.id,
            'title'      : report.title,
            'type'       : report.type,
            'date'       : report.date,
            'status'     : report.status,
          })

        })

        this.loading = false;
      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },
    visibilityChanged(isVisible){
      if (!isVisible){return }

      if (this.page >= this.lastPage){return }

      this.page++
      this.getReports();
    },

    destroy(id) {
      this.openDeleteModal = true;
      this.reportId = id;
    },
    deleteReport(){
      Reports.destroy(this.reportId).then(() => {

        this.openDeleteModal = false;
        this.$notify({
          title: 'Success',
          message: 'Report Deleted Successfully.',
          type: 'success'
        });

        this.tableData = [];
        this.page = 1;
        this.getReports();

      }).catch(() => {
        this.$notify({
          title: 'Error',
          message: 'Report Deleted Failed.',
          type: 'error'
        });
      });
    },

    createdInvestorReport(){
      this.openCreateInvestorReportModal = false;
      this.tableData = [];
      this.page = 1;
      this.getReports();
    },
    createdContactReport(){
      this.openCreateContactReportModal = false;
      this.tableData = [];
      this.page = 1;
      this.getReports();
    },
    createdDocumentReport(){
      this.openCreateDocumentReportModal = false;
      this.tableData = [];
      this.page = 1;
      this.getReports();
    },

    downloadReport(id,title){

      Reports.download(id).then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download",  title+".xlsx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);

      }).catch(()=>{
        Notification.error('Something went wrong!');
      })
    },

  }
}
</script>

<style scoped>
.fund-name{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}


</style>