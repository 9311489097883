<template>
  <div class="row">
    <div class="fund-selection-container">
      <h2 class="fund-title">Select Funds</h2>
      <h5 class="fund-sub-title">Available Funds</h5>
      <div class="available-fund-card ">
        <div class="available-fund-card-header">
          <input type="text" v-model="keyword" class="form-control"
                 placeholder="Search Funds">
        </div>
        <div class="available-fund-card-body">
          <div v-if="filteredFundList.length > 0">
            <div class="fund-item" v-for="(fund) in filteredFundList"
                 :key="fund.id"
                 :class="{'selected-item' : selectedIndexes.includes(fund.id)}"
                 @click="selectFund(fund.id)">
              <div class="fund-name">{{ fund.name }}</div>
              <div class="fund-id">(ID {{ fund.uid }})</div>
            </div>
          </div>
          <div v-else>
            <h6 class="text-muted text-center text-capitalize">Fund not available</h6>
          </div>
        </div>

      </div>
      <h5 class="fund-sub-title">Selected Funds*</h5>
      <div class="selected-fund-card">
        <div class="selected-fund-card-header">
          <div>Selected Funds</div>
        </div>
        <div class="selected-fund-card-body">
          <div v-if="selectedFunds.length > 0">
            <div v-for="fund in selectedFunds" :key="fund.id"
                 class="selected-fund-item">
              <div class="d-flex justify-content-between gap-3">
                <div>
                  <div class="selected-fund-name">{{ fund.name }}</div>
                  <div class="selected-fund-id">(ID {{ fund.uid }})</div>
                </div>
                <div class="d-flex align-items-center">
                  <svg @click.prevent="removeFund(fund.id)"
                       style="cursor: pointer;"
                       width="23"
                       height="23"
                       viewBox="0 0 23 23"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#F3F3F3"/>
                    <path d="M14.526 14.526L8.47339 8.47339M14.526 8.47339L8.47339 14.526" stroke="#505968" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h6 class="text-muted text-center text-capitalize">Fund not selected yet</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-4 text-right">
      <button
          type="button"
          @click="back()"
          class="common-button"
      >
        Back
      </button>
      <button
          type="button"
          class="common-button mx-2"
          @click="saveAndClose()"
      >
        Save & Close
      </button>
      <button
          type="button"
          class="cancel-button common-button"
          @click="next()"
      >
        Next
      </button>
    </div>
  </div>
</template>
<script>
import Fund from "../../../apis/Fund";
import Document from "../../../apis/Document";
export default {
  name: "FundSelection",
  data() {
    return {
      keyword: "",
      fundsList: [],
      selectedIndexes: [],
      selectedFund: {},
      selectedFunds: [],
      currentStep: 2,
      newDocument: {},
    };
  },
  props: {
    document: Object,
  },
  created() {

    Fund.listWithUid().then((res) => {

      this.fundsList = res.data.data;

      this.document.funds.forEach((fund)=>{
        this.selectedFunds.unshift(fund);
        this.selectedIndexes.push(fund.id);
      })

      //remove selected funds from fundList
      // this.fundsList = res.data.data.filter(fund =>
      //     !this.selectedFunds.some(selectFund => fund.id === selectFund.id));

    });

  },
  mounted() {
    this.newDocument = { ...this.document };
  },
  computed: {
    filteredFundList() {
      return this.fundsList.filter((fund) => {
        return this.keyword
            .toLowerCase()
            .split(" ")
            .every((v) => fund.name.toLowerCase().includes(v));
      });
    },
  },
  methods: {
    selectFund(id){
      if (!this.selectedIndexes.includes(id)) {
        this.selectedIndexes.push(id);
        let filteredFund = this.filteredFundList.filter((fund) => id === fund.id)

        filteredFund.forEach((fund)=>{
          this.selectedFunds.push(fund)
        })

      } else {
        this.selectedIndexes.splice(this.selectedIndexes.indexOf(id), 1);

        const idToRemove = this.selectedFunds.findIndex(item => item.id === id);

        if (idToRemove !== -1) {
          this.selectedFunds.splice(idToRemove, 1);
        }

      }

    },
    removeFund(id){
      this.selectedIndexes.splice(this.selectedIndexes.indexOf(id), 1);

      const idToRemove = this.selectedFunds.findIndex(item => item.id === id);

      if (idToRemove !== -1) {
        this.selectedFunds.splice(idToRemove, 1);
      }
    },
    saveAndClose() {
      this.handleSubmit();
      this.$emit("cancel", true);
    },
    next() {
      this.handleSubmit("next");
    },
    back() {
      this.$emit("back");
    },
    handleSubmit(type) {
      type = type ? type : "";
      this.newDocument.fund_ids = this.selectedFunds.map((a) => a.id);
      Document.update(this.newDocument.id,this.newDocument)
        .then((res) => {          
          this.$emit("submitted", res.data.data);
          this.$notify({
            title: "Success!",
            message: "Funds successfully updated.",
            type: "success",
          });
          this.loading = false;
          if (type === "next") {
            this.$emit("next");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.formErrors(error.response.data, this.$notify.error);
          } else {
            this.errorMessage(error);
          }
          this.loading = false;
        });
    },
  },

};
</script>
<style scoped>

.fund-title{
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #092C4C;
  margin-bottom: 20px;
}

.fund-sub-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.available-fund-card{
  box-sizing: border-box;
  width: 100%;
  min-height: 100px;
  max-height: 340px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 15px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.available-fund-card-header{
  width: 100%;
  height: 46px;
  background: #FAFAFA;
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.form-control{
  border: none !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px;
  padding: 0 !important;
  background-color: #FAFAFA !important;
}

.form-control:focus{
  border: none !important;
  border-color: #FAFAFA;
  box-shadow: none !important;
}
.form-control::placeholder{
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: #092C4C;
  opacity: 0.7;
}

.available-fund-card-body{
  padding: 10px;
}

.fund-item{
  margin-bottom: 6px;
  padding: 5px;
  cursor: pointer;
}

.fund-name{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.fund-id{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505968;
}

.selected-item{
  background: #F3F3F3;
  border-radius: 10px;
}

.selected-fund-card{
  box-sizing: border-box;
  width: 100%;
  min-height: 100px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 15px;
}

.selected-fund-card-header{
  width: 100%;
  height: 46px;
  background: #FAFAFA;
  border-radius: 15px 15px 0 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.selected-fund-card-body{
  padding: 10px;
}

.selected-fund-item{
  width: auto;
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #F3F3F3;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.selected-fund-name{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.selected-fund-id{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505968;
  opacity: 0.7;
}


</style>
