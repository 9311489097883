
export const setUser = ({commit}, user) => {
    localStorage.setItem('user', JSON.stringify(user));
    commit('SET_USER', user)
}

export const getPermissions = ({commit},permissions) => {
    localStorage.setItem('permissions', JSON.stringify(permissions));
    commit('SET_PERMISSIONS', permissions)
}


export const setToken = ({commit}, token) => {
    localStorage.setItem('token', token);
    commit('SET_TOKEN', token)
}

export const setTokenExpiration = ({commit}, expiration) => {
    localStorage.setItem('tokenExpiration', expiration);
    commit('SET_TOKEN_EXPIRATION', expiration)
}

export const logout = ({commit}) => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    commit('LOGOUT')
}