<template>
  <div class="container-fluid p-0">
    <div class="d-flex justify-content-between mb-3">
      <h1 class="list-title">Templates</h1>
      <div>

        <el-button
            @click.prevent="$router.go(-1)"
            class="back-button"
        ><i class="fa fa-arrow-alt-circle-left"></i> Back
        </el-button>
      </div>
    </div>
    <p style="border-bottom: 1px solid #E6E8EB;"></p>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5">
        <div class="search-card">
          <div class="input-group">
            <span class="input-group-text">
              <svg style="margin-top: -3px; margin-left: 5px;" width="16"
                   height="16"
                   viewBox="0 0 16 16"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12.7615C10.3137 12.7615 13 10.1286 13 6.88075C13 3.6329 10.3137 1 7 1C3.68629 1 1 3.6329 1 6.88075C1 10.1286 3.68629 12.7615 7 12.7615Z" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.001 14.7217L12.001 11.7814" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <input @keyup.prevent="searchTemplate" v-model="search" type="text" class="form-control" placeholder="Search">
            <span class="input-group-text">
               <svg  style="margin-right: 5px;" width="14" height="11"
                     viewBox="0 0 14 11"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">-->
                 <path d="M0 0.84C0 0.617218 0.0884998 0.403561 0.24603 0.24603C0.403561 0.0884996 0.617218 0 0.84 0H13.16C13.3828 0 13.5964 0.0884996 13.754 0.24603C13.9115 0.403561 14 0.617218 14 0.84C14 1.06278 13.9115 1.27644 13.754 1.43397C13.5964 1.5915 13.3828 1.68 13.16 1.68H0.84C0.617218 1.68 0.403561 1.5915 0.24603 1.43397C0.0884998 1.27644 0 1.06278 0 0.84ZM2.24 5.32C2.24 5.09722 2.3285 4.88356 2.48603 4.72603C2.64356 4.5685 2.85722 4.48 3.08 4.48H10.92C11.1428 4.48 11.3564 4.5685 11.514 4.72603C11.6715 4.88356 11.76 5.09722 11.76 5.32C11.76 5.54278 11.6715 5.75644 11.514 5.91397C11.3564 6.0715 11.1428 6.16 10.92 6.16H3.08C2.85722 6.16 2.64356 6.0715 2.48603 5.91397C2.3285 5.75644 2.24 5.54278 2.24 5.32ZM4.48 9.8C4.48 9.57722 4.5685 9.36356 4.72603 9.20603C4.88356 9.0485 5.09722 8.96 5.32 8.96H8.68C8.90278 8.96 9.11644 9.0485 9.27397 9.20603C9.4315 9.36356 9.52 9.57722 9.52 9.8C9.52 10.0228 9.4315 10.2364 9.27397 10.394C9.11644 10.5515 8.90278 10.64 8.68 10.64H5.32C5.09722 10.64 4.88356 10.5515 4.72603 10.394C4.5685 10.2364 4.48 10.0228 4.48 9.8Z" fill="#092C4C"/>
               </svg>
            </span>
          </div>

        </div>

        <div v-if="templates.length > 0">
          <div class="mini-template-card" v-for="template in templates"
               :key="template.id">
            <div class="row">
              <div class="col-10">
                <div>
                  <span @click.prevent="editTemplate(template)"
                        class="template-name">{{
                      template.name }}</span>
                  <span class="template-creator">(Created by {{template.created_by}})</span>
                </div>
              </div>
              <div class="col-2 text-right">
                <button
                    v-if="checkPermission('template_all','template_delete')"
                    @click.prevent="destroy(template.id)"
                    class="delete-button"
                >
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                          fill="#230B34"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="template-create-time">{{ template.created_at}}</div>
            <div class="subject-section">
              <span class="subject">Subject :</span>
              <span class="subject-text"> {{ template.subject}}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <h3 class="text-danger text-center">No Templates Found!</h3>
        </div>


      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-7">
        <div class="template-create-card">
          <el-form
              ref="form"
              class="template-form"
              :model="formData"
              :rules="rules"
              label-position="top"
              label-width="120px"
          >

            <div class="row">
              <div class="col-md-12">
                <el-form-item label="Email template name" prop="name">
                  <el-input
                      v-model="formData.name"
                      placeholder="Enter email template name here"
                  />
                </el-form-item>
              </div>
            </div>
            <p style="border-top: 1px solid #092C4C;opacity: 0.1;margin: 5px 0 25px"></p>

            <div class="row">
              <div class="col-md-12">
                <el-form-item prop="subject">
                  <el-input
                      v-model="formData.subject"
                      placeholder="Subject"
                  />
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">

               <div class="clearfix mb-2">
                  <span class="dropdown float-end">
                  <span class="dropdown-toggle dropdown-button" type="button"
                          id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <i class="el-icon-price-tag"></i>
                    Add Tag
                  </span>
                  <ul class="dropdown-menu mt-5"
                      aria-labelledby="dropdownMenuClickableInside">
                    <li class="dropdown-item">
                      <a @click.prevent="addTag('$first_name')">First Name</a>
                    </li>
                    <li class="dropdown-item">
                       <a @click.prevent="addTag('$last_name')">Last Name</a>
                    </li>
                    <li class="dropdown-item">
                       <a @click.prevent="addTag('$portal_link')">Portal Link</a>
                    </li>
                  </ul>
              </span>
               </div>

<!--                <textarea id="text1" cols="70"  rows="10"-->
<!--                          v-model="formData.body"></textarea>-->
                <el-form-item prop="body">
                  <ckeditor
                      :editor="editor"
                      v-model="formData.body"
                      :config="editorConfig"
                      @ready="onEditorReady"
                  />
                </el-form-item>

                <el-button
                    @click="handleSubmit('form')"
                    :loading="buttonLoading"
                    :disabled="disabled"
                    class="common-button custom-position">
                  Save
                </el-button>
              </div>
            </div>

          </el-form>

        </div>
      </div>
    </div>

    <delete-modal
        :open="openDeleteModal"
        :disabled="btnDisabled"
        @close="openDeleteModal = false"
        @confirm="deleteTemplate"
    />

  </div>
</template>



<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Template from "@/apis/Template";
import {Notification} from 'element-ui';
import Auth from "@/apis/Auth";
import DeleteModal from "../../components/common/DeleteModal";
import _ from 'lodash'



export default {
  name: "TemplateList",
  data(){
    return{
      editor: ClassicEditor,
      editorConfig: {
        placeholder: 'Type message here....',
      },
      ckeditor: null,
      search: '',
      formData: {
        name: '',
        subject: '',
        body: '',
      },
      rules: {
        name: {
          required: true,
          message : 'Template Name is required',
          type: 'string'
        },
        subject: {
          required: true,
          message : 'Subject is required',
          type: 'string'
        },
        body: {
          required: true,
          message : 'Template Description is required',
        },
      },
      templates: [],
      permissions: [],
      templateId: null,
      openDeleteModal: false,
      btnDisabled: false,
      buttonLoading: false,
      loading: false,
      disabled: false

    }
  },

  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getTemplates();
  },
  components: {DeleteModal},
  methods: {
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    checkPermission(allPermission,specificPermission){


      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;


    },

    getTemplates(){

      Template.index(this.search).then((res)=>{
        this.templates = res.data.data;
        this.loading = false;
      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },

    searchTemplate:_.debounce(function (){
      this.getTemplates()
    },1000),

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.disabled = true;
          if (this.templateId === null){
            this.createTemplate();
          }else {
            this.callEditApi()
          }

        } else {
          return false;
        }
      });
    },

    onEditorReady( editor ){
      this.ckeditor = editor
    },

    addTag(tag){
      this.ckeditor.model.change( writer => {
        const insertPosition = this.ckeditor.model.document.selection.getFirstPosition();
        writer.insertText(tag, insertPosition);
      });

      // let currentInput = document.getElementById('text1');
      //
      // let cursorPos = currentInput.selectionStart;
      // let v = currentInput.value;
      // let textBefore = v.substring(0,  cursorPos );
      // let textAfter  = v.substring( cursorPos, v.length );
      // currentInput.value = textBefore + tag + textAfter;
      //
      // cursorPos += v.length;
      // currentInput.focus();
      // currentInput.setSelectionRange(cursorPos, cursorPos);
    },

    createTemplate(){
      Template.store(this.formData).then(()=>{
        this.$notify({
          title: "Success!",
          message: "Template Created Successfully.",
          type: "success",
        });
        // this.clearForm();
        this.getTemplates();
        this.buttonLoading = false;
        this.disabled = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.buttonLoading = false;
        this.disabled = false;
      })

    },

    editTemplate(template){
      this.templateId = template.id;
      this.formData.name = template.name;
      this.formData.subject = template.subject;
      this.formData.body = template.body;
    },

    callEditApi(){
      Template.update(this.templateId,this.formData).then(()=>{
        this.$notify({
          title: "Success!",
          message: "Template Updated Successfully.",
          type: "success",
        });
        // this.clearForm();
        this.getTemplates();
        this.buttonLoading = false;
        this.disabled = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.buttonLoading = false;
        this.disabled = false;
      })
    },

    destroy(id) {
      this.openDeleteModal = true;
      this.templateId = id;
    },

    deleteTemplate(){
      Template.destroy(this.templateId).then(() => {

        this.openDeleteModal = false;
        this.$notify({
          title: 'Success',
          message: 'Template Deleted Successfully.',
          type: 'success'
        });

        this.getTemplates();

      }).catch(() => {
        Notification.error("Something went wrong!");
        this.loading = false;
      });
    },

    clearForm(){
      this.templateId = null;
      this.formData.name = '';
      this.formData.subject = '';
      this.formData.body = '';
    }
  },

}
</script>

<style scoped>
.search-card{
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
}

.input-group{
  height: 100%;
}
.input-group-text{
  background-color: #fff !important;
  border: none !important;
}

.form-control{
  border: none !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px;
  padding: 0 !important;
}
.form-control:focus{
  border: none !important;
  border-color: #ffffff;
  box-shadow: none !important;
}
.form-control::placeholder{
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px;
  color: #101136;
  opacity: 0.5;
}


.mini-template-card{
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  margin-top: 10px;
  padding: 10px 15px;
}

.delete-button{
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 50%;
  text-align: center;
  vertical-align: center;
}

.template-name{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #092C4C;
}

.template-name:hover{
  cursor: pointer;
  color: #0a53be;
  text-decoration: underline;
}

.template-creator{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  opacity: 0.8;
}

.template-create-time{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505968;
}

.subject-section{
  margin-top: 12px;
}

.subject{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #505968;
}

.subject-text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #092C4C;
}

.template-create-card{
  box-sizing: border-box;
  width: 100%;
  height: 825px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0 8px 20px 2px rgba(9, 21, 42, 0.03);
  border-radius: 15px;
  padding: 25px;
}

.custom-position{
  float: right;
}

.dropdown-button{
  width: 132px;
  height: 39px;
  background: #FAFAFA;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>