<template>
    <div class="container-fluid p-0 mt-n1 contact-container">

        <div
            class="title-section mb-2">
            <h1 class="list-title ">Contacts List
              <el-popover
                  placement="top"
                  width="240"
                  trigger="hover"
                  content="Press keyboard <- and -> to scroll horizontally">
                <i  slot="reference" class="el-icon-info"></i>
              </el-popover>

            </h1>

            <div>
              <span style="margin-right: 10px;">
              <span class="dropdown">
                <button class="dropdown-button dropdown-toggle" type="button"
                        id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  Columns
                </button>
                <ul class="dropdown-menu dropdown-menu-box mt-5"
                    aria-labelledby="dropdownMenuClickableInside">
                  <div class="dropdown-menu-header dropdown-menu-header-custom">
                    <div class="d-flex justify-content-between">
                      All
                      <el-switch
                          @change="handleAllColumnChange"
                          v-model="tableColumn.all"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </div>

                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Name
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.name"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Company
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.company"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Last Login
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.last_login"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Investors
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.investors"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Role
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.role"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Last Update
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.last_update"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                  <li class="dropdown-item dropdown-item-custom">
                    <div class="d-flex justify-content-between">
                      Action
                      <el-switch
                          @change="handleSingleColumnChange"
                          v-model="tableColumn.action"
                          active-color="#4F31FF"
                          inactive-color="#E5E5E5">
                      </el-switch>
                    </div>
                  </li>
                </ul>
              </span>
            </span>

                <button class="common-button" v-if="checkPermission('contact_all','contact_create')"
                        @click.prevent="openAddNewModal = true">
                  <i class="fa fa-plus"></i>
                  Add New Contact
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card rounded-3 shadow-none">
                    <div class="card-body investor-card">

                       <span v-if="tableColumn.name === false &&
                          tableColumn.company === false &&
                          tableColumn.last_login === false &&
                          tableColumn.investors === false &&
                          tableColumn.role === false &&
                          tableColumn.last_update === false &&
                          tableColumn.action === false">
                        </span>
                        <el-table v-else
                                  ref="multipleTable"
                                  :data="tableData"
                                  style="width: 100%"
                                  header-row-class-name="table-header-row"
                                  row-class-name="table-row"
                                  v-loading="loading"
                        >
                          <el-table-column
                              label="Name"
                              min-width="250"
                              v-if="tableColumn.name"
                          >
                            <template slot-scope="scope">
                                <span
                                    v-if="checkPermission('contact_all','contact_edit')"
                                    @click.prevent="viewContact(scope.row.id)"
                                    class="contact-name contact-overview"> {{
                                    scope.row.first_name+' '+scope.row.last_name }}
                                </span>

                              <span v-else class="contact-name"> {{ scope.row.first_name+' '+scope.row.last_name }}</span>

                            </template>

                          </el-table-column>

                          <el-table-column
                              label="Company"
                              min-width="250"
                              v-if="tableColumn.company"
                          >
                            <template slot-scope="scope">
                                <span class="contact-des">
                                  {{ scope.row.company }}
                                </span>
                            </template>
                          </el-table-column>
                          <el-table-column
                              label="Last Login"
                              min-width="200"
                              v-if="tableColumn.last_login"
                          >
                            <template slot-scope="scope">
                                  <span class="contact-des"
                                        v-if="scope.row.last_login_at !==null">
                                      {{  scope.row.last_login_at | date  }}
                                  </span>
                              <span v-else></span>

                            </template>
                          </el-table-column>

                          <el-table-column
                              label="Investors"
                              min-width="150"
                              v-if="tableColumn.investors"
                          >
                            <template slot-scope="scope">
                                <span class="contact-des">
                                  {{ scope.row.investors }}
                                </span>
                            </template>

                          </el-table-column>

                          <el-table-column
                              label="Role"
                              min-width="200"
                              v-if="tableColumn.role"
                          >
                            <template slot-scope="scope">
                               <span class="investor-type-button">
                                  {{ scope.row.user_role_name ?
                                   scope.row.user_role_name : 'N/A'  }}
                               </span>

                            </template>
                          </el-table-column>

                          <el-table-column
                              label="Last Update"
                              min-width="200"
                              v-if="tableColumn.last_update"
                          >
                            <template slot-scope="scope">
                              <span class="contact-des" v-if="scope.row.updated_at !==
                              null">
                                {{ scope.row.updated_at | date }}
                              </span>

                            </template>
                          </el-table-column>


                          <el-table-column
                              align="right"
                              header-align="center"
                              width="220"
                              label="Actions"
                              v-if="tableColumn.action"
                          >
                            <template slot-scope="scope">
                              <router-link :to="`/contact-access-rights?uid=${scope.row.id}`" class="me-2">
                                <button title="Access Rights"
                                        class="mini-button"
                                        v-if="scope.row.user_role_type === 'general'"
                                >
                                  <i style="color: #230B34" class="el-icon-s-tools"></i>
                                </button>
                              </router-link>

                              <button
                                  v-if="checkPermission('contact_all','contact_invite')"
                                  class="mini-button"
                                  @click="invite(scope.row.id,scope.row.first_name,scope.row.last_name)"
                              >
                                <i style="color: #230B34" class="el-icon-s-promotion"></i>
                              </button>

                              <button
                                  v-if="checkPermission('contact_all','contact_edit')"
                                  class="mini-button"
                                  @click="edit(scope.row.id)"
                              >
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.91875 10.339L9.21498 3.48995C9.50282 3.12061 9.60517 2.6936 9.50922 2.25881C9.42607 1.86355 9.183 1.48773 8.81841 1.20262L7.9293 0.496331C7.15534 -0.11924 6.19587 -0.0544431 5.64578 0.651844L5.05091 1.42358C4.97416 1.52012 4.99335 1.66268 5.08929 1.74043C5.08929 1.74043 6.59245 2.94566 6.62443 2.97158C6.72678 3.06877 6.80353 3.19836 6.82272 3.35388C6.8547 3.65842 6.64362 3.94353 6.3302 3.98241C6.18308 4.00185 6.04236 3.95649 5.94002 3.87225L4.3601 2.61519C4.28334 2.55752 4.16821 2.56983 4.10424 2.64759L0.349543 7.50736C0.106479 7.81191 0.0233251 8.20717 0.106479 8.58947L0.58621 10.6695C0.611796 10.7796 0.707742 10.8574 0.822878 10.8574L2.9337 10.8314C3.31748 10.825 3.67568 10.65 3.91875 10.339ZM6.87434 9.69136H10.3163C10.6521 9.69136 10.9252 9.96804 10.9252 10.3082C10.9252 10.6491 10.6521 10.9251 10.3163 10.9251H6.87434C6.53853 10.9251 6.26541 10.6491 6.26541 10.3082C6.26541 9.96804 6.53853 9.69136 6.87434 9.69136Z"
                                        fill="#230B34"/>
                                </svg>
                              </button>

                              <button
                                  v-if="checkPermission('contact_all','contact_delete')"
                                  class="mini-button"
                                  @click="destroy(scope.row.id)"
                              >
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                                        fill="#230B34"/>
                                </svg>
                              </button>
                            </template>
                          </el-table-column>

                        </el-table>

                      <div v-if="tableData.length" v-observe-visibility="visibilityChanged"></div>

                    </div>
                </div>
            </div>
        </div>

        <add-new-modal
            :open="openAddNewModal"
            :disabled="btnDisabled"
            @close="openAddNewModal = false"
            @cancel="openAddNewModal = false"
            @submitted="addedNewContact"
            ref="addNewModal"
        />
        <edit-modal
            :open="openEditModal"
            :disabled="btnDisabled"
            @close="openEditModal = false"
            @cancel="openEditModal = false"
            @submitted="editedContact"
            ref="editModal"
        />

        <delete-modal
            :open="openDeleteModal"
            :disabled="btnDisabled"
            @close="openDeleteModal = false"
            @confirm="deleteContact"
        />

    </div>
</template>

<script>
import DeleteModal from "../../components/common/DeleteModal";
import AddNewModal from "../../components/contact/AddNewModal";
import EditModal from "../../components/contact/EditModal";
import Contact from "../../apis/Contact"
import Account from "@/apis/Account";
import Tag from "../../apis/Tag";
import Auth from "@/apis/Auth";
import {mapGetters} from "vuex";
import {Notification} from "element-ui";

export default {
    name: "Index",
    components: {EditModal, AddNewModal, DeleteModal},
    computed: {
        ...mapGetters('auth', ['isSuperAdmin', 'isAdmin', 'isUser']),
    },
    data() {
        return {
            openDeleteModal: false,
            btnDisabled: false,
            openAddNewModal: false,
            openEditModal: false,

          tableColumn: {
            all: true,
            name: true,
            company: true,
            last_login: true,
            investors: true,
            role: true,
            last_update: true,
            action: true,
          },

            permissions: [],
            tableData: [],
            page: 1,
            lastPage: 1,
            deleteContactId: 0,
            loading: true,
        }
    },

    created() {
      this.getPermissions();
      this.getContacts();
    },
    mounted() {
      this.checkPermission();

      setTimeout(()=>{
        this.$message({
          showClose: true,
          duration: 7000,
          message: 'Press keyboard <- and -> to scroll horizontally',
          type: 'warning'
        });
      },2000)

    },
    methods: {
      handleAllColumnChange(){
        if (this.tableColumn.all === true){
          this.tableColumn.name = true
          this.tableColumn.company = true
          this.tableColumn.last_login = true
          this.tableColumn.investors = true
          this.tableColumn.role = true
          this.tableColumn.last_update = true
          this.tableColumn.action = true

        }else {
          this.tableColumn.name = false
          this.tableColumn.company = false
          this.tableColumn.last_login = false
          this.tableColumn.investors = false
          this.tableColumn.role = false
          this.tableColumn.last_update = false
          this.tableColumn.action = false
        }
      },
      handleSingleColumnChange(){
        this.tableColumn.all =
            this.tableColumn.name === true &&
            this.tableColumn.company === true &&
            this.tableColumn.last_login === true &&
            this.tableColumn.investors === true &&
            this.tableColumn.role === true &&
            this.tableColumn.last_update === true &&
            this.tableColumn.action === true;
      },

      async getContacts(){
        // this.loading = true;
        await Contact.index(this.page).then((res)=>{

          let contacts = res.data.data;

          this.lastPage = res.data.meta.last_page;

          contacts.forEach((contact)=>{
            this.tableData.push({
              'id' : contact.id,
              'first_name' : contact.first_name,
              'last_name' : contact.last_name,
              'company' : contact.company,
              'email' : contact.email,
              'user_role' : contact.user_role,
              'investors' : contact.investors,
              'status' : contact.status,
              'user_role_type' : contact.user_role_type,
              'user_role_name' : contact.user_role_name,
              'last_login_at' : contact.last_login,
              'updated_at' : contact.updated_at
            })
          })
          this.loading = false;

        }).catch(()=>{
          Notification.error("Something went wrong!");
          this.loading = false;
        });
      },
      visibilityChanged(isVisible){
        if (!isVisible){return }

        if (this.page >= this.lastPage){return }

        this.page++
        this.getContacts();
      },
        roleMap(role) {
            switch (role) {
                case 'superadmin':
                    return 'Super Admin';
                case 'admin':
                    return 'General';
                case 'user':
                    return 'Investor';
                default:
                    return 'N/A';
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        statusBtnType(status) {
            if (status === 'active') {
                return 'success';
            } else if (status === 'inactive') {
                return 'warning';
            } else if (status === 'removed') {
                return 'danger';
            }
        },
        statusBtnClass(status) {
            if (status === 'active') {
                return 'status-btn-success';
            } else if (status === 'inactive') {
                return 'status-btn-warning';
            } else if (status === 'removed') {
                return 'status-btn-danger';
            }
        },
        destroy(id) {
            this.deleteContactId = id;
            this.openDeleteModal = true;
        },
        deleteContact() {
            Contact.destroy(this.deleteContactId)
                .then(response => {
                    this.openDeleteModal = false;
                    this.$notify({
                        title: 'Success',
                        message: response.data.message,
                        type: 'success'
                    });
                    // this.table.refresh();
                  this.tableData = [];
                  this.page = 1;
                  this.getContacts()
                })
                .catch(() => {
                  Notification.error("Something went wrong!");
                  this.loading = false;
                });
        },
        addedNewContact(data) {
            this.openAddNewModal = false;
            this.$notify({
                title: 'Success',
                message: data.message,
                type: 'success'
            });
            // this.table.refresh();
          this.tableData = [];
          this.page = 1;
          this.getContacts()
        },
        edit(id) {
            this.openEditModal = true;
            this.$refs.editModal.loading = true;

            Contact.show(id)
                .then(response => {

                    let data = response.data;

                    this.$refs.editModal.id = data.id;
                    this.$refs.editModal.selectedRole.type = data.role;

                    this.$refs.editModal.formData.first_name = data.first_name;
                    this.$refs.editModal.formData.last_name = data.last_name;
                    this.$refs.editModal.formData.email = data.email;
                    this.$refs.editModal.formData.phone_number = data.phone_number;
                    this.$refs.editModal.formData.role = data.role;
                    this.$refs.editModal.formData.company = data.company;
                    this.$refs.editModal.formData.tags = data.tags.map(tag => tag.name);
                    this.$refs.editModal.formData.committed = data.committed;



                    data.accounts.forEach((account,index)=>{
                        Account.fundList(account.id).then((res)=>{
                          this.$refs.editModal.formData.accounts = data.accounts.map(account=>account);
                          this.$refs.editModal.formData.accounts[index].allFunds = res.data;
                        }).catch((error)=>{
                          console.log(error)
                        })
                    })

                    this.$refs.editModal.loading = false;

                })
                .catch(() => {
                  Notification.error("Something went wrong!");
                  this.$refs.editModal.loading = false;
                });
        },
        editedContact() {
            this.openEditModal = false;
            this.$notify({
                title: 'Success',
                message: 'Contact updated successfully.',
                type: 'success'
            });
            // this.table.refresh();
          this.tableData = [];
          this.page = 1;
          this.getContacts()
        },
        invite(id,fname,lname) {
          this.$confirm(`Are you sure you want to send an invitation to ${fname} ${lname}?`,
              'Warning', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'common-button',
            cancelButtonClass: 'cancel-button common-button',
            type: 'warning'
          }).then(() => {
            Contact.invite(id).then(response => {
                    this.$notify({
                        title: 'Success',
                        message: response.data.message,
                        type: 'success'
                    });
                })
                .catch(error => {
                  Notification.error("Something went wrong!");
                  this.loading = false;
                });
          }).catch(() => {});

        },
        getTags() {
            Tag.index()
                .then(response => {
                    let tags = response.data.data;
                    this.$refs.addNewModal.tagOptions = tags;
                    this.$refs.editModal.tagOptions = tags;
                })
                .catch(error => {
                  Notification.error("Something went wrong!");
                  this.loading = false;
                });
        },

      getPermissions(){
        Auth.userPermissions().then((res)=>{
          this.permissions = res.data.permissions;
        }).catch(()=>{
          Notification.error("Something went wrong!");
        })
      },

      checkPermission(allPermission,specificPermission){


        let userPermissions = this.permissions

        for (let key in userPermissions) {
          if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
            return true;
          }
        }
        return false;
      },

      viewContact(id){
        this.$router.push(`/contact/${id}`)
      },

    },

}
</script>

<style scoped>

.contact-name{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
}

.contact-overview:hover{
  cursor: pointer;
  color: #0a53be;
  text-decoration: underline;
}

.contact-des{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  opacity: 0.8;
}

</style>