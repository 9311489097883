<template>
  <div>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        class="mt-2 profile-form overflow-hidden"
        label-position="top"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <el-form-item label="Your Current Email" >
                <el-input
                    v-model="user.email"
                    type="email" placeholder="example@example.com"
                    readonly/>
              </el-form-item>
            </div>
            <div class="col-md-12">
              <el-form-item label="Enter your new email" prop="email">
                <el-input
                    v-model="formData.email"
                    type="email"
                    placeholder="example@example.com"/>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <el-button v-if="checkPermission('profile_all','profile_changeEmail')"
                     class="common-button mt-2"
                     type="success"
                     :loading="loading"
                     @click="handleSubmit('form')"
          >Change Email
          </el-button>
        </div>
      </div>


    </el-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import email from "@/store/email";
import Auth from "@/apis/Auth";
import Profile from "@/apis/Profile";
import {Notification} from "element-ui";

export default {
  name: "ChangeEmail",
  data(){
    return {
      formData: {
        email: '',
      },
      rules: {
        email: [
          {required: true, message: 'Please insert email address', trigger: 'blur'},
          {type: 'email', message: 'Please insert correct email address', trigger: ['blur', 'change']}
        ],
      },
      permissions: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },
    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;

    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callToBackendChangePasswordApi();
        } else {
          return false;
        }
      });
    },
    callToBackendChangePasswordApi() {

      this.loading = true;

      Profile.changeEmail(this.formData).then((res)=>{
        this.$store.dispatch('auth/setUser', res.data.data);
        this.$notify({
          title: "Success!",
          message: "Email Updated Successfully.",
          type: "success",
        });

        this.loading = false;

      }).catch((error)=>{

        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;

      })

    },
  },
}
</script>

<style scoped>

</style>