import Emails from "@/apis/Emails";


export const getEmails = (context)=>{
    Emails.index().then((res)=>{
        console.log(res.data.emails)
        context.commit('SET_EMAILS',res.data.emails);
    }).catch((error)=>{
        console.log(error)
    })
}

export const storeEmails = (formData) =>{
    Emails.store(formData).then((res)=>{
        return res;
    }).catch((error)=>{
        console.log(error)
    })
}