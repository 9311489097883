<template>
  <div class="sidebar-card card-padding">
    <div class="clearfix mb-3">
      <div class="float-start">
        <h5 class="user-analytics">User Analytics</h5>
      </div>
      <div class="float-end cursor-pointer">
        <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.96453e-05 17.7308V17.1837C0.0120897 17.1394 0.0322065 17.0992 0.0402532 17.0549C0.217281 15.9043 1.17484 14.995 2.29733 14.9185C3.55262 14.83 4.61478 15.5462 4.96079 16.7129C5.00505 16.8658 5.03724 17.0268 5.07747 17.1837V17.7308C5.0654 17.7671 5.04528 17.7992 5.04126 17.8395C4.86825 18.9016 4.10382 19.7103 3.05775 19.9396C2.97728 19.9557 2.89681 19.9799 2.81635 20H2.26917C2.23296 19.9879 2.19675 19.9678 2.16054 19.9638C1.11447 19.7948 0.317865 19.0505 0.0764434 18.0165C0.0523033 17.9159 0.0241401 17.8234 0 17.7308H1.96453e-05ZM5.07749 2.26513L5.07747 2.81234C5.0654 2.8566 5.04528 2.89683 5.04126 2.94109C4.86021 4.09982 3.89863 5.00507 2.76404 5.07749C1.51278 5.15796 0.454638 4.43375 0.112653 3.26296C0.0683965 3.11407 0.0362102 2.96121 0 2.81232V2.26515C0.0120701 2.22894 0.0321868 2.19675 0.0402335 2.16054C0.233354 1.11447 0.84088 0.430499 1.85476 0.104607C1.98754 0.0603503 2.12835 0.0362102 2.26515 0H2.81232C2.84853 0.0120701 2.88474 0.0321868 2.92095 0.0362102C3.97105 0.209214 4.76365 0.949511 5.00505 1.98351C5.02921 2.08007 5.05335 2.17261 5.07749 2.26513ZM5.07747 9.72444V10.2716C5.0654 10.3159 5.04528 10.3561 5.03724 10.4004C4.85618 11.5551 3.85839 12.4845 2.73186 12.5327C1.4645 12.5891 0.390265 11.8246 0.0885136 10.6458C0.0563268 10.5211 0.0281635 10.3963 0 10.2716V9.72444C0.0120701 9.68018 0.0321868 9.63995 0.0402335 9.59569C0.217261 8.44099 1.21907 7.51158 2.34561 7.45929C3.61297 7.39894 4.6872 8.1674 4.98896 9.34625C5.02114 9.47499 5.04931 9.59972 5.07747 9.72444Z" fill="#505968"/>
        </svg>
      </div>
    </div>

    <div class="text-center mx-auto d-flex justify-content-center ">
      <apexchart width="332" type="donut" :options="chartOptions"
                 :series="series"></apexchart>
    </div>

    <div class="clearfix" style="margin-top: 40px;">
      <div class="float-start">
        <h5 class="user-analytics">KPIs</h5>
      </div>
      <div class="float-end cursor-pointer">
        <svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.96453e-05 17.7308V17.1837C0.0120897 17.1394 0.0322065 17.0992 0.0402532 17.0549C0.217281 15.9043 1.17484 14.995 2.29733 14.9185C3.55262 14.83 4.61478 15.5462 4.96079 16.7129C5.00505 16.8658 5.03724 17.0268 5.07747 17.1837V17.7308C5.0654 17.7671 5.04528 17.7992 5.04126 17.8395C4.86825 18.9016 4.10382 19.7103 3.05775 19.9396C2.97728 19.9557 2.89681 19.9799 2.81635 20H2.26917C2.23296 19.9879 2.19675 19.9678 2.16054 19.9638C1.11447 19.7948 0.317865 19.0505 0.0764434 18.0165C0.0523033 17.9159 0.0241401 17.8234 0 17.7308H1.96453e-05ZM5.07749 2.26513L5.07747 2.81234C5.0654 2.8566 5.04528 2.89683 5.04126 2.94109C4.86021 4.09982 3.89863 5.00507 2.76404 5.07749C1.51278 5.15796 0.454638 4.43375 0.112653 3.26296C0.0683965 3.11407 0.0362102 2.96121 0 2.81232V2.26515C0.0120701 2.22894 0.0321868 2.19675 0.0402335 2.16054C0.233354 1.11447 0.84088 0.430499 1.85476 0.104607C1.98754 0.0603503 2.12835 0.0362102 2.26515 0H2.81232C2.84853 0.0120701 2.88474 0.0321868 2.92095 0.0362102C3.97105 0.209214 4.76365 0.949511 5.00505 1.98351C5.02921 2.08007 5.05335 2.17261 5.07749 2.26513ZM5.07747 9.72444V10.2716C5.0654 10.3159 5.04528 10.3561 5.03724 10.4004C4.85618 11.5551 3.85839 12.4845 2.73186 12.5327C1.4645 12.5891 0.390265 11.8246 0.0885136 10.6458C0.0563268 10.5211 0.0281635 10.3963 0 10.2716V9.72444C0.0120701 9.68018 0.0321868 9.63995 0.0402335 9.59569C0.217261 8.44099 1.21907 7.51158 2.34561 7.45929C3.61297 7.39894 4.6872 8.1674 4.98896 9.34625C5.02114 9.47499 5.04931 9.59972 5.07747 9.72444Z" fill="#505968"/>
        </svg>
      </div>
    </div>

    <div class="clearfix" style="margin-top: 30px;">
      <h6 class="current-user-title">Current Users</h6>
      <div class="float-start">
        <h3 class="current-user-text">{{ current_users }}</h3>
      </div>
      <div class="float-end">
        <div v-if="current_users_percentage > 0" class="ratio-button-increase">
          {{ current_users_percentage}}%
          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 7.8722L5.04085 3.69428L7.39916 5.25743C7.50196 5.32555 7.6256 5.35489 7.74815 5.34008C7.87055 5.32528 7.98378 5.26729 8.06739 5.17655L10.7577 2.25618L10.4981 4.23566C10.4754 4.41892 10.5516 4.60028 10.6983 4.71226C10.845 4.82425 11.0401 4.84989 11.2108 4.77957C11.3814 4.70937 11.5019 4.55365 11.5273 4.37093L11.9955 0.799381C12.0151 0.651329 11.9699 0.501918 11.8713 0.389518C11.7728 0.277255 11.6306 0.212832 11.4811 0.212969C11.4581 0.212969 11.435 0.214476 11.4123 0.217629L7.84212 0.685742C7.65938 0.710962 7.50366 0.83145 7.43348 1.00211C7.36316 1.17275 7.3888 1.36794 7.50079 1.51475C7.61262 1.66142 7.79412 1.73763 7.97725 1.71488L10.1019 1.43621L7.60413 4.14768L5.25908 2.59354C5.15901 2.52734 5.03907 2.49786 4.91968 2.51006C4.80029 2.5224 4.68885 2.57559 4.60427 2.66071L0.155445 7.14077C0.0563415 7.23796 0.000413468 7.37092 2.27228e-06 7.50964C-0.000408291 7.6485 0.0548328 7.78173 0.153389 7.87947C0.251795 7.97734 0.385441 8.03162 0.524159 8.03025C0.663003 8.02888 0.795415 7.97199 0.892052 7.8722Z" fill="#27AE60"/>
          </svg>
        </div>
        <div v-else class="ratio-button-decrease">
          {{  current_users_percentage | positiveNumber }}%

          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 0.371084L5.04085 4.54901L7.39916 2.98586C7.50196 2.91773 7.6256 2.8884 7.74815 2.9032C7.87055 2.91801 7.98378 2.97599 8.06739 3.06673L10.7577 5.98711L10.4981 4.00763C10.4754 3.82436 10.5516 3.64301 10.6983 3.53102C10.845 3.41903 11.0401 3.3934 11.2108 3.46372C11.3814 3.53392 11.5019 3.68963 11.5273 3.87235L11.9955 7.44391C12.0151 7.59196 11.9699 7.74137 11.8713 7.85377C11.7728 7.96603 11.6306 8.03045 11.4811 8.03032C11.4581 8.03032 11.435 8.02881 11.4123 8.02566L7.84212 7.55754C7.65938 7.53232 7.50366 7.41184 7.43348 7.24118C7.36316 7.07054 7.3888 6.87534 7.50079 6.72854C7.61262 6.58187 7.79412 6.50565 7.97725 6.52841L10.1019 6.80708L7.60413 4.0956L5.25908 5.64974C5.15901 5.71595 5.03907 5.74542 4.91968 5.73322C4.80029 5.72088 4.68885 5.6677 4.60427 5.58258L0.155445 1.10252C0.0563415 1.00532 0.000413468 0.872361 2.27228e-06 0.733644C-0.000408291 0.594789 0.0548328 0.461555 0.153389 0.36382C0.251795 0.265948 0.385441 0.211667 0.524159 0.213038C0.663003 0.21441 0.795415 0.271293 0.892052 0.371084Z" fill="#EB5757"/>
          </svg>
        </div>
      </div>
    </div>

    <div style="opacity: 0.7;border-bottom: 1px solid #EBEBEB;
                margin: 18px 0 10px;"></div>

    <div class="clearfix" style="margin-top: 20px;">
      <h6 class="current-user-title">Active Users</h6>
      <div class="float-start">
        <h3 class="current-user-text">{{ active_users }}</h3>
      </div>
      <div class="float-end">
        <div v-if="active_users_percentage > 0" class="ratio-button-increase">
          {{ active_users_percentage}}%
          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 7.8722L5.04085 3.69428L7.39916 5.25743C7.50196 5.32555 7.6256 5.35489 7.74815 5.34008C7.87055 5.32528 7.98378 5.26729 8.06739 5.17655L10.7577 2.25618L10.4981 4.23566C10.4754 4.41892 10.5516 4.60028 10.6983 4.71226C10.845 4.82425 11.0401 4.84989 11.2108 4.77957C11.3814 4.70937 11.5019 4.55365 11.5273 4.37093L11.9955 0.799381C12.0151 0.651329 11.9699 0.501918 11.8713 0.389518C11.7728 0.277255 11.6306 0.212832 11.4811 0.212969C11.4581 0.212969 11.435 0.214476 11.4123 0.217629L7.84212 0.685742C7.65938 0.710962 7.50366 0.83145 7.43348 1.00211C7.36316 1.17275 7.3888 1.36794 7.50079 1.51475C7.61262 1.66142 7.79412 1.73763 7.97725 1.71488L10.1019 1.43621L7.60413 4.14768L5.25908 2.59354C5.15901 2.52734 5.03907 2.49786 4.91968 2.51006C4.80029 2.5224 4.68885 2.57559 4.60427 2.66071L0.155445 7.14077C0.0563415 7.23796 0.000413468 7.37092 2.27228e-06 7.50964C-0.000408291 7.6485 0.0548328 7.78173 0.153389 7.87947C0.251795 7.97734 0.385441 8.03162 0.524159 8.03025C0.663003 8.02888 0.795415 7.97199 0.892052 7.8722Z" fill="#27AE60"/>
          </svg>
        </div>
        <div v-else class="ratio-button-decrease">
          {{  active_users_percentage | positiveNumber }}%

          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 0.371084L5.04085 4.54901L7.39916 2.98586C7.50196 2.91773 7.6256 2.8884 7.74815 2.9032C7.87055 2.91801 7.98378 2.97599 8.06739 3.06673L10.7577 5.98711L10.4981 4.00763C10.4754 3.82436 10.5516 3.64301 10.6983 3.53102C10.845 3.41903 11.0401 3.3934 11.2108 3.46372C11.3814 3.53392 11.5019 3.68963 11.5273 3.87235L11.9955 7.44391C12.0151 7.59196 11.9699 7.74137 11.8713 7.85377C11.7728 7.96603 11.6306 8.03045 11.4811 8.03032C11.4581 8.03032 11.435 8.02881 11.4123 8.02566L7.84212 7.55754C7.65938 7.53232 7.50366 7.41184 7.43348 7.24118C7.36316 7.07054 7.3888 6.87534 7.50079 6.72854C7.61262 6.58187 7.79412 6.50565 7.97725 6.52841L10.1019 6.80708L7.60413 4.0956L5.25908 5.64974C5.15901 5.71595 5.03907 5.74542 4.91968 5.73322C4.80029 5.72088 4.68885 5.6677 4.60427 5.58258L0.155445 1.10252C0.0563415 1.00532 0.000413468 0.872361 2.27228e-06 0.733644C-0.000408291 0.594789 0.0548328 0.461555 0.153389 0.36382C0.251795 0.265948 0.385441 0.211667 0.524159 0.213038C0.663003 0.21441 0.795415 0.271293 0.892052 0.371084Z" fill="#EB5757"/>
          </svg>
        </div>
      </div>
    </div>

    <div style="opacity: 0.7;border-bottom: 1px solid #EBEBEB;
                margin: 18px 0 10px;"></div>

    <div class="clearfix" style="margin-top: 20px;">
      <h6 class="current-user-title">Inactive Users</h6>
      <div class="float-start">
        <h3 class="current-user-text">{{ inactive_users }}</h3>
      </div>
      <div class="float-end">
        <div v-if="inactive_users_percentage > 0" class="ratio-button-increase">
          {{ inactive_users_percentage}}%
          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 7.8722L5.04085 3.69428L7.39916 5.25743C7.50196 5.32555 7.6256 5.35489 7.74815 5.34008C7.87055 5.32528 7.98378 5.26729 8.06739 5.17655L10.7577 2.25618L10.4981 4.23566C10.4754 4.41892 10.5516 4.60028 10.6983 4.71226C10.845 4.82425 11.0401 4.84989 11.2108 4.77957C11.3814 4.70937 11.5019 4.55365 11.5273 4.37093L11.9955 0.799381C12.0151 0.651329 11.9699 0.501918 11.8713 0.389518C11.7728 0.277255 11.6306 0.212832 11.4811 0.212969C11.4581 0.212969 11.435 0.214476 11.4123 0.217629L7.84212 0.685742C7.65938 0.710962 7.50366 0.83145 7.43348 1.00211C7.36316 1.17275 7.3888 1.36794 7.50079 1.51475C7.61262 1.66142 7.79412 1.73763 7.97725 1.71488L10.1019 1.43621L7.60413 4.14768L5.25908 2.59354C5.15901 2.52734 5.03907 2.49786 4.91968 2.51006C4.80029 2.5224 4.68885 2.57559 4.60427 2.66071L0.155445 7.14077C0.0563415 7.23796 0.000413468 7.37092 2.27228e-06 7.50964C-0.000408291 7.6485 0.0548328 7.78173 0.153389 7.87947C0.251795 7.97734 0.385441 8.03162 0.524159 8.03025C0.663003 8.02888 0.795415 7.97199 0.892052 7.8722Z" fill="#27AE60"/>
          </svg>
        </div>
        <div v-else class="ratio-button-decrease">
          {{  inactive_users_percentage | positiveNumber }}%

          <svg style="margin-left: 3px;" width="12" height="9"
               viewBox="0 0 12 9" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.892052 0.371084L5.04085 4.54901L7.39916 2.98586C7.50196 2.91773 7.6256 2.8884 7.74815 2.9032C7.87055 2.91801 7.98378 2.97599 8.06739 3.06673L10.7577 5.98711L10.4981 4.00763C10.4754 3.82436 10.5516 3.64301 10.6983 3.53102C10.845 3.41903 11.0401 3.3934 11.2108 3.46372C11.3814 3.53392 11.5019 3.68963 11.5273 3.87235L11.9955 7.44391C12.0151 7.59196 11.9699 7.74137 11.8713 7.85377C11.7728 7.96603 11.6306 8.03045 11.4811 8.03032C11.4581 8.03032 11.435 8.02881 11.4123 8.02566L7.84212 7.55754C7.65938 7.53232 7.50366 7.41184 7.43348 7.24118C7.36316 7.07054 7.3888 6.87534 7.50079 6.72854C7.61262 6.58187 7.79412 6.50565 7.97725 6.52841L10.1019 6.80708L7.60413 4.0956L5.25908 5.64974C5.15901 5.71595 5.03907 5.74542 4.91968 5.73322C4.80029 5.72088 4.68885 5.6677 4.60427 5.58258L0.155445 1.10252C0.0563415 1.00532 0.000413468 0.872361 2.27228e-06 0.733644C-0.000408291 0.594789 0.0548328 0.461555 0.153389 0.36382C0.251795 0.265948 0.385441 0.211667 0.524159 0.213038C0.663003 0.21441 0.795415 0.271293 0.892052 0.371084Z" fill="#EB5757"/>
          </svg>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Dashboard from "@/apis/Dashboard";

export default {
  name: "AdminDashboardSidebar",
  data(){
    return {
      current_users: 0,
      active_users: 0,
      inactive_users: 0,

      current_users_percentage: 0,
      active_users_percentage: 0,
      inactive_users_percentage: 0,

      admin_activity: 0,
      user_activity: 0,

      chart: {
        type: 'donut',
      },
      chartOptions: {
        labels: ['User', 'Admin'],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Poppins',
          fontWeight: 500,
          offsetX: -25,
          offsetY: 5,

          markers: {
            width: 18,
            height: 18,
            strokeColor: '#fff',
            // fillColors: ['#4F31FF','#E2B93B','#EB5757'],
            fillColors: ['#4F31FF','#E2B93B'],
            radius: 5,
            offsetX: -4,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 10,
          },

        },
        fill: {
          // colors: ['#4F31FF','#E2B93B','#EB5757']
          colors: ['#4F31FF','#E2B93B']
        },
        stroke: {
          width: 0
        },

        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10
            },
            donut: {
              size: '70%',
              background: 'transparent',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total Visit(%)',
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  color: '#505968;',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    },0)
                  }
                }
              }
            },
          }
        },
      },
      series: [],

    }
  },
  mounted() {
    this.getSidebarData();
  },
  methods: {
    getSidebarData(){
      Dashboard.getAdminDashboardSidebarInfo().then((res)=>{

        if (res.data.total < 1000){
          this.current_users = res.data.total;
        }else {
          let current = res.data.total/1000;
          let currentUser = current.toFixed(1) + 'K';
          this.current_users = currentUser;
        }

        if (res.data.active < 1000){
          this.active_users = res.data.active;
        }else {
          let active = res.data.active/1000;
          let activeUser = active.toFixed(1) + 'K';
          this.active_users = activeUser;
        }

        if (res.data.inactive < 1000){
          this.inactive_users = res.data.inactive;
        }else {
          let inactive = res.data.inactive/1000;
          let inactiveUser = inactive.toFixed(1) + 'K';
          this.inactive_users = inactiveUser;
        }

        if (res.data.total_user_difference_percentage !== 0){
          this.current_users_percentage =
              res.data.total_user_difference_percentage.toFixed(0)
        }

        if (res.data.active_user_difference_percentage !== 0){
          this.active_users_percentage =
              res.data.active_user_difference_percentage.toFixed(0)
        }

        if (res.data.inactive_user_difference_percentage !== 0){
          this.inactive_users_percentage =
              res.data.inactive_user_difference_percentage.toFixed(0)
        }


        this.series = res.data.activity;

      })
    }
  },
}
</script>

<style scoped>

</style>