<template>
  <el-dialog
      title="Create New Document Report"
      custom-class="modal-custom-class"
      :visible.sync="open"
      width="642px"
      :before-close="handleClose">
    <el-form
        ref="form"
        class="acceson-form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
    >

      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Report title" prop="title">
            <el-input
                v-model="formData.title"
                placeholder="Enter Report Title"
            />
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item label="Select Columns" prop="columns">
            <el-select v-model="formData.columns"  style="width: 100%"
                       placeholder="Select Columns" multiple>
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

      </div>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
          class="cancel-button common-button mx-3"
          @click="$emit('cancel', true)"
          :disabled="disabled"
      >Cancel
      </button>
      <el-button
          class="common-button"
          @click="handleSubmit('form')"
          :disabled="disabled"
          :loading="loading"
      >Create
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Reports from "@/apis/Reports";

export default {
  name: "DocumentReportModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formData: {
        title: '',
        type: 'document',
        columns: [],
      },
      rules: {
        title: {
          required: true,
          message : 'Report Title is required'
        },
        columns: {
          required: true,
          message : 'Columns are required'
        },
      },
      options: [
        {
          value: 'title',
          label: 'Title'
        },{
          value: 'security',
          label: 'Security'
        },
        {
          value: 'document_profile',
          label: 'Document Type'
        },
        {
          value: 'download_count',
          label: 'Viewed Count'
        },
        {
          value: 'last_downloaded_at',
          label: 'Last Viewed At'
        },
        {
          value: 'published_at',
          label: 'Published At Portal'
        },
        {
          value: 'date',
          label: 'Document Date'
        },
        {
          value: 'status',
          label: 'Status'
        },

      ],
      loading: false
    }
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.formData)
          this.loading = true;
          this.callToBackendApi();
        } else {
          this.$notify.error('Please insert Required Field!')
          return false;
        }
      });

    },

    async callToBackendApi(){

      await Reports.store(this.formData).then((res)=>{

        if (res.status === 204){
          this.$notify({
            title: "Warning!",
            message: "No Document Found!",
            type: "warning",
          });
        }else {
          this.$notify({
            title: "Success!",
            message: "Document Report Created Successfully.",
            type: "success",
          });
        }

        this.clearFormData();
        this.$emit('submitted', res.data)
        this.loading = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;
      })

    },
    clearFormData(){
      this.formData.name = '';
      this.formData.columns = [];
    }

  },
}
</script>

<style scoped>

</style>