<template>
  <el-dialog
    custom-class="modal-custom-class bulkUpload"
    :visible.sync="open"
    width="900px"
    :before-close="handleClose"
    v-loading="loading"
  >
    <div class="row">
      <div class="col-12">
        <h3 class="doc-upload-title">Edit Document</h3>
        <p class="doc-upload-text">Follow the simple 3 steps to complete your
          mapping</p>
      </div>
    </div>
    <div class="row" style="margin-top: 20px;">
      <div class="col-md-4">
        <div class="stepper-wrapper">
          <div class="stepper-item" :class="{ completed: currentStep >= 1 }">
            <div>
              <div class="step-name">Document Details</div>
              <div class="document-select-text">Select document for next
                step</div>
            </div>
            <div class="step-counter"></div>
          </div>
          <div class="stepper-item" :class="{ completed: currentStep >= 2 }">
            <div>
              <div class="step-name">Select Funds</div>
              <div class="document-select-text">Select your fund for next step</div>
            </div>
            <div class="step-counter"></div>
          </div>
          <div class="stepper-item" :class="{ completed: currentStep >= 3 }">
            <div>
              <div class="step-name">Summary</div>
              <div class="document-select-text">Finalize and save</div>
            </div>
            <div class="step-counter"></div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <DocumentDetail @submitted="handleDocumentSubmit"
                        @cancel="cancel"
                        @next="handleNext"
                        @back="handleBack"
                        ref="documentDetailRef"
                        :document="document" v-if="currentStep  === 1" />

        <FundSelection @submitted="handleDocumentSubmit"
                       @cancel="cancel"
                       ref="fundSelectionRef"
                       @next="handleNext"
                       @back="handleBack"
                       :document="document"
                       v-if="currentStep === 2" />

        <DocumentSummary @submitted="handleDocumentSubmit"
                         @cancel="cancel"
                         :document="document"
                         @next="handleNext"
                         @back="handleBack"
                         v-if="currentStep === 3" />

      </div>
    </div>


  </el-dialog>
</template>

<script>
import DocumentDetail from "./edit/DocumentDetail";
import FundSelection from "./edit/Funds";
import DocumentSummary from "./edit/Summary";
import Document from "@/apis/Document";
import {Notification} from "element-ui";
export default {
  name: "BulkUploadModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    document_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    DocumentDetail,
    FundSelection,
    DocumentSummary
  },
  data() {
    return {
      document: {
        id: null,
        title: "",
        document_profile: "bulk",
        security: "",
        split_method: "",
        split_per_page: "",
        category_id: "",
        date: "",
        due_date: "",
        fund_ids: [],
        funds: [],
        category: {},
        status: "",
        create_file: "no",
      },

      currentStep: 1,
      loading: false,
    };
  },
  created() {
    if (this.document_id !== null){
      this.loading = true;
      Document.show(this.document_id).then((res)=>{
        this.loading = false;
        let document = res.data.data;

        let category = document.category_id

        this.document.id = document.id
        this.document.title = document.title;
        this.document.document_profile = document.document_profile;
        this.document.security = document.security;
        this.document.split_method = document.split_method;
        this.document.split_per_page = document.split_per_page;
        this.document.category_id = category.toString();
        this.document.date = document.date;
        this.document.due_date = document.due_date;
        this.document.fund_ids = document.fund_ids;
        this.document.funds = document.funds;
        this.document.category = document.category;
        this.document.status = document.status;

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      })
    }
  },
  methods: {
    handleClose() {
      this.$emit("close", false);      
    },
    cancel() {
      this.$emit('cancel', true);
    },
    saveAndClose() {
      this.$emit('cancel', true);
    },
    handleNext() {
      this.currentStep += 1;
    },
    handleBack() {
      this.currentStep -= 1;
    },
    handleDocumentSubmit(document) {      
      this.document = document;
    },
  },

};
</script>

<style scoped>
.doc-upload-title{
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #092C4C
}
.document-select-text{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #505968;
}

.doc-upload-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505968;
}
.stepper-wrapper {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 85px;
}
.stepper-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 26px;
  color: #092c4c;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  //content: "";
  //border-bottom: 6px solid #c1d9fa;
  //width: 50%;
  //transform: rotate(90deg);
  //top: 42px;
  //right: -44%;
  //z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #F3F3F3;
  width: 50%;
  transform: rotate(90deg);
  top: 85px;
  left: 70%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #4F31FF;
  margin-bottom: 6px;
}

.stepper-item.completed {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4F31FF;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #4F31FF;
  width: 50%;
  top: 85px;
  left: 70%;
  z-index: 3;
}


.stepper-item.completed::before {
  position: absolute;
  content: url("../../assets/images/Vector.svg");
  top: 17px;
  left: 93.5%;
  z-index: 11;
}

.stepper-item:first-child::before {
  //content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.stepper-item .step-name {
  margin-bottom: 8px;
  text-align: right;
}

@media (max-width: 575.99px) {
  .stepper-item::after {
    left: 70.6%;
  }

  .stepper-item.completed::after {
    top: 98px;
    left: 70.5%;
  }

  .stepper-item.completed::before {
    left: 94%;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #F3F3F3;
    width: 15%;
    transform: rotate(90deg);
    top: 100px;
    left: 91%;
    z-index: 2;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #4F31FF;
    width: 15%;
    top: 100px;
    left: 91%;
    z-index: 3;
  }

  .stepper-item.completed::before {
    left: 98%;
  }


  }
</style>
