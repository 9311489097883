<template>
  <div class="container-fluid p-0 mt-n2 dashboard-container">

    <div class="row">
      <div class="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">

          <div class="col-12">
            <div class="card dashboard-heading card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start">
                    <h6 class="dashboard-user-title">
                      Hey {{ user.first_name + ' ' + user.last_name }}
                    </h6>
                    <h3 class="dashboard-user-welcome">{{ welcome }}</h3>
                  </div>
                  <div class="float-end">
                    <img src="../../assets/images/dashboard-logo.png"
                         alt="">
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start">
                    <el-button type="danger"
                               icon="el-icon-suitcase" circle></el-button>
                  </div>
                  <div class="float-end">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>
                <div class="clearfix row mt-3">
                  <div
                      class="float-start col-xxl-5 col-xl-6 col-lg-7 col-md-12 col-sm-12">
                    <div class="count-box fixedWidth">
                      <p class="count-title"># of Funds</p>
                      <p class="count-number" v-if="dataList.funds <= 1000">
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.funds"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>

                      </p>
                      <p class="count-number" v-else>
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.funds/1000"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                        {{'K'}}

                      </p>
                    </div>
                  </div>
                  <div
                      class="float-end col-xxl-7 col-xl-6 col-lg-5 col-md-12 col-sm-12">
                    <img src="../../assets/images/graph1.png"
                         class="img-fluid w-100" alt="">
                  </div>
                </div>

                <button class="create-card-button" @click.prevent="addNewFund">
                  <i class="fa fa-plus me-2"></i>
                  Create New Fund
                </button>

              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start">
                    <el-button type="warning" icon="el-icon-s-custom" circle></el-button>
                  </div>
                  <div class="float-end">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>
                <div class="clearfix row mt-3">
                  <div
                      class="float-start col-xxl-5 col-xl-6 col-lg-7 col-md-12 col-sm-12">
                    <div class="count-box fixedWidth">
                      <p class="count-title">Investors</p>
                      <p class="count-number" v-if="dataList.investors <= 1000">
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.investors"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                      </p>
                      <p class="count-number" v-else>
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.investors/1000"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                        {{'K'}}

                      </p>
                    </div>
                  </div>
                  <div
                      class="float-end col-xxl-7 col-xl-6 col-lg-5 col-md-12 col-sm-12">
                    <img src="../../assets/images/graph2.png"
                         class="img-fluid w-100" alt="">
                  </div>
                </div>

                <button class="create-card-button"
                        @click.prevent="addNewInvestor">
                  <i class="fa fa-plus me-2"></i>
                  Create New Investor
                </button>

              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start">
                    <el-button type="primary" icon="el-icon-user-solid" circle></el-button>
                  </div>
                  <div class="float-end">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>
                <div class="clearfix row mt-3">
                  <div
                      class="float-start col-xxl-5 col-xl-6 col-lg-7 col-md-12 col-sm-12">
                    <div class="count-box fixedWidth">
                      <p class="count-title">Contacts</p>
                      <p class="count-number" v-if="dataList.contacts <= 1000">
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.contacts"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                      </p>
                      <p class="count-number" v-else>
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.contacts/1000"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                        {{'K'}}

                      </p>
                    </div>
                  </div>
                  <div
                      class="float-end col-xxl-7 col-xl-6 col-lg-5 col-md-12 col-sm-12">
                    <img src="../../assets/images/graph1.png"
                         class="img-fluid w-100" alt="">
                  </div>
                </div>

                <button class="create-card-button"
                        @click.prevent="addNewContact">
                  <i class="fa fa-plus me-2"></i>
                  Create New Contact
                </button>

              </div>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="card dashboard-card card-padding">
              <div class="card-body">
                <div class="clearfix">
                  <div class="float-start">
                    <el-button type="success" icon="el-icon-document" circle></el-button>
                  </div>
                  <div class="float-end">
                    <i class="fa fa-ellipsis-v"></i> <br>
                  </div>
                </div>
                <div class="clearfix row mt-3">
                  <div
                      class="float-start col-xxl-5 col-xl-6 col-lg-7 col-md-12 col-sm-12">
                    <div class="count-box fixedWidth">
                      <p class="count-title">Documents</p>
                      <p class="count-number" v-if="dataList.documents <= 1000">
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.documents"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>

                      </p>
                      <p class="count-number" v-else>
                        <number
                            ref="number1"
                            :from="0"
                            :to="dataList.documents/1000"
                            :duration="3"
                            :delay="1"
                            easing="Power1.easeOut"/>
                        {{'K'}}
                      </p>
                    </div>
                  </div>
                  <div
                      class="float-end col-xxl-7 col-xl-6 col-lg-5 col-md-12 col-sm-12">
                    <img src="../../assets/images/graph2.png"
                         class="img-fluid w-100" alt="">
                  </div>
                </div>

                <button class="create-card-button" @click.prevent="addNewDocument">
                  <i class="fa fa-upload me-2"></i>
                  Upload Document
                </button>

              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card card-padding">
              <div class="card-body">
                <div id="chart">
                  <apexchart type="area" height="350"
                             :options="chartOptions" :series="series"></apexchart>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card card-padding" style="height: 770px; overflow-y: auto;">
          <div class="card-header clearfix">
            <div class="float-start">
              <h5
                  style="font-weight: 500;font-size: 20px;color: #092C4C;">Activity Log</h5>
            </div>
            <div class="float-end cursor-pointer">
              <i class="fa fa-ellipsis-v"></i>
            </div>
          </div>
          <div class="card-body">

            <div class="clearfix user-log-card" v-for="data in
            dataList.activities">
              <div class="float-start">
                <p class="sidebar-user">
                  {{ data.user.first_name + ' '+ data.user.last_name}}
                </p>
                <p class="sidebar-user-log">{{ data.description }}</p>
              </div>
              <div class="float-end">
                <div class="user-log-time">{{ data.created_at | log_time}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Dashboard from "@/apis/Dashboard";

export default {
  name: "AdminDashboard",
  data(){
    return{
      welcome: '',
      dataList: '',

      series: [{
        name: "Investment",
        data: [10, 180,120, 140, 120, 90, 150, 140, 180, 160,210,220]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#F44336', '#E91E63', '#9C27B0'],
          }
        },
        animations: {
          enabled: true
        },
        tooltip: {
          enabled: true
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        title: {
          text: 'Analytics',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#ffffff', 'transparent'],
            opacity: 0
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',
            'Aug', 'Sep','Oct','Nov','Dec'],
        },


      },
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    welcomeUser(){
      let date = new Date();
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let current_time = hours * 60 * 60 + minutes * 60

      if (current_time >= 1 && current_time < 21600){
        this.welcome = 'Good Night'
      }else if (current_time >= 21600 && current_time < 43200){
        this.welcome = 'Good Morning'
      }else if (current_time >= 43200 && current_time < 64800){
        this.welcome = 'Good Afternoon'
      }else if (current_time >= 64800 && current_time < 90000){
        this.welcome = 'Good Evening'
      }

    },

    addNewFund(){
      this.$router.push({name: 'setting',query:{open: true}})
    },

    addNewInvestor(){
      this.$router.push({name: 'portal',query:{open: true}})
    },

    addNewContact(){
      this.$router.push({name: 'contact',query:{open: true}})
    },

    addNewDocument(){
      this.$router.push({name: 'document',query:{open: true}})
    },



  },
  mounted() {
    this.welcomeUser();
    Dashboard.index().then((res)=>{
      this.dataList = res.data;
    })
  }
}
</script>

<style scoped>

@media (min-width: 1440px) and (max-width: 1650px) {
  .fixedWidth{
    width: 110px !important;
  }

  .sidebar-user{
    font-size: 12px !important;
  }

  .sidebar-user-log{
    font-size: 10px !important;
  }

  .user-log-time{
    width: 60px;
    height: 25px;
    font-size: 10px;
    margin-right: 5px;
    padding-top: 9px;
  }
}


</style>