export const isAuthenticated = state => state.token !== null;
export const isGuest = state => state.token === null;
export const user = state => state.user;
export const permissions = state => state.permissions;
export const isActive = state => state.user.is_active;
export const token = state => state.token;
export const isSuperAdmin = state => state.user.user_role === 'superadmin';
export const isAdmin = state => state.user.user_role === 'admin';
export const isUser = state => state.user.user_role === 'user';
export const tokenExpiration = state => state.tokenExpiration;
