import Api from "./Api";

export default {

    index() {
        return Api.get(`/v1/dashboard/list-count`);
    },

    getAdminDashboardSidebarInfo() {
        return Api.get(`/v1/dashboard/sidebar`);
    },

    getUserDashboardInfo() {
        return Api.get(`/v1/user-dashboard/data`);
    },

}