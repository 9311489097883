<template>
  <el-dialog
    custom-class="modal-custom-class singleUpload"
    :visible.sync="open"
    width="900px"
    :before-close="handleClose"
  >
    <div class="row">
      <div class="col-12">
        <h3 class="doc-upload-title">Edit Document</h3>
        <p class="doc-upload-text">Follow the simple 4 steps to complete your
          mapping</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="stepper-wrapper">
          <div class="stepper-item" :class="{ completed: currentStep >= 1 }">
            <div>
              <div class="step-name">Document Details</div>
              <div class="document-select-text">Select document for next
                step</div>
            </div>
            <div class="step-counter"></div>
          </div>
          <div class="stepper-item" :class="{ completed: currentStep >= 2 }">
            <div>
              <div class="step-name">Select Funds</div>
              <div class="document-select-text">Select your fund for next step</div>
            </div>
            <div class="step-counter"></div>
          </div>
          <div class="stepper-item" :class="{ completed: currentStep >= 3 }">
            <div>
              <div class="step-name">Select Investors</div>
              <div class="document-select-text">Select your fund for next step</div>
            </div>
            <div class="step-counter"></div>
          </div>
          <div class="stepper-item" :class="{ completed: currentStep >= 4 }">
            <div>
              <div class="step-name">Summary</div>
              <div class="document-select-text">Finalize and save</div>
            </div>
            <div class="step-counter"></div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <DocumentDetail @submitted="handleDocumentSubmit" @cancel="cancel" @next="handleNext" @back="handleBack" ref="documentDetailRef" :document="document" v-if="currentStep == 1" />

        <FundSelection @submitted="handleDocumentSubmit" @cancel="cancel" ref="fundSelectionRef" @next="handleNext" @back="handleBack" :document="document" v-if="currentStep == 2" />

        <InvestorSelection @submitted="handleDocumentSubmit" @cancel="cancel" ref="investorSelectionRef" @next="handleNext" @back="handleBack" :document="document" v-if="currentStep == 3" />

        <DocumentSummary @submitted="handleDocumentSubmit" @cancel="cancel" :document="document" @next="handleNext" @back="handleBack" v-if="currentStep == 4" />

      </div>
    </div>

  </el-dialog>
</template>

<script>

import Document from "../../apis/Document";
import DocumentDetail from "./edit/DocumentDetail";
import FundSelection from "./edit/Funds";
import InvestorSelection from "./edit/Investors";
import DocumentSummary from "./edit/Summary";
import {Notification} from "element-ui";
export default {
  name: "SingleUploadModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    document_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    DocumentDetail,
    FundSelection,
    InvestorSelection,
    DocumentSummary
  },
  data() {
    return {
      document: {
        id: null,
        title: "",
        document_profile: "single",
        security: "",
        split_mode: "",
        split_per_page: "",
        category_id: "",
        date: "",
        due_date: "",
        fund_ids: [],
        funds: [],
        category: {},
        account_ids: [],
        accounts: [],
        status: "",
        create_file: "no",
      },
      currentStep: 1,
    };
  },
  created() {
    if (this.document_id !== null){
      this.loading = true;
      Document.show(this.document_id).then((res)=>{
        this.loading = false;
        let document = res.data.data;

        let category = document.category_id

        this.document.id = document.id
        this.document.title = document.title;
        this.document.document_profile = document.document_profile;
        this.document.security = document.security;
        this.document.split_method = document.split_method;
        this.document.split_per_page = document.split_per_page;
        this.document.category_id = category.toString();
        this.document.date = document.date;
        this.document.due_date = document.due_date;
        this.document.fund_ids = document.fund_ids;
        this.document.funds = document.funds;
        this.document.category = document.category;
        this.document.accounts = document.accounts;
        this.document.account_ids = document.account_ids;
        this.document.status = document.status;

      }).catch(()=>{
        Notification.error("Something went wrong!");
        this.loading = false;
      })
    }
  },
  methods: {
    handleClose() {
      this.$emit("close", false);      
    },
    cancel() {
      this.$emit('cancel', true);
    },
    saveAndClose() {
      // this.$refs.documentDetailRef.handleSubmit('form');
      this.$emit('cancel', true);
    },
    handleNext() {
      this.currentStep += 1;
    },
    handleBack() {
      this.currentStep -= 1;
    },
    handleDocumentSubmit(document) {      
      this.document = document;
    },    
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("document", this.tempDocument);
          formData.append("name", this.document.name);
          formData.append("date", this.document.date);
          formData.append(
            "due_date",
            this.document.due_date ? this.document.due_date : ""
          );
          formData.append(
            "category_id",
            this.document.category_id ? this.document.category_id : ""
          );
          formData.append(
            "upload_type",
            this.document.upload_type ? this.document.upload_type : ""
          );
          formData.append(
            "split_mode",
            this.document.split_mode ? this.document.split_mode : ""
          );
          if (this.document.split_mode === "merged") {
            formData.append(
              "split_per_page",
              this.document.split_per_page ? this.document.split_per_page : ""
            );
          }

          this.document.fund_ids.forEach((id, key) => {
            formData.append("fund_ids[" + key + "]", id);
          });

          this.loading = true;
          Document.store(formData)
            .then((res) => {
              this.$emit("submitted", res.data.data);
              this.loading = false;
            })
            .catch((error) => {
              if (error.response) {
                this.formErrors(error.response.data, this.$notify.error);
              } else {
                this.errorMessage(error);
              }
              this.loading = false;
            });
        } else {
          this.errorMessage("Error while submitting!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    fileChange(file, fileList) {
      fileList = [];
      this.tempDocument = file.raw;
    },
    handlePreview(file) {
      console.log(file);
    },
  },

};
</script>

<style scoped>
.doc-upload-title{
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #092C4C
}

.document-select-text{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #505968;
}

.doc-upload-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505968;
}

.stepper-wrapper {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 85px;
}
.stepper-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 26px;
  color: #092c4c;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item.completed::before {
  position: absolute;
  content: url("../../assets/images/Vector.svg");
  top: 17px;
  left: 93.5%;
  z-index: 11;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #F3F3F3;
  width: 50%;
  transform: rotate(90deg);
  top: 85px;
  left: 70%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #4F31FF;
  margin-bottom: 6px;
}

.stepper-item.completed {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #2f80ed;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #4F31FF;
  width: 50%;
  top: 85px;
  left: 70%;
  z-index: 3;
}

.stepper-item:first-child::before {
  //content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.stepper-item .step-name {
  margin-bottom: 8px;
  text-align: right;
}

@media (max-width: 575.99px) {
  .stepper-item::after {
    left: 70.6%;
  }

  .stepper-item.completed::after {
    top: 98px;
    left: 70.5%;
  }

  .stepper-item.completed::before {
    left: 94%;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #F3F3F3;
    width: 15%;
    transform: rotate(90deg);
    top: 100px;
    left: 91%;
    z-index: 2;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #4F31FF;
    width: 15%;
    top: 100px;
    left: 91%;
    z-index: 3;
  }

  .stepper-item.completed::before {
    left: 98%;
  }

}
</style>
