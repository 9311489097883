<template>
  <div class="d-inline-block">
    <!--    Dashboard-->

    <svg v-if="name === 'dashboard'" width="16" height="17" viewBox="0 0 16 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.5H13.2866C14.2326 1.5 15.0001 2.27413 15.0001 3.22913V5.53487C15.0001 6.48915 14.2326 7.264 13.2866 7.264H11C10.0532 7.264 9.28571 6.48915 9.28571 5.53487V3.22913C9.28571 2.27413 10.0532 1.5 11 1.5Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.71431 1.5H5.0002C5.94698 1.5 6.71445 2.27413 6.71445 3.22913V5.53487C6.71445 6.48915 5.94698 7.264 5.0002 7.264H2.71431C1.76753 7.264 1.00006 6.48915 1.00006 5.53487V3.22913C1.00006 2.27413 1.76753 1.5 2.71431 1.5Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.71431 9.73593H5.0002C5.94698 9.73593 6.71445 10.5101 6.71445 11.4658V13.7708C6.71445 14.7258 5.94698 15.4999 5.0002 15.4999H2.71431C1.76753 15.4999 1.00006 14.7258 1.00006 13.7708V11.4658C1.00006 10.5101 1.76753 9.73593 2.71431 9.73593Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 9.73593H13.2866C14.2326 9.73593 15.0001 10.5101 15.0001 11.4658V13.7708C15.0001 14.7258 14.2326 15.4999 13.2866 15.4999H11C10.0532 15.4999 9.28571 14.7258 9.28571 13.7708V11.4658C9.28571 10.5101 10.0532 9.73593 11 9.73593Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!--    Portal-->
    <svg
      v-if="name === 'portal'"
      width="22" height="20"
      style="margin-right: 5px; display: inline-block"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7V6H4V7H3ZM0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H9C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V8.5C11 8.63261 11.0527 8.75979 11.1464 8.85355C11.2402 8.94732 11.3674 9 11.5 9C11.6326 9 11.7598 8.94732 11.8536 8.85355C11.9473 8.75979 12 8.63261 12 8.5V2C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V8.5C14 9.16304 13.7366 9.79893 13.2678 10.2678C12.7989 10.7366 12.163 11 11.5 11H2.5C1.83696 11 1.20107 10.7366 0.732233 10.2678C0.263392 9.79893 0 9.16304 0 8.5V2ZM2.5 3C2.36739 3 2.24021 3.05268 2.14645 3.14645C2.05268 3.24021 2 3.36739 2 3.5C2 3.63261 2.05268 3.75979 2.14645 3.85355C2.24021 3.94732 2.36739 4 2.5 4H8.5C8.63261 4 8.75979 3.94732 8.85355 3.85355C8.94732 3.75979 9 3.63261 9 3.5C9 3.36739 8.94732 3.24021 8.85355 3.14645C8.75979 3.05268 8.63261 3 8.5 3H2.5ZM2.5 5C2.36739 5 2.24021 5.05268 2.14645 5.14645C2.05268 5.24021 2 5.36739 2 5.5V7.5C2 7.63261 2.05268 7.75979 2.14645 7.85355C2.24021 7.94732 2.36739 8 2.5 8H4.5C4.63261 8 4.75979 7.94732 4.85355 7.85355C4.94732 7.75979 5 7.63261 5 7.5V5.5C5 5.36739 4.94732 5.24021 4.85355 5.14645C4.75979 5.05268 4.63261 5 4.5 5H2.5ZM6.5 5C6.36739 5 6.24021 5.05268 6.14645 5.14645C6.05268 5.24021 6 5.36739 6 5.5C6 5.63261 6.05268 5.75979 6.14645 5.85355C6.24021 5.94732 6.36739 6 6.5 6H8.5C8.63261 6 8.75979 5.94732 8.85355 5.85355C8.94732 5.75979 9 5.63261 9 5.5C9 5.36739 8.94732 5.24021 8.85355 5.14645C8.75979 5.05268 8.63261 5 8.5 5H6.5ZM6.5 7C6.36739 7 6.24021 7.05268 6.14645 7.14645C6.05268 7.24021 6 7.36739 6 7.5C6 7.63261 6.05268 7.75979 6.14645 7.85355C6.24021 7.94732 6.36739 8 6.5 8H8.5C8.63261 8 8.75979 7.94732 8.85355 7.85355C8.94732 7.75979 9 7.63261 9 7.5C9 7.36739 8.94732 7.24021 8.85355 7.14645C8.75979 7.05268 8.63261 7 8.5 7H6.5Z"
        fill="#6D6D6D"
      />
    </svg>

    <!--        Document-->

    <svg v-if="name === 'documents'" width="14" height="17" viewBox="0 0 14 17" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99458 1.50893H3.92278C2.35314 1.50893 1 2.78126 1 4.35166V12.5185C1 14.1774 2.26471 15.5 3.92278 15.5H10.0131C11.5835 15.5 12.8558 14.0897 12.8558 12.5185V5.53099L8.99458 1.50893Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.79431 1.5V3.71762C8.79431 4.80014 9.67023 5.67834 10.752 5.68063C11.756 5.68292 12.7828 5.68368 12.8522 5.6791" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.64946 11.2637H4.53516" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.09318 7.48839H4.53479" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!--        Fund-->

    <svg  v-if="name === 'funds'" width="16" height="17" viewBox="0 0 16 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25641 10.6154L6.43849 7.77932L8.92752 9.73452L11.0629 6.97856" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="13.5519" cy="2.90133" r="1.40133" fill="#092C4C" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.8551 2.11396H4.55672C2.3613 2.11396 1 3.66877 1 5.8642V11.7565C1 13.9519 2.3346 15.5 4.55672 15.5H10.8294C13.0248 15.5 14.3861 13.9519 14.3861 11.7565V6.62492" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <!--        Investor-->

    <svg  v-if="name === 'investors'" width="16" height="17"
          viewBox="0 0 16 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00031 7.10025C11.8665 7.10025 15.0006 5.84659 15.0006 4.30012C15.0006 2.75366 11.8665 1.5 8.00031 1.5C4.13415 1.5 1 2.75366 1 4.30012C1 5.84659 4.13415 7.10025 8.00031 7.10025Z" stroke="#505968" stroke-width="1.5" stroke-linecap="square"/>
      <path d="M1 4.3V8.49956C1 10.0459 4.13427 11.2997 8.00031 11.2997C11.8663 11.2997 15.0006 10.0465 15.0006 8.49956V4.3" stroke="#505968" stroke-width="1.5" stroke-linecap="square"/>
      <path d="M1 8.49966V12.6998C1 14.2461 4.13427 15.5 8.00031 15.5C11.8663 15.5 15.0006 14.2461 15.0006 12.6998V8.49966" stroke="#505968" stroke-width="1.5" stroke-linecap="square"/>
    </svg>


    <!--        Contact-->
    <svg v-if="name === 'contacts'" width="16" height="15"
         viewBox="0 0 16 15" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0422 6.65511C12.4203 6.65511 13.5373 5.53809 13.5373 4.15998C13.5373 2.78264 12.4203 1.66486 11.0422 1.66486" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.1896 9.36649C12.6013 9.39449 13.0099 9.45352 13.4133 9.54282C13.9726 9.65406 14.6461 9.88337 14.8852 10.3851C15.0381 10.7068 15.0381 11.0814 14.8852 11.403C14.6469 11.9047 13.9726 12.134 13.4133 12.2491" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.17717 9.90377C8.96971 9.90377 11.3551 10.3268 11.3551 12.0167C11.3551 13.7074 8.98484 14.1448 6.17717 14.1448C3.38463 14.1448 1 13.7225 1 12.0319C1 10.3412 3.3695 9.90377 6.17717 9.90377Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.17718 7.49149C4.33517 7.49149 2.85944 6.015 2.85944 4.17298C2.85944 2.33172 4.33517 0.855236 6.17718 0.855236C8.0192 0.855236 9.49569 2.33172 9.49569 4.17298C9.49569 6.015 8.0192 7.49149 6.17718 7.49149Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!--        Email-->
    <svg v-if="name === 'email'" width="16" height="15" viewBox="0 0 16 15" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path d="M12.115 5.31824L9.00974 7.8184C8.42209 8.27914 7.5983 8.27914 7.01064 7.8184L3.87872 5.31824" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5584 1.26965H11.422C12.4116 1.28075 13.3535 1.69912 14.0284 2.42733C14.7032 3.15553 15.0521 4.13001 14.9937 5.1238V9.87613C15.0521 10.8699 14.7032 11.8444 14.0284 12.5726C13.3535 13.3008 12.4116 13.7192 11.422 13.7303H4.5584C2.43269 13.7303 1 12.0009 1 9.87613V5.1238C1 2.99899 2.43269 1.26965 4.5584 1.26965Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!--        Reports-->
    <svg v-if="name === 'report'" width="15" height="17" viewBox="0 0 15 17"
         fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0993 11.7461H4.60883" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.0993 8.56243H4.60883" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.70401 5.38619H4.60895" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2456 1.5C10.2456 1.5 4.4076 1.50304 4.39848 1.50304C2.29962 1.51597 1 2.89696 1 5.00342V11.9966C1 14.1137 2.30951 15.5 4.42662 15.5C4.42662 15.5 10.2639 15.4977 10.2738 15.4977C12.3726 15.4848 13.673 14.103 13.673 11.9966V5.00342C13.673 2.88631 12.3627 1.5 10.2456 1.5Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!-- Role -->
    <svg  v-if="name === 'role'" width="16" height="17" viewBox="0 0 16 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.56665 10.9333L6.72665 7.22667L10.4333 6.06667L9.27332 9.77333L5.56665 10.9333Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="8" cy="8.5" r="7" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!-- Setting-->
    <svg  v-if="name === 'setting'" width="15" height="17" viewBox="0 0 15 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7663 5.39333L13.313 4.60667C12.9294 3.94103 12.0795 3.7114 11.413 4.09333V4.09333C11.0957 4.28024 10.7171 4.33327 10.3606 4.24073C10.0042 4.14818 9.69924 3.91766 9.51296 3.6C9.39313 3.39809 9.32873 3.16812 9.32629 2.93333V2.93333C9.3371 2.55692 9.19509 2.19215 8.93261 1.92214C8.67013 1.65212 8.30953 1.49984 7.93296 1.5H7.01963C6.6507 1.5 6.29698 1.64701 6.03674 1.9085C5.7765 2.16999 5.63119 2.52441 5.63296 2.89333V2.89333C5.62203 3.65503 5.0014 4.26675 4.23963 4.26667C4.00485 4.26423 3.77487 4.19984 3.57296 4.08V4.08C2.90641 3.69806 2.05648 3.92769 1.67296 4.59333L1.18629 5.39333C0.803238 6.05814 1.02974 6.90753 1.69296 7.29333V7.29333C2.12406 7.54223 2.38964 8.00221 2.38964 8.5C2.38964 8.99779 2.12406 9.45777 1.69296 9.70667V9.70667C1.03058 10.0899 0.803833 10.9372 1.18629 11.6V11.6L1.64629 12.3933C1.82599 12.7176 2.12749 12.9568 2.48407 13.0582C2.84066 13.1595 3.22293 13.1146 3.54629 12.9333V12.9333C3.86418 12.7478 4.24299 12.697 4.59853 12.7922C4.95407 12.8873 5.25687 13.1205 5.43963 13.44C5.55946 13.6419 5.62386 13.8719 5.62629 14.1067V14.1067C5.62629 14.8762 6.25011 15.5 7.01963 15.5H7.93296C8.69988 15.5 9.32262 14.8802 9.32629 14.1133V14.1133C9.32451 13.7433 9.47074 13.3878 9.73242 13.1261C9.99411 12.8644 10.3495 12.7182 10.7196 12.72C10.9538 12.7263 11.1829 12.7904 11.3863 12.9067V12.9067C12.0511 13.2897 12.9005 13.0632 13.2863 12.4V12.4L13.7663 11.6C13.9521 11.2811 14.0031 10.9013 13.908 10.5447C13.8129 10.188 13.5795 9.88405 13.2596 9.7V9.7C12.9397 9.51595 12.7064 9.21196 12.6113 8.85534C12.5162 8.49872 12.5672 8.11891 12.753 7.8C12.8738 7.58906 13.0487 7.41416 13.2596 7.29333V7.29333C13.9189 6.90774 14.1448 6.06331 13.7663 5.4V5.4V5.39333Z" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="7.47963" cy="8.49999" r="1.92" stroke="#505968" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


  </div>
</template>

<script>
export default {
  name: "Icons",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
