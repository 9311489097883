<template>
  <div class="container-fluid p-0 mt-n2">
    <div class="title-section mb-3">
      <h1 class="list-title">Access Rights</h1>
      <div>
        <el-button @click.prevent="backToContact" class="common-button">
          <i class="fa fa-arrow-alt-circle-left"></i> Back
        </el-button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card rounded-3 card-padding">
          <div class="card-body">
            <el-table
              :data="onlyAccounts"
              style="width: 100%"
              row-key="uid"
              border
              :lazy="true"
              :load="load"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column prop="name" label="Investor Account"
                               width="350">
              </el-table-column>
              <el-table-column prop="" label="Categories" min-width="400">
                <template slot-scope="scope">
                  <div>
                    <el-select
                      style="width: 100%"
                      v-model="scope.row.access_rights"
                      @change="onChange(scope.row)"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose document category to set access"
                    >
                      <el-option
                        v-for="category in categories"
                        :key="category.id"
                        :label="category.name"
                        :value="category.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="border-top mt-3 d-flex justify-content-end">
              <button
                @click.prevent="handleSubmit"
                class="mt-3 common-button"
                type="button"
                >Save</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from "@/apis/Category";
import Account from "@/apis/Account";
import Contact from "../../apis/Contact";

export default {
  name: "AccessRights",
  data() {
    return {
      onlyAccounts: [],
      accounts: [],
      categories: [],
      data: [],
      test: [],      
    };
  },
  mounted() {
    Category.list().then((res) => {
      Object.entries(res.data.data).forEach(([key, value]) => {
        this.categories.push({
          id: key,
          name: value,
        });
      });
    });

    this.getUserAccounts();
  },

  methods: {
    
    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    handleSubmit() {
      Contact.accessRights(this.data).then((response) => {
        this.$notify({
          title: "Success!",
          message: "Document access rights successfully saved.",
          type: "success",
        });

        // this.getUserAccounts("saved");
        location.reload();
      });
    },
    onChange(value) {
      this.data.forEach((item) => {
        if (value.entity_type === "all") {
          item.access_rights = value.access_rights;
        } else if (
          item.entity_type === "account" ||
          item.account_id === value.id
        ) {
          item.access_rights = value.access_rights;
        } else if (item.fund_id === value.id) {
          item.access_rights = value.access_rights;
        }
      });

    },
    getUserAccounts(type) {
      type = type ? type : "";
      Account.userWiseAccounts(this.$route.query.uid).then((response) => {
        this.accounts = response.data.data;
        if (type) {
          this.setChildren();
        } else {
          this.onlyAccounts = [
            {
              id: "all",
              name: "All",
              hasChildren: this.accounts.length > 0,
              children: [],
              entity_type: "all",
              uid: 'all'
            },
          ];
          this.data.push({
            user_id: this.$route.query.uid,
            account_id: "all",
            access_rights: [],
            entity_type: "all",
          });
          this.accounts.forEach((account) => {
            this.data.push({
              user_id: this.$route.query.uid,
              account_id: account.id,
              access_rights: [],
              entity_type: "account",
            });
            account.funds.forEach((fund) => {
              this.data.push({
                user_id: this.$route.query.uid,
                account_id: account.id,
                fund_id: fund.id,
                access_rights: fund.access_rights,
                entity_type: "fund",
              });
            });

          });
        }

      });
    },
    setChildren() {
      let acc = {
        id: "all",
        name: "All",
        hasChildren: this.accounts.length > 0,
        children: [],
        entity_type: "all",
      };
      this.accounts.forEach((account, aKey) => {
        acc.children.push({
          id: account.id,
          name: account.title,
          access_rights: [],
          entity_type: "account",
          hasChildren: account.funds.length > 0,
        });

        account.funds.forEach((fund) => {
            acc.children[aKey].children = {
            id: fund.id,
            name: fund.name,
            access_rights: fund.access_rights,
            entity_type: "fund",
          }          
        });
      });
    },
    load(tree, treeNode, resolve) {
      let children = [];
      this.accounts.forEach((account) => {
        if (tree.entity_type === "all") {
          children.push({
            id: account.id,
            name: account.title,
            access_rights: [],
            entity_type: "account",
            hasChildren: account.funds.length > 0,
            uid: account.id+ this.randomInteger(10, 9989)
          });
        } else if (account.id === tree.id) {
          account.funds.forEach((fund) => {
            children.push({
              id: fund.id,
              name: fund.name,
              access_rights: fund.access_rights,
              entity_type: "fund",
              uid: fund.id+ this.randomInteger(10, 9989)
            });
          });
        }
      });

      setTimeout(() => {
        resolve(children);
      }, 1000);
    },

    backToContact() {
      this.$router.push({ name: "contact" });
    },
  },
};
</script>

<style scoped></style>
