import Api from "./Api";

export default {

    index(page,fund_id) {
        return Api.get(`/v1/commitments?page=${page}&fund_id=${fund_id}`,);
    },

    store(formData) {
        return Api.post('/v1/commitments', formData);
    },

    show(id) {
        return Api.get(`/v1/commitments/${id}`);
    },

    update(id, formData) {
        return Api.patch(`/v1/commitments/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/commitments/${id}`);
    },

    updateStatus(id,status) {
        return Api.patch(`/v1/update-fund-commitment-status/${id}`,{'status' : status});
    },

}