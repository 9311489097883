import Api from "./Api";

export default {

    updateProfile(formData) {
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return Api.post('/v1/profile/update', formData, config);
    },

    changePassword(formData) {
        return Api.post('/v1/change-password', formData);
    },

    changeEmail(formData) {
        return Api.post('/v1/change-email', formData);
    },

    updateProfileWithEmail(formData) {
        return Api.post('/v1/update-profile', formData);
    },



}