<template>
    <div class="wrapper">
        <sidebar/>

        <div class="main">
            <navbar/>

            <main class="content">
                <router-view></router-view>
            </main>
        </div>
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
export default {
    name: "MainLayout",
    components: {Navbar, Sidebar}
}
</script>

<style scoped>

</style>