<template>
  <el-dialog
    title="Add a new investor account"
    custom-class="modal-custom-class acc-create-modal"
    :visible.sync="open"
    width="642px"
    :before-close="handleClose"
  >
    <el-form
      ref="accForm"
      class="acceson-form account"
      :model="account"
      :rules="rules"
      label-position="top"
      label-width="120px"
      v-loading="loading"
    >
      <div class="row">
        <!-- <div class="col-12 text-center mb-3">
                    <el-avatar :size="130" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                    <h3>John Smith 8888</h3>
                </div> -->
        <div class="col-md-6">
          <el-form-item label="Account Name" prop="title">
            <el-input v-model="account.title" placeholder="Input title" />
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item label="Unique Identification No." prop="uid">
            <el-input
              type="number"
              v-model="account.uid"
              placeholder="Input uid"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Investor Type" prop="investor_type">
            <el-select v-model="account.investor_type" class="w-100"
                       placeholder="Select Investor Type">
              <el-option
                v-for="item in investor_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Tax ID Type" prop="tax_id_type">
            <el-select v-model="account.tax_id_type" class="w-100" placeholder="Select Type">
              <el-option
                  v-for="item in taxTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-md-6" v-if="account.tax_id_type">
          <el-form-item label="Tax ID" prop="tax_id_value">
            <el-input v-model="account.tax_id_value" placeholder="Input Tax ID">
            </el-input>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Address" prop="address">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
              v-model="account.address"
              placeholder="Input address"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Bank Name" prop="bank_name">
            <el-input
              v-model="account.bank_name"
              placeholder="Input Bank Name"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Account Name" prop="account_name">
            <el-input
                v-model="account.account_name"
                placeholder="Input Account Name"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Account Number" prop="account_number">
            <el-input
              v-model="account.account_number"
              placeholder="Input Account Number"
            />
          </el-form-item>
        </div>

<!--        <div class="col-md-6">-->
<!--          <el-form-item label="Contacts" prop="user_ids">-->
<!--            <el-select-->
<!--              v-model="account.user_ids"-->
<!--              multiple-->
<!--              filterable-->
<!--              placeholder="Select Contacts"-->
<!--              class="w-100"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(value, id) in contactsList"-->
<!--                :key="id"-->
<!--                :label="value"-->
<!--                :value="id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
      </div>


      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center">Select Contact</h4>
          <hr>

          <table class="table">
            <tbody>
            <tr v-for="(user,index) in account.users" :key="index">
              <td style="min-width: 170px">
                <el-select
                    filterable
                    placeholder="Select Contact"
                    v-model="user.id"
                    class="w-100"
                >
                  <el-option
                      v-for="(value, id) in contactsList"
                      :key="id"
                      :label="value"
                      :value="id"
                  >
                  </el-option>
                </el-select>
              </td>
              <td style="min-width: 100px">
                <el-radio @change="markAsPrimaryContact(user)"
                          v-model="radio"
                          :label="index">Mark As Primary</el-radio>

              </td>
              <td style="min-width: 50px">
                <el-button
                    type="danger"
                    circle
                    size="small"
                    class="circle-danger-btn"
                    title="delete row"
                    @click="deleteContactRow(index)"
                >
                  <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                        fill="#EB5757"
                    />
                  </svg>
                </el-button>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">
                <button
                    class="common-button"
                    type="button"
                    @click="addMoreContact"
                >
                  + Add More Contact
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h4 class="text-center">Select Fund</h4>

          <table class="table">
<!--            <thead>-->
<!--              <tr>-->
<!--                <th>Select Fund</th>-->
<!--                <th>Commitment Amount</th>-->
<!--                <th>Action</th>-->
<!--              </tr>-->
<!--            </thead>-->
            <tbody>
              <tr v-for="(fund, fKey) in account.funds" :key="fKey">
                <td style="min-width: 300px">
                  <el-select
                    filterable
                    placeholder="Select Fund"
                    v-model="fund.id"
                    class="w-100"
                  >
                    <el-option
                      v-for="(value, id) in fundsList"
                      :key="id"
                      :label="value"
                      :value="id"
                    >
                    </el-option>
                  </el-select>
                </td>
                <td style="min-width: 200px">
                  <el-input
                    @input="formatNumber(fund,fKey)"
                    v-model="fund.commitment_amount"
                    placeholder="Input Commitment Amount"
                  ></el-input>
                </td>
                <td style="min-width: 100px">
                  <el-button
                    type="danger"
                    circle
                    size="small"
                    class="circle-danger-btn"
                    title="delete row"
                    @click="deleteFundRow(fKey)"
                  >
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.2284 1.83426C10.4394 1.83426 10.6151 2.00949 10.6151 2.23245V2.4386C10.6151 2.65614 10.4394 2.83679 10.2284 2.83679H1.23736C1.02579 2.83679 0.850098 2.65614 0.850098 2.4386V2.23245C0.850098 2.00949 1.02579 1.83426 1.23736 1.83426H2.81914C3.14045 1.83426 3.42008 1.60587 3.49237 1.28363L3.5752 0.913648C3.70394 0.40967 4.12761 0.0749512 4.61248 0.0749512H6.85271C7.33231 0.0749512 7.76073 0.40967 7.88472 0.887066L7.97336 1.28309C8.04511 1.60587 8.32474 1.83426 8.64659 1.83426H10.2284ZM9.42458 9.37021C9.58972 7.83115 9.87885 4.17474 9.87885 4.13785C9.8894 4.02609 9.853 3.92031 9.78072 3.83513C9.70316 3.75539 9.60502 3.70819 9.49686 3.70819H1.97209C1.86341 3.70819 1.75999 3.75539 1.68824 3.83513C1.61543 3.92031 1.57955 4.02609 1.58483 4.13785C1.5858 4.14462 1.59617 4.27342 1.61352 4.48874C1.69057 5.4453 1.90517 8.10951 2.04385 9.37021C2.14198 10.299 2.75137 10.8827 3.63406 10.9038C4.31521 10.9196 5.01693 10.925 5.73448 10.925C6.41035 10.925 7.09677 10.9196 7.79902 10.9038C8.71231 10.8881 9.32117 10.3147 9.42458 9.37021Z"
                        fill="#EB5757"
                      />
                    </svg>
                  </el-button>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-right">
                  <button
                    class="common-button"
                    type="button"
                    @click="addMoreAccount"
                  >
                    + Add More Fund
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
        class="cancel-button common-button mx-3"
        @click="$emit('cancel', true)"
        :disabled="disabled"
      >
        Cancel
      </button>
      <button
        class="common-button"
        @click="handleSubmit('accForm')"
        :disabled="disabled"
      >
        Save
      </button>
    </div>
  </el-dialog>
</template>

<script>
import Account from "../../apis/Account";
import Contact from "../../apis/Contact";
import Fund from "../../apis/Fund";
export default {
  name: "AccountCreateModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      account: {
        title: "",
        investor_type: "",
        uid: "",
        tax_id_type: "",
        tax_id_value: "",
        address: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        // user_ids: [],
        users: [{ id: "", is_primary_contact: 0 }],
        funds: [{ id: "", commitment_amount: "" }],
      },
      radio: '1',
      rules: {
        title: {
          required: true,
          type: "string",
        },
        investor_type: {
          required: true,
          type: "string",
        },
        // user_ids: {
        //   required: false,
        //   type: "array",
        // },
        uid: {
          min: 3,
          max: 15,
        },
      },
      loading: false,
      taxTypes: [
        {
          value: "TIN",
          label: "TIN",
        },
        {
          value: "SSN",
          label: "SSN",
        },
      ],

      investor_type: [
        {
          value: "Fund Manager",
          label: "Fund Manager",
        },
        {
          value: "Individual",
          label: "Individual",
        },
        {
          value: "Endowment",
          label: "Endowment",
        },
      ],
      contactsList: [],
      fundsList: [],
    };
  },
  methods: {
    handleClose() {
      this.$emit("close", false);
    },
    formatNumber(fund,index){
      let tempNumber = fund.commitment_amount.replace(/,/gi, "");
      let commaSeparatedNumber = tempNumber.split(/(?=(?:\d{3})+$)/).join(",");

      this.account.funds[index].commitment_amount = commaSeparatedNumber
    },
    handleSubmit(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          Account.store(this.account)
            .then((res) => {
              this.$notify({
                title: 'Success',
                message: "Investor Account Created Successfully.",
                type: 'success'
              });
              this.$emit("submitted", res.data.data);
              this.loading = false;
            })
            .catch((error) => {
              if (error.response) {
                this.formErrors(error.response.data, this.$notify.error);
              } else {
                this.errorMessage(error);
              }
              this.loading = false;
            });
        } else {
          this.errorMessage("Error while submitting!");
          return false;
        }
      });
    },
    addMoreAccount() {
      this.account.funds.push({ id: "", commitment_amount: "" });
    },

    deleteFundRow(index) {
      this.account.funds.splice(index, 1);
    },

    addMoreContact(){
      this.account.users.push({ id: '', is_primary_contact: 0 });
    },
    deleteContactRow(index) {
      this.account.users.splice(index, 1);
    },

    markAsPrimaryContact(user){
      this.account.users.forEach((account,index)=>{
        if (account.id === user.id){
          this.account.users[index].is_primary_contact = 1
        }else {
          this.account.users[index].is_primary_contact = 0
        }
      })
    }
  },
  created() {
    Contact.contactsByRole("investor").then((res) => {
      this.contactsList = res.data.data;
    });

    Fund.list().then((res) => {
      this.fundsList = res.data.data;
    });
  },
};
</script>

<style>
.acc-create-modal .el-dialog__body {
  height: calc(100vh - 280px);
  overflow-y: scroll;
}
/* width */
.acc-create-modal .el-dialog__body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.acc-create-modal .el-dialog__body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.acc-create-modal .el-dialog__body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.acc-create-modal .el-dialog__body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
