<template>
  <el-dialog
      title="Edit Fund"
      custom-class="modal-custom-class"
      :visible.sync="open"
      width="642px"
      :before-close="handleClose">
    <el-form
        ref="form"
        class="acceson-form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
        v-loading="loading"
    >

      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Category Name" prop="name">
            <el-input
                v-model="formData.name"
                placeholder="Enter Category Name"
            />
          </el-form-item>
        </div>
      </div>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
          class="cancel-button common-button mx-3"
          @click="$emit('cancel', true)"
          :disabled="disabled"
      >Cancel
      </button>
      <button
          class="common-button"
          @click="handleSubmit('form')"
          :disabled="disabled"
      >Update
      </button>
    </div>
  </el-dialog>
</template>

<script>
import Category from "@/apis/Category";


export default {
  name: "EditModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        name: '',
        description: '',
      },

      rules: {
        name: {
          required: true,
          type: 'string',
          message : 'Category Name is required'
        }
      },

      loading: false
    }
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callToBackendUpdateFundApi();
        } else {
          return false;
        }
      });
      this.$emit('close', true);
    },

    callToBackendUpdateFundApi(){

      Category.update(this.id,this.formData).then((res)=>{
        this.$notify({
          title: "Success!",
          message: "Category Updated Successfully.",
          type: "success",
        });

        this.$emit('submitted', res.data)
        this.loading = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;
      })


    },




  },


}


</script>

<style scoped>

</style>