<template>
    <div>
      <div v-if="user.user_role === 'superadmin' || user.user_role === 'admin' ">
        <AdminDashboardLatest/>
<!--        <AdminDashboard/>-->
      </div>
      <div v-else>
        <UserDashboard/>
      </div>

    </div>
</template>

<script>
import AdminDashboard from "@/components/dashboard/AdminDashboard";
import UserDashboard from "@/components/dashboard/UserDashboard";
import AdminDashboardLatest from "@/components/dashboard/AdminDashboardLatest";
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  components: {AdminDashboard,UserDashboard,AdminDashboardLatest},
  computed: {
    ...mapGetters('auth', ['user'])
  },
}
</script>

<style scoped>


</style>