import Vue from "vue";
import moment from "moment";

Vue.filter('log_time',function (value){
    return moment(new Date(value)).format(' h:mm a');
})

Vue.filter('readmore', function (value, limit) {
    if (!value) return ''
    if (value.length > limit) {
        return value.substring(0, limit) + '...'
    }
    return value
})

Vue.filter('shortLength',(value,length,suffix)=>{
    return value.substring(0,length) + suffix
})

Vue.filter('birthday',(value)=>{
    return moment(new Date(value)).format('DD MMMM, YYYY');
})

Vue.filter('date',(value)=>{
    return moment(value).format('MM/DD/YYYY');
})

Vue.filter('year',(value)=>{
    return moment(new Date(value)).format('YYYY');
})

Vue.filter('number',(value)=>{
    return Number(value).toLocaleString();
})


Vue.filter('positiveNumber',(value)=>{
    return Math.abs(value);
})

Vue.filter('capitalize', (value) => {
    return _.capitalize(value)
})

