import { errorMessage } from "./helpers"
import Api from "../apis/Api"

export default class Table {
    constructor(options) {
        this.data = [];
        this.links = {
            first: null,
            last: null,
            prev: null,
            next: null
        };
        this.meta = {
            current_page: 1,
            from: 1,
            last_page: 10,
            links: [],
            path: null,
            per_page: 10,
            to: 10,
            total: 100
        };

        this.loading = false;

        this.map = item => {
            const result = {};

            for (let column of this.columns) {
                result[column] = item[column];
            }

            return result;
        }

        if (options) {
            if (options.hasOwnProperty("columns")) {
                this.columns = options.columns;
            }

            if (options.hasOwnProperty("data")) {
                this.populate(options.data);
            }
        }
    }

    populate(data) {
        if (this.columns) {
            this.data = data.data.map(this.map);
        } else {
            this.data = data.data
        }
        this.links = data.links;
        this.meta = data.meta;
    }

    get(getMethod) {
        this.loading = true;
        getMethod()
            .then(response => {
                this.populate(response.data);

                this.loading = false;
            })
            .catch(error => {
                if (error.response) {
                    errorMessage(error.response.data.message);
                } else {
                    errorMessage(error);
                }

                this.loading = false;
            });
    }

    currentChange(curr) {
        this.loading = true;
        Api.get(this.meta.links[curr].url)
            .then(response => {
                this.populate(response.data);

                this.loading = false;
            })
            .catch(error => {
                if (error.response) {
                    errorMessage(error.response.data.message);
                } else {
                    errorMessage(error);
                }

                this.loading = false;
            });
    }

    prevClick() {
        this.loading = true;
        Api.get(this.links.prev)
            .then(response => {
                this.populate(response.data);

                this.loading = false;
            })
            .catch(error => {
                if (error.response) {
                    errorMessage(error.response.data.message);
                } else {
                    errorMessage(error);
                }

                this.loading = false;
            });
    }

    nextClick() {
        this.loading = true;
        Api.get(this.links.next)
            .then(response => {
                this.populate(response.data);

                this.loading = false;
            })
            .catch(error => {
                if (error.response) {
                    errorMessage(error.response.data.message);
                } else {
                    errorMessage(error);
                }

                this.loading = false;
            });
    }

    refresh() {
        this.loading = true;
        let currentPage = this.meta.current_page;

        Api.get(this.meta.links[currentPage].url)
            .then(response => {
                this.populate(response.data);

                this.loading = false;
            })
            .catch(error => {
                if (error.response) {
                    errorMessage(error.response.data.message);
                } else {
                    errorMessage(error);
                }

                this.loading = false;
            });
    }
}