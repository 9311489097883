import Api from "./Api";

export default {
    allTemplates() {
        return Api.get(`/v1/template-list`);
    },

    index(param) {
        return Api.get(`/v1/templates?search=${param}`);
    },



    store(formData) {
        return Api.post('/v1/templates/store', formData);
    },
    edit(id) {
        return Api.get(`/v1/templates/edit/${id}`);
    },

    update(id, formData) {
        return Api.post(`/v1/templates/update/${id}`, formData);
    },

    destroy(id) {
        return Api.delete(`/v1/templates/delete/${id}`);
    },

    show(id) {
        return Api.get(`/v1/templates/show/${id}`);
    },

}