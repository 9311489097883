import Api from "@/apis/Api";

export default {
    login(data) {
        return Api.post("/auth/login", data);
    },
    checkOtp(data) {
        return Api.post("/auth/check-otp", data);
    },

    refreshToken() {
        return Api.patch("/auth/refresh");
    },

    me() {
        return Api.get("/auth/me");
    },

    logout() {
        return Api.delete("/auth/logout");
    },

    userPermissions() {
        return Api.get(`/auth/permissions`);
    },

    checkUser(data){
        return Api.post("/auth/check-user",data);
    },


}