import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import Dashboard from "@/views/dashboard/Dashboard";
import Contact from "@/views/contact/Index";
import Document from "@/views/document/Index";
import MainLayout from "../components/layouts/MainLayout";
import Register from "../views/auth/Register";
import PasswordReset from "../views/auth/PasswordReset";
import NotFound from "../views/errors/NotFound";
import UserProfile from "@/views/profile/Index";
import AccessDenied from "@/views/errors/AccessDenied";
import AccessRights from "@/components/contact/AccessRights";
import Investor from "@/views/investors/Index";
import Funds from "@/views/funds/Index";
import ShowFund from "@/components/fund/Show";
import ViewDocument from "@/components/document/ViewDocument";
import ViewInvestor from "@/components/investor/View";
import ViewContact from "@/components/contact/ViewContact";
import Communication from "@/views/communication/Index";
import ViewCommunication from "@/components/communication/ViewEmail";
import TemplateList from "@/views/communication/TemplateList";
import PreviewEmail from "@/components/communication/PreviewEmail.vue";
import Setting from "@/views/setting/Setting.vue";
import Notification from "@/views/notification/Index.vue";
import Reports from "@/views/reports/Reports.vue";
import LoginLatest from "@/views/auth/LoginLatest.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: "",
        // name: "root",
        component: MainLayout,
        meta: {requiresAuth: true},
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: Dashboard
            },
            {
                path: '/profile',
                name: 'profile',
                component: UserProfile
            },
            {
                path: '/document',
                name: 'document',
                component: Document
            },
            {
                path: '/view-document',
                name: 'view-document',
                component: ViewDocument
            },
            {
                path: '/funds',
                name: 'funds',
                component: Funds
            },
            {
                path: '/funds/:id',
                name: 'view-fund',
                component: ShowFund
            },
            {
                path: '/investor',
                name: 'investor',
                component: Investor
            },
            {
                path: '/investor/:id',
                name: 'view-investor',
                component: ViewInvestor
            },
            {
                path: '/contact',
                name: 'contact',
                component: Contact
            },
            {
                path: '/contact/:id',
                name: 'contact-view',
                component: ViewContact
            },

            {
                path: '/contact-access-rights',
                name: 'accessRights',
                component: AccessRights
            },
            {
                path: '/communication',
                name: 'communication',
                component: Communication
            },
            {
                path: '/communication/:id',
                name: 'communication-view',
                component: ViewCommunication
            },
            {
                path: '/email-preview',
                name: 'emailPreview',
                component: PreviewEmail
            },
            {
                path: '/templates',
                name: 'templates',
                component: TemplateList
            },

            {
                path: '/report',
                name: 'report',
                component: Reports
            },

            {
                path: '/setting',
                name: 'setting',
                component: Setting
            },
            {
                path: '/notification',
                name: 'notification',
                component: Notification
            },


        ]
    },
    {
        path: '/register',
        component: Register,
        meta: {requiresGuest: true}
    },
    {
        path: '/login',
        component: LoginLatest,
        meta: {requiresGuest: true}
    },
    {
        path: '/set-password',
        component: PasswordReset,
        meta: {requiresGuest: true}
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        meta: {requiresGuest: true}
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/forbidden',
        name: 'accessDenied',
        component: AccessDenied
    },
    {
        path: '/:catchAll(.*)',
        redirect: '404'
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters['auth/isAuthenticated']) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresGuest)) {
        if (store.getters['auth/isGuest']) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
});

// router.beforeEach((to, from, next) => {
//     let presentTime = new Date().getTime();
//     let expireTime = store.getters['auth/tokenExpiration'];
//
//     if (presentTime > expireTime) {
//         Auth.refreshToken()
//             .then(response => {
//                 store.dispatch('auth/setToken', response.data.token)
//                 store.dispatch('auth/setTokenExpiration', new Date().getTime() + (response.data.expires_in * 1000));
//                 next()
//             })
//             .catch(error => {
//                 store.dispatch('auth/logout')
//                 next('/login')
//             })
//     } else {
//         next();
//     }
//     next();
// })

export default router
