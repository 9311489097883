<template>
  <div class="row permission-container">
    <div class="col-12">
      <h3 class="role-title">Role & Permissions</h3>
      <el-table
          :data="roles"
          style="width: 100%"
      >

        <el-table-column width="150" label="Name">
          <template slot-scope="scope">
            <div>
              {{ scope.row.display_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" label="Slug">
          <template slot-scope="scope">
            <code>
              {{ scope.row.name }}
            </code>
          </template>
        </el-table-column>
        <el-table-column label="Permissions">
          <template slot-scope="scope">
            <el-select v-model="scope.row.permissions" multiple
                       filterable style="width: 100%"
                       placeholder="Select Permission">
              <el-option-group
                  v-for="(group,name) in permissions"
                  :key="name"
                  :label="name + ' ' +'permissions'">
                <el-option
                    v-for="item in group"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id">
                </el-option>
              </el-option-group>

            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div class="d-flex justify-content-start mt-5">
        <el-button
            class="common-button"
            @click.prevent="updatePermissions"
            :loading="loading"
        >Update Settings
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Role from "@/apis/Role";
import {Notification} from "element-ui";

export default {
  name: "RolePermission",
  data(){
    return{
      roles: [],
      permissions: [],
      loading: false,
    }
  },
  mounted() {
    this.getAllPermissions();
    this.getRoles();
  },
  methods:{
    async getRoles() {
      await Role.getRolePermission().then((res)=>{
        this.roles = res.data.data;
      })
    },

    async getAllPermissions() {
      await Role.allPermissions().then((res)=>{
        this.permissions = res.data.permissions
      })
    },
    updatePermissions() {
      this.loading = true;
      Role.updatePermissions(this.roles).then((res) => {
        this.roles = res.data.data
        Notification.success('Permissions updated successfully.');
        this.loading = false;
      }).catch(()=>{
        Notification.error('Something went wrong!');
        this.loading = false;
      })
    },

  }
}
</script>

<style scoped>
.role-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #092C4C;
  margin-top: 10px;
}

</style>