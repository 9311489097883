import Api from "./Api";

export default {
    index(page) {
        return Api.get(`/v1/accounts?page=${page}`);
    },
    allAccounts(page=1,title,fund_name,investor_type) {
        return Api.get(`/v1/accounts?page=${page}&title=${title}&fund_name=${fund_name}&investor_type=${investor_type}`);
    },
    accountList(page,fund,commitment_amount,contact,account_number,address,tax_id) {
        return Api.get(`/v1/account/lists?page=${page}&fund=${fund}&commitment_amount=${commitment_amount}&contact=${contact}&account_number=${account_number}&address=${address}&tax_id=${tax_id}`);
    },
    exportToExcel(fund,commitment_amount,contact,account_number,address,tax_id) {
        return Api.get(`/v1/all-accounts/export?fund=${fund}&commitment_amount=${commitment_amount}&contact=${contact}&account_number=${account_number}&address=${address}&tax_id=${tax_id}`,{responseType: 'blob'});
    },
    list() {
        return Api.get(`/v1/account-list`);
    },
    fundList(id) {
        return Api.get(`/v1/fundList/${id}`);
    },
    deletedAccounts(page) {
        return Api.get(`/v1/accounts?deleted_only=yes&page=${page}`);
    },
    show(id) {
        return Api.get(`/v1/accounts/${id}`);
    },
    store(data) {
        return Api.post('/v1/accounts', data);
    },
    update(id, data) {
        return Api.put(`/v1/accounts/${id}`, data);
    },
    destroy(id) {
        return Api.delete(`/v1/accounts/${id}`);
    },
    userWiseAccounts(userId){
        return Api.get(`/v1/users/${userId}/accounts`);
    },

}