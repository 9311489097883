<template>
  <el-dialog
      title="Upload New Documents"
      custom-class="modal-custom-class"
      :visible.sync="open"
      width="550px"
      :before-close="handleClose">
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-position="top"
        label-width="120px"
        v-loading="loading"
    >


      <div class="row">
        <div class="col-md-12">
          <el-form-item label="Document Title" prop="title">
            <el-input
                v-model="formData.title"
                placeholder="Enter Title here"
            />
          </el-form-item>
        </div>
        <div class="col-md-12">
          <el-form-item label="Category" prop="category_id">
            <el-select v-model="formData.category_id" style="width: 100%"
                       placeholder="Select">
              <el-option
                  v-for="(value, id) in categoryList"
                  :key="id"
                  :label="value"
                  :value="id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-md-12">
          <el-form-item label="Security" prop="security">
            <el-select
                v-model="formData.security"
                placeholder="Select Security"
                class="w-100"
            >
              <el-option v-for="(security,index) in securityOptions"
                         :key="index"
                         :label="security.label"
                         :value="security.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-md-12">
          <el-form-item label="Investors" prop="investor_ids">
            <el-select v-model="formData.investor_ids" style="width: 100%"
                       placeholder="Select" multiple>
              <el-option
                  v-for="item in accounts"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <el-form-item
              prop="document">
            <el-upload
                class="upload-demo acceson-uploader"
                drag
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="handleChange"
                :auto-upload="false"
                :file-list="fileList"
                :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" style="font-weight: 500;font-size:
               16px;">Drop files here or
                <em style="color: #4F31FF;">Browse</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                application/pdf or zip files with a size less than 2MB
              </div>

            </el-upload>
          </el-form-item>
        </div>

      </div>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <button
          class="cancel-button common-button mx-3"
          @click.prevent="$emit('cancel', true)"
          :disabled="disabled"
      >Cancel
      </button>
      <el-button
          class="common-button"
          @click.prevent="handleSubmit('form')"
          :disabled="disabled"
          :loading="loading"
      >Save
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Category from "@/apis/Category";
import {Notification} from 'element-ui';
import FundDocument from "@/apis/FundDocument";
import Account from "@/apis/Account";

export default {
  name: "CreateDocumentModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formData: {
        fund_id: this.$route.params.id,
        title: '',
        category_id: '',
        security: '',
        investor_ids: [],
        document: null,
      },
      rules: {
        title: { required: true, message: 'Please enter Title', trigger: 'blur' },
        category_id: {required: true,message: 'Please Select Category',trigger: 'blur'},
        security: {required: true,message: 'Security is required',trigger: 'blur'},
        investor_ids: {required: true,message: 'Investors are required',trigger: 'blur'},
        document: { required: true, message: 'Please Upload document', trigger: 'blur' },
      },
      options: [
        {
          value: 1,
          label: 'Active'
        }, {
          value: 0,
          label: 'Pending'
        }],
      securityOptions: [
        {
          value: 'yes',
          label: 'Does not Allow saving and printing of this document'
        }, {
          value: 'no',
          label: 'Allow saving and printing of this document'
        }],
      fileList: [],
      categoryList: [],
      accounts: [],

      loading: false
    }
  },

  mounted() {
    this.getAccountList();
  },
  methods: {
    getAccountList(){
      Account.list().then((res)=>{
        this.accounts = res.data.data;
      })
    },
    handleClose() {
      this.$emit('close', false);
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.callToBackendApi();
        } else {
          Notification.error('Please fill up the required field!')
          return false;
        }
      });

    },

    callToBackendApi(){

      let formData = new FormData();

      formData.append('fund_id',this.formData.fund_id);
      formData.append('title',this.formData.title);
      formData.append('category_id',this.formData.category_id);
      formData.append('security',this.formData.security);
      formData.append('investor_ids',this.formData.investor_ids);
      formData.append('document',this.formData.document);


      FundDocument.store(formData).then((res)=>{
        this.$notify({
          title: "Success!",
          message: "Document Created Successfully.",
          type: "success",
        });

        this.clearForm();

        this.$emit('submitted', res.data)
        this.loading = false;

      }).catch((error)=>{
        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;
      }).finally(()=>{
        this.loading = false;
      })

    },

    clearForm(){
      this.formData.title = '';
      this.formData.category_id = '';
      this.formData.document = '';
      this.fileList = [];
    },

    handleChange(file){
      this.formData.document = file.raw;
    },

    getCategories(){
      Category.list().then((res) => {
        this.categoryList = res.data.data;
      });
    },


  },

  created() {
    this.getCategories();
  }
}
</script>

<style scoped>

</style>