<template>
  <div>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        class="mt-4 profile-form overflow-hidden"
        label-position="top"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <el-upload
                  style="border: 1px dashed #00B050; width: 178px; height: 178px;"
                  class="avatar-uploader d-inline-block mb-3"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :on-success="handleUploadSuccess"
                  :auto-upload="false"
              >
                <img v-if="imageShow" :src="`${baseUrl}`+imageShow"
                     class="user-avatar" alt="">
                <img v-else-if="preview != null" :src="preview" class="user-avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <p class="text-sm" v-if="!imageShow">jpg/png files with a size less than 2MB</p>

              <br>
              <el-popconfirm
                  v-if="imageShow"
                  confirm-button-text='OK'
                  cancel-button-text='Cancel'
                  icon="el-icon-info"
                  icon-color="red"
                  title="Do you want to remove your profile picture?"
                  @confirm="removeImage"
              >

              <span slot="reference" class="text-danger cursor-pointer">
                Remove Profile Pic
              </span>
              </el-popconfirm>

            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-4">

                  <el-form-item label="First Name" prop="first_name">
                    <el-input
                        v-model="formData.first_name"
                        type="text"
                        placeholder="first name"
                    />
                  </el-form-item>

                </div>
                <div class="col-md-4">
                  <el-form-item label="Last Name" prop="last_name">
                    <el-input
                        v-model="formData.last_name"
                        type="text"
                        placeholder="last name"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="Date of Birth" prop="dob">
                    <el-date-picker
                        v-model="formData.dob"
                        style="width: 100%;"
                        type="date"
                        format="dd MMMM, yyyy"
                        placeholder="Pick a day">
                    </el-date-picker>
                  </el-form-item>
                </div>

                <div class="col-md-4">
                  <el-form-item label="Phone Number" prop="phone_number">
                    <el-input
                        v-model="formData.phone_number"
                        type="text"
                        placeholder="Phone Number"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="Address" prop="address">
                    <el-input v-model="formData.address" type="text"
                              placeholder="address"/>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="City" prop="city">
                    <el-input v-model="formData.city"
                              type="text" placeholder="city"/>
                  </el-form-item>
                </div>

                <div class="col-md-4">
                  <el-form-item label="Country" prop="country">
                    <el-input v-model="formData.country"
                              type="text"
                              placeholder="country"/>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="Organization" prop="organization">
                    <el-input v-model="formData.organization"
                              type="text" placeholder="organization"/>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="Profession" prop="profession">
                    <el-input v-model="formData.profession"
                              type="text" placeholder="profession"/>
                  </el-form-item>
                </div>

              </div>


            </div>
          </div>

          <div class="mt-3">
            <el-button v-if="checkPermission('profile_all','profile_update')"
                       class="common-button mt-2"
                       type="success"
                       :loading="loading"
                       @click="handleSubmit('form')"
            >Update Profile
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>

import Auth from "@/apis/Auth";
import Profile from "@/apis/Profile";
import UserList from "@/apis/UserList";
import {Notification} from "element-ui";


export default {
  name: "UpdateProfile",
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        dob: '',
        phone_number: '',
        address: '',
        city: '',
        country: '',
        organization: '',
        profession: '',
        profile_picture: '',
      },
      imageShow: null,
      preview: null,
      fileList: [],
      permissions: [],
      baseUrl: `${process.env.VUE_APP_BASE_URL}`,
      rules: {
        first_name: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        last_name: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        phone_number: [
          {min: 4,max: 16, trigger: 'blur'},
        ],
        address: [
          {min: 2,max: 100, trigger: 'blur'},
        ],
        city: [
          {min: 2,max: 50, trigger: 'blur'},
        ],
        country: [
          {min: 2,max: 50, trigger: 'blur'},
        ],
        organization: [
          {min: 2,max: 100, trigger: 'blur'},
        ],
        profession: [
          {min: 2,max: 100, trigger: 'blur'},
        ],
      },
      loading: false,
    }
  },

  methods: {
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callToBackendUpdateProfileApi();
        } else {
          return false;
        }
      });
    },

    callToBackendUpdateProfileApi() {

      this.loading = true;

      let formData = new FormData();
      formData.append('first_name',this.formData.first_name);
      formData.append('last_name',this.formData.last_name);
      formData.append('dob',this.formData.dob);
      formData.append('phone_number',this.formData.phone_number);
      formData.append('address',this.formData.address);
      formData.append('city',this.formData.city);
      formData.append('country',this.formData.country);
      formData.append('organization',this.formData.organization);
      formData.append('profession',this.formData.profession);
      formData.append('profile_picture',this.formData.profile_picture);

      Profile.updateProfile(formData).then((res)=>{
        if (res.data.status === true){
          this.$notify({
            title: "Success!",
            message: "Profile Updated Successfully.",
            type: "success",
          });

          this.fileList = []    
          this.$store.dispatch('auth/setUser', res.data.data);
          this.getAuthUser()

        }else {
          this.$notify({
            title: "Failed!",
            message: "Something went wrong.",
            type: "error",
          });
        }

        this.loading = false;

      }).catch((error)=>{

        if (error.response) {
          this.formErrors(error.response.data, this.$notify.error);
        } else {
          this.errorMessage(error);
        }
        this.loading = false;

      })

    },


    handleUploadSuccess(file) {
      console.log(file)
    },

    handleChange(file) {
      const isJpgOrPng = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.raw.size / 1024 / 1024 < 2;

      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG/PNG file!');
        return isJpgOrPng && isLt2M;
      }
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
        return isJpgOrPng && isLt2M;
      }

      let imageFile = file.raw;
      this.formData.profile_picture = imageFile
      let reader = new FileReader();
      reader.onload = (e) => {
        this.preview = e.target.result
      };
      reader.readAsDataURL(imageFile);
    },

    removeImage(){
      UserList.deleteUserProfilePicture().then((res) => {
        this.$store.dispatch('auth/setUser', res.data.data);
        this.imageShow = res.data.data.profile_picture;

        this.$notify({
          title: "Success!",
          message: "Profile Picture Removed Successfully.",
          type: "success",
        });
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getAuthUser() {
      Auth.me().then((response) => {
        if (response.data.first_name != null){
          this.formData.first_name  = response.data.first_name
        }
        if (response.data.last_name != null){
          this.formData.last_name = response.data.last_name
        }
        if (response.data.dob != null){
          this.formData.dob = response.data.dob
        }
        if (response.data.phone_number != null){
          this.formData.phone_number = response.data.phone_number
        }
        if (response.data.address != null){
          this.formData.address = response.data.address
        }
        if (response.data.city != null){
          this.formData.city = response.data.city
        }
        if (response.data.country != null){
          this.formData.country = response.data.country
        }
        if (response.data.organization != null){
          this.formData.organization = response.data.organization
        }
        if (response.data.profession != null){
          this.formData.profession = response.data.profession
        }
        if (response.data.profile_picture != null){
          this.imageShow = response.data.profile_picture
        }

      }).catch((error) => {
        console.log(error)
      })
    },

    getPermissions(){
      Auth.userPermissions().then((res)=>{
        this.permissions = res.data.permissions;
      }).catch(()=>{
        Notification.error("Something went wrong!");
      })
    },

    checkPermission(allPermission,specificPermission){
      let userPermissions = this.permissions

      for (let key in userPermissions) {
        if (userPermissions[key].name === allPermission || userPermissions[key].name === specificPermission){
          return true;
        }
      }
      return false;
    }

  },
  created() {
    this.getPermissions();
  },
  mounted() {
    this.checkPermission();
    this.getAuthUser();
  }

}
</script>

<style scoped>

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.user-avatar {
  width: 178px;
  height: 178px;
  display: block;
}


</style>